import React from "react";
// import { Switch } from "@mui/material";
//  import { makeStyles } from "@mui/material";

 import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles({
//   root: {
//     width: "50px",
//     height: "24px",
//     padding: "0px"
//   },
//   switchBase: {
//     color: "#818181",
//     padding: "1px",
//     "&$checked": {
//       "& + $track": {
//         backgroundColor: "#006FF4"
//       }
//     }
//   },
//   thumb: {
//     color: "white",
//     width: "20px",
//     height: "20px",
//     margin: "1px"
//   },
//   track: {
//     borderRadius: "20px",
//     backgroundColor: "#818181",
//     opacity: "1 !important",
//     "&:after, &:before": {
//       color: "white",
//       fontSize: "11px",
//       position: "absolute",
//       top: "6px"
//     },
//     "&:after": {
//       content: "'On'",
//       left: "8px"
//     },
//     "&:before": {
//       content: "'Off'",
//       right: "7px"
//     }
//   },
//   trackStart: {
//     borderRadius: "20px",
//     backgroundColor: "#006FF4 !important",
//     opacity: "1 !important",
//     "&:after, &:before": {
//       color: "white",
//       fontSize: "8px",
//       position: "absolute",
//       top: "7px"
//     },
//     "&:after": {
//       content: "'Start'",
//       left: "6px"
//     },
//   },
//   trackStop:{
//     borderRadius: "20px",
//     backgroundColor: "#818181",
//     opacity: "1 !important",
//     "&:after, &:before": {
//       color: "white",
//       fontSize: "8px",
//       position: "absolute",
//       top: "7px"
//     },
//     "&:before": {
//       content: "'Stop'",
//       right: "6px"
//     }
//   },
//   checked: {
//     color: "#23bf58 !important",
//     transform: "translateX(26px) !important"
//   }
// });
const useStyles = makeStyles((theme) => ({
  root: {
    width: "50px",
    height: "24px",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "44px",
      height: "18px",
    },
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#006FF4",
      },
    },
  },
  thumb: {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px",
    [theme.breakpoints.down("sm")]: {
      width: "14px",
      height: "14px",
      margin: "1px",
    },
  },
  track: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    position: "relative",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "50%", // Center text vertically
      transform: "translateY(-50%)", // Center text vertically
    },
    "&:after": {
      content: "'On'",
      left: "8px",
    },
    "&:before": {
      content: "'Off'",
      right: "8px",
    },
  },
  trackStart: {
    borderRadius: "20px",
    backgroundColor: "#006FF4 !important",
    opacity: "1 !important",
    position: "relative",
    "&:after, &:before": {
      color: "white",
      fontSize: "8px",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    "&:after": {
      content: "'Start'",
      left: "6px",
    },
  },
  trackStop: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    position: "relative",
    "&:after, &:before": {
      color: "white",
      fontSize: "8px",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    "&:before": {
      content: "'Stop'",
      right: "6px",
    },
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(26px) !important", // Adjust this based on thumb size
  },
}));


export default function ToggleSwitch(props: any) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true
  });

  const { isEnable } = props;

  const handleChange = (event:any) => {
    const { onChangeHandler } = props
    setState({ ...state, [event.target.name]: event.target.checked });
    onChangeHandler();
  };

  return (
    <div>
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: isEnable ? classes.trackStart : classes.trackStop,
          checked: classes.checked
        }}
        checked={isEnable}
        onChange={handleChange}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </div>
  );
}
