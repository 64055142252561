import React, { useEffect, useState } from "react";
import InputComponent from "../../../components/shared/Input/Index";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  ResetPasswordOp,
  VerifyToken,
} from "../../../redux/toolkit/auth/operation";
import { errorMsgs, regexStrConst, LOGO_URL } from "../../../constants/stringConstants";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import "./index.css";
import { OMWLogoWhite } from "../../../constants/Images";
import InputTypeText from "../../../components/shared/Input/Basic/InputTypeText";
import { BsEye,BsEyeSlash } from "react-icons/bs";
import InputTypeSecure from "../../../components/shared/Input/InputTypeSecure";
import BasicButton from "../../../components/shared/Buttons/Basic";
import LanguageDropdown from "../../../components/shared/TranslationDropdown";



const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [ConfirmpassError, setConfirmPassError] = useState("");
  const [showNewPass, setShowNewPass] = useState(true)
  const [showConfirmNewPass, setShowConfirmNewPass] = useState(true)


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  const token = location.pathname.split("/")[2];
  const resetPassDetailss: any = useSelector(
    (state: RootState) => state.resetPassDetails
  );
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const validate = () => {
    if (confirmPass === "") setConfirmPassError(errorMsgs.emptyPassword);
    if (password === "") setPassError(errorMsgs.emptyPassword);
    if(confirmPass !== password) setConfirmPassError(errorMsgs.confirmPassword)

    return confirmPass === "" ||
      password === "" ||
      ConfirmpassError ||
      passError
      ? false
      : true;
  };

  useEffect(() => {
    const payload = {
      token: token,
    };
    dispatch(VerifyToken(payload));
  }, [token]);

  useEffect(() => {
    if (resetPassDetailss && Object.keys(resetPassDetailss.data).length !== 0) {
      navigate("/");
    }
  }, [resetPassDetailss]);

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.passwordRegex.test(item)) {
      setPassError("");
    } else {
      setPassError(errorMsgs.emptyPassword);
    }
    setPassword(item);
  };

  const onChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const item = event.target.value;
    if (regexStrConst.passwordRegex.test(item)) {
      setConfirmPassError("");
    } else {
      setConfirmPassError(errorMsgs.emptyPassword);
    }
    setConfirmPass(item);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (validate()) {
      if (password === confirmPass) {
        setLoading(true);
        const payload = {
          password: password,
          token: token,
        };
        dispatch(ResetPasswordOp(payload)).then(() => setLoading(false));
      } else {
        // Swal.fire({
        //   icon: "error",
        //   title: "Oopss",
        //   text: "Password should match",
        // });
      }
    }
  };

  const validatePassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value == ''){
      setPassError(errorMsgs.emptyPassword)
    }
    else if(!regexStrConst.passwordRegex.test(e.target.value)){
      setPassError(errorMsgs.incorrectPassword)
    }
    else{
      setPassError('')
    }
  }

  const validateConfirmPassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value == ''){
      setConfirmPassError(errorMsgs.emptyPassword)
    }
    else if(!regexStrConst.passwordRegex.test(e.target.value)){
      setConfirmPassError(errorMsgs.incorrectPassword)
    }
    else{
      setConfirmPassError('')
    }
  }
  return (
    <div className="NewPassword p-2">
      <div className="onBoardingImage onBoardingBGImg">
      
        <img className="omwLogo" src={OMWLogoWhite} />
        {/* <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 210, height: 80,filter:"drop-shadow(0 0 0.4px)"}}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div> */}
        {/* <div className="left-title-box">
          <h2 className="left-title">{t("login.title.payment")} </h2>
          <h2 className="left-title-second">{t("login.title.platform")}</h2>
          <h2 className="left-title-third">{t("login.title.SMEs")}</h2>
        </div>
        <div className="welcome-box">
          <p className="welcome">{t("login.title.welcome")}</p>
          <p>{t("login.welcomeDesc")}</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div> */}
      </div>
      <div className="onBoardingForm">
      <div className="top-banner">
        <img src={OMWLogoWhite}/>
      </div>
       <div className="lng-dropdown">
          <LanguageDropdown />
        </div>
        {/* <div className="point-box">
          <p className="left-point"></p>
          <p className="middle-point"></p>
          <p className="right-point"></p>
        </div> */}
        <h2 className="onBoarding-title mt-2">
          {t("resetPassword.mainTitle")}
          {/* Reset Your Password */}
        </h2>
        <p className="onBoarding-text">
          {t("resetPassword.title.description")}
          {/* Enter an unique and strong password */}
        </p>
        <form className="new-form">
          <p className="inputLabel">{t("resetPassword.newPass")}</p>
          {/* <InputTypeText
            type="password"
            className="inputText"
            name="password"
            // placeholder={t("enterPass")}
            value={password}
            onBlur={
              (e) => validatePassword(e)
              // () =>
              // password === "" && regexStrConst.passwordRegex.test(password)
              //   ? setPassError(errorMsgs.emptyPassword)
              //   : setPassError("")
              }
              onChange={onChangePassword}
              errorMsg={passError}
              />
              <p className="inputLabel">{t("resetPassword.confirmNewPass")}</p>
              <InputTypeText
              type="password"
              className="inputText"
              name="confirmPassword"
              // placeholder={t("resetPassword.confirmPassP")}
              value={confirmPass}
              onBlur={
                (e) => validateConfirmPassword(e)
                // confirmPass === "" && regexStrConst.passwordRegex.test(confirmPass)
                //   ? setConfirmPassError(errorMsgs.emptyPassword)
                //   : setConfirmPassError("")
                }
                onChange={onChangeConfirmPassword}
                errorMsg={ConfirmpassError}
                /> */}

          <InputTypeSecure
            type={`${showNewPass ? "password" : "text"}`}
            className="inputTextBox inputPass"
            // placeholder={t("enterPass")}
            value={password}
            onBlur={(e: any) => validatePassword(e)}
            onChange={onChangePassword}
            errorMsg={passError}
          />
          <span className="passwordReqText mt-2">{t("errorMsgs.emptyPasswordReg")}</span>

          <p className="inputLabel mt-4">
            {t("resetPassword.confirmNewPass")}
            {/* Confirm New Password */}
          </p>

          <InputTypeSecure
            type={`${showConfirmNewPass ? "password" : "text"}`}
            className="inputTextBox inputPass"
            value={confirmPass}
            onBlur={(e: any) => validateConfirmPassword(e)}
            onChange={onChangeConfirmPassword}
            errorMsg={ConfirmpassError}
          />

          <BasicButton
           text={t("resetPassword.resetMyPass")}
           style={{marginTop:'20px'}}
           isLoading={loading}
           onClick={handleButtonClick}
           disabled={loading}
          />

          {/* <button
            className="reset-pass-btn btn btn-primary mt-4"
            onClick={handleButtonClick}
          >
            {loading ? (
              <Spinner animation={"border"} />
            ) : (
              `${t("resetPassword.resetMyPass")}`
              "Save"
            )}
          </button> */}
          {/* <button className="back-btn">{t("resetPassword.back")}</button> */}
          <div className="backToLogin">
            <span onClick={() => navigate("/")}>
              {t("forgotPassword.backToLogin")}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
