import React from "react";
import { Col } from "react-bootstrap";
import "./index.css";

const DisabledCard = ({ data }: { data: any }) => {
  return (
      <div className={`disabled-mainBox ${data.data.isDisabled}`}>
        <Col className="mainBox-icon col-2">
          <img src={data?.data?.mainIcon} alt={data.data.name} />
        </Col>
        <Col className="mainBox-text col-8">{data.data.name}</Col>
      </div>
  );
};

export default DisabledCard;
