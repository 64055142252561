import React, { useEffect, useState } from "react";
import InputComponent from "../../components/shared/Input/Index";
import { Form } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../redux/store";
import { UserRegistration, UserUpdate } from "../../redux/toolkit/auth/operation";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/toolkit/auth/selector";
import { useNavigate } from "react-router-dom";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import LanguageDropdown from "../../components/shared/TranslationDropdown";
import "./index.css";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import * as iso from "iso-3166-1";
import { apiHelper } from '../../api helper/index'
import { ErrorImage } from "../../constants/Images";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";
import ExchangeRatesCard from "../../components/shared/ExchangeRatesCard";
import { getS3BaseUrl } from '../../utils/constants';
import AutoCompleteInput from "../../components/shared/Input/AutoCompleteInput";
import cities from 'cities.json';
import { getCountryCodeByName } from "../../constants/locationService";

type City = {
  name: string;
  country: string;
  lat?:string
  lng?:string
}
const cityData: City[] = cities as City[];
import { showAlert } from "../../redux/toolkit/swal/reducer";

const EditRegistration = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [countryCode, setCountryCode] = useState<any>("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [ConfirmpassError, setConfirmPassError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [agree, setAgree] = useState(true);
  const [loading, setLoading] = useState(false);

  const [city, setCity] = useState("");
  const [cityErrorMsg, setCityErrorMsg] = useState("");

  const [country, setCountry] = useState("");
  const [countryErrorMsg, setCountryErrorMsg] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState("");

  const [companyRegNumber, setCompanyRegNumber] = useState('');
  const [companyRegNumberErrorMsg, setCompanyRegNumberErrorMsg] = useState("");
  const [CompanyLogoBase64, setCompanyLogoBase64] = useState("");
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const [companyLogoError, setCompanyLogoError] = useState("");
  const [latestCompanyurl, setLatestCompanyurl] = useState("");
  const countries = iso.all();
  const S3_URL: any = getS3BaseUrl();
  const [citiesByCountry, setCitiesByCountry] = useState<City[]>([]);



  const onChangeCity = (event: string) => {
    const item = event;
    setCity(item);
  };

  const onChangeCountry = (event: any) => {
    const countryName = event;
    setCountry(countryName);
    setCity('')
    const countCd = countries.find((c)=>c.country===countryName)?.alpha2
    setCountryCode(countCd)
    getCitiesByCountry(countCd || '')
  };

  const onChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setPostalCode(item);
  };

  const onChangeCompanyRegNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item) {
      setCompanyRegNumberErrorMsg("")
    } else {
      setCompanyRegNumberErrorMsg(errorMsgs.emptyCompanyRegNo)
    }
    setCompanyRegNumber(item);

  };

  // const companyLogo: any = localStorage.getItem("companyLogo")
  // const companyLogo: any =(URL.S3_IMAGES_BASE_URL || "") + localStorage.getItem("companyLogo");
  const companyLogo: any = latestCompanyurl?.includes("CompanyLogos") ? (S3_URL || "") + latestCompanyurl : latestCompanyurl;
  console.log('COMPANY LOGO ', companyLogo)
  // const registerationDetails: any = useSelector(getProfileDetails);
  const userId: any = localStorage.getItem("userId");
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (registerationDetails.data) {
  //     if (Object.keys(registerationDetails.data).length > 0) {
  //       navigate("/");
  //     }
  //   }
  // }, [registerationDetails]);

  const getUserDetail = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      if (userId) {
        const res = await apiHelper(`user/getUser/${userId}`, "get", {}, {
          "Authorization": `Bearer ${token}`
        })
        if (res?.data?.data) {
          const data = res?.data?.data;

          console.log(data?.firstName);
          setFirstNameError("");
          setFirstName(data?.firstName != null ? data?.firstName : "");

          console.log(data?.lastName);
          setLastNameError("");
          setLastName(data?.lastName != null ? data?.lastName : "");

          console.log(data?.email);
          setEmailError("");
          setEmail(data?.email != null ? data?.email : "");

          console.log(data?.phoneNo);
          setPhoneNumberError("");
          setPhoneNumber(data?.phoneNo != null ? data?.phoneNo : "");

          console.log(data?.city);
          setCityErrorMsg("");
          setCity(data?.city != null ? data?.city : "");

          console.log(data?.country);
          setCityErrorMsg("");
          setCountry(data?.country != null ? data?.country : "");
          if(data.country) {
            getCitiesByCountry(getCountryCodeByName(data.country)||'')
          }

          console.log(data?.postalCode);
          setCityErrorMsg("");
          setPostalCode(data?.postalCode != null ? data?.postalCode : "");

          console.log(data?.countryCode);
          setCountryCode(data?.countryCode != null ? data?.countryCode : "");

          setLatestCompanyurl(data?.companyID?.companyLogoBase64)

          setCompanyRegNumberErrorMsg("");
          setCompanyRegNumber(data?.companyRegNumber != null ? data?.companyRegNumber : "");
        }
      }
    } catch (error) {
      console.log('Error Occured for User Detail ', error)
    }
  }

  useEffect(() => {
    getUserDetail();
  }, [])

  // useEffect(() => {
  //   const fName = localStorage.getItem("firstName");
  //   console.log(fName);
  //   setFirstNameError("");
  //   setFirstName(fName != null ? fName : "");

  //   const lName = localStorage.getItem("lastName");
  //   console.log(lName);
  //   setLastNameError("");
  //   setLastName(lName != null ? lName : "");

  //   const userEmail = localStorage.getItem("userEmail");
  //   console.log(userEmail);
  //   setEmailError("");
  //   setEmail(userEmail != null ? userEmail : "");

  //   const userPhone = localStorage.getItem("userPhone");
  //   console.log(userPhone);
  //   setPhoneNumberError("");
  //   setPhoneNumber(userPhone != null ? userPhone : "");

  //   const userCity = localStorage.getItem("city");
  //   console.log(userCity);
  //   setCityErrorMsg("");
  //   setCity(userCity != null ? userCity : "");

  //   const userCountry = localStorage.getItem("country");
  //   console.log(userCountry);
  //   setCityErrorMsg("");
  //   setCountry(userCountry != null ? userCountry : "");

  //   const userPostalCode = localStorage.getItem("postalCode");
  //   console.log(userPostalCode);
  //   setCityErrorMsg("");
  //   setPostalCode(userPostalCode != null ? userPostalCode : "");

  //   const userCountryCode = localStorage.getItem("countryCode");
  //   console.log(userCountryCode);
  //   setCountryCode(userCountryCode != null ? userCountryCode : "");


  //   const compRegNumber = localStorage.getItem("companyRegNumber")?.trim();
  //   setCompanyRegNumberErrorMsg("");
  //   setCompanyRegNumber(compRegNumber != null ? compRegNumber : "");
  // }, []);




  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setFirstNameError(errorMsgs.emptyName);
    } else {
      setFirstNameError("");
    }
    setFirstName(item);
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setLastNameError(errorMsgs.emptyName);
    } else {
      setLastNameError("");
    }
    setLastName(item);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.emailRegex.test(item)) {
      setEmailError("");
    } else {
      setEmailError(errorMsgs.emptyEmail);
    }
    setEmail(item);
  };

  const onChangePhoneNo = (value: any, country: any) => {
    // const item = event.target.value;
    if (regexStrConst.phoneNumberRegex.test(value)) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError(errorMsgs.emptyPhone);
    }
    // setCountryCode(country?.countryCode)
    setPhoneNumber(value);
  };


  const validate = () => {
    if (email === "") setEmailError(errorMsgs.emptyEmail);
    if (firstName === "") setFirstNameError(errorMsgs.emptyName);
    if (lastName === "") setLastNameError(errorMsgs.emptyName);
    if (phoneNumber === "") setPhoneNumberError(errorMsgs.emptyPhone);
    if (city === "") setCityErrorMsg(errorMsgs.emptyCity);
    if (country === "") setCountryErrorMsg(errorMsgs.emptyCountry);
    if (postalCode === "") setPostalCodeErrorMsg(errorMsgs.emptyPostCode);
    if (companyRegNumber === '') setCompanyRegNumberErrorMsg(errorMsgs.emptyCompanyRegNo)
    // if (!companyLogo && !CompanyLogoBase64) { setCompanyLogoError(errorMsgs.emptyLogo) } else { setCompanyLogoError("") }
    // if (companyLogo) { setCompanyLogoError('')}

    return email === "" ||
      firstName === "" ||
      lastName === "" ||
      phoneNumber === "" ||
      city === "" ||
      country === "" ||
      postalCode === "" ||
      companyRegNumber === '' ||
      // (!CompanyLogoBase64 && !companyLogo) ||


      emailError ||
      firstNameError ||
      lastNameError ||
      phoneNumberError ||
      cityErrorMsg ||
      countryErrorMsg ||
      postalCodeErrorMsg ||
      companyRegNumberErrorMsg ||
      companyLogoError
      ? false
      : true;
  };

  const handleButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // console.log("=>", validate())
    const token = localStorage.getItem('token');
    if (validate()) {
      if (password === confirmPass) {
        try {
          setLoading(true);
          const companyName = localStorage.getItem("companyName")!


          const cleanedPhoneNumber = phoneNumber.replace(/^[+]+/g, "");
          const payload = {
            email: email,
            phoneNo: "+" + cleanedPhoneNumber,
            firstName: firstName,
            lastName: lastName,
            userId: userId,
            city: city,
            country: country,
            postalCode: postalCode,
            countryCode: countryCode ? countryCode : countries.find((c)=>c.country===country)?.alpha2,
            companyRegNumber: companyRegNumber,
            companyName: companyName,
            companyLogoBase64: companyLogoFile,
            // companyLogoBase64: CompanyLogoBase64,
            // companyLogoBase64: CompanyLogoBase64 ? CompanyLogoBase64 : companyLogo,
            token: token,
          };
          dispatch(UserUpdate(payload)).then(() => {
            setLoading(false)
            getUserDetail();
          });

          //this is to set new vales into localstorage so that if user refreshes the page
          //user will be able to see the new values into the form
          const valuesToUpdate = {
            userEmail: email,
            userPhone: "+" + phoneNumber.replace(/^[+]+/g, ""),
            firstName: firstName,
            lastName: lastName,
            city: city,
            country: country,
            postalCode: postalCode,
            companyRegNumber: companyRegNumber,
            companyLogo: CompanyLogoBase64 ? CompanyLogoBase64 : companyLogo
          };

          Object.entries(valuesToUpdate).forEach(([key, value]) => {
            localStorage.setItem(key, value);
          });

        } catch (error: any) {
          // Swal.fire({
          //   // icon: "error",
          //   imageUrl: ErrorImage,
          //   title: "Oopss",
          //   // text: "Something Went Wrong",
          //   text: error.message,

          // });
          dispatch(showAlert({
            type: 'error',
            title: 'Oopss',
            text: error.message,
            imageUrl: ErrorImage,
            imageAlt: 'Error Image'
          }));
        }

      } else {
        // Swal.fire({
        //   // icon: "error",
        //   imageUrl: ErrorImage,
        //   title: "Oopss",
        //   text: "Password should match",
        // });
        dispatch(showAlert({
          type: 'error',
          title: 'Oopss',
          text: `${t("sweetAlerts.passwordShouldMatch")}`,
          imageUrl: ErrorImage,
          imageAlt: 'Error Image'
        }));
      }
    }
  };

  const handleLogoUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      if (file.size > 1000000) {
        // Swal.fire({
        //   icon: "info",
        //   title: "Oopss",
        //   text: "Logo size should be less than 1MB",
        // });
        dispatch(showAlert({
          type: 'error',
          title: 'Oopss',
          text: `${t("sweetAlerts.logoSize")}`,
          icon: "info",
        }));
      } else {
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setCompanyLogoBase64(reader.result);
            setCompanyLogoError('')
            // console.log(CompanyLogoBase64)
          } else {
            console.error('Unexpected result type:', typeof reader.result);
          }
        };
        reader.readAsDataURL(file);
        setCompanyLogoFile(file)
      }
    }
  };

  const getCitiesByCountry = (code: string) => {
    if(code){
      let  tempCity = cityData.filter(city => city.country === code);
      const unique = tempCity.filter(
        (obj, index) =>tempCity.findIndex((item) => item.name === obj.name) === index
      );

      tempCity = unique.map(t => ({...t, label: t.name}))
      setCitiesByCountry(tempCity)
    }

};

  return (
    <div className="">
      {/* <div className="left_sidebar position-fixed">
        <LeftSideBar name="editRegistration" />
      </div>
      <div className="inner__content position-relative pb-5">
        <h1 className="convert_title">{t("editProfile")}</h1>
        <div className="convert-card-box">
          {/* <div className="point-box">
            <p className="left-point"></p>
            <p className="middle-point"></p>
            <p className="right-point"></p>
          </div> */}
      {/* <h2 className="right-title">{t("editregister")}</h2> */}
      <Row className="edit-profile justify-content-between">
        <Col xs={12} sm={12} md={7} lg={7} xl={7} className="mainFormBox">
          <div className="basicFormWidth inner__content position-relative">
            <h1 className="onBoarding-title">{t("editregister")}</h1>
            <div className="convert-card-box">
              {/* <div className="point-box">
            <p className="left-point"></p>
            <p className="middle-point"></p>
            <p className="right-point"></p>
          </div> */}
              {/* <h2 className="right-title">{t("editregister")}</h2> */}
              <p className="onBoarding-text">{t("registration.title.editdescription")}</p>

              <form className="reg-form" onSubmit={handleButtonClick}>
                <p className="inputLabel mt-2">{t("registration.firstName")}</p>
                <InputTypeText
                  placeholder={t("registration.firstName")}
                  value={firstName}
                  maxLength={20}
                  defaultValue={firstName}
                  onBlur={() =>
                    firstName === ""
                      ? setFirstNameError(errorMsgs.emptyName)
                      : setFirstNameError("")
                  }
                  onChange={onChangeFirstName}
                  errorMsg={firstNameError}
                />
                <p className="inputLabel mt-2">{t("registration.lastName")}</p>
                <InputTypeText
                  placeholder={t("registration.lastName")}
                  value={lastName}
                  maxLength={20}
                  defaultValue={lastName}
                  onBlur={() =>
                    lastName === ""
                      ? setLastNameError(errorMsgs.emptyName)
                      : setLastNameError("")
                  }
                  onChange={onChangeLastName}
                  errorMsg={lastNameError}
                />
                {/* <p className="company-text">{t("registration.companyName")}</p>
          <InputComponent
            className="company-inp"
            placeholder={t("registration.companyName")}
            value={companyName}
            onBlur={() =>
              companyName === ""
                ? setCompanyNameError(errorMsgs.emptyName)
                : setCompanyNameError("")
            }
            onChange={onChangeCompanyName}
            errorMsg={companyNameError}
          /> */}
                {/* <p className="companyadd-text">{t("registration.companyAddress")}</p>
          <InputComponent
            className="companyadd-inp"
            placeholder={t("registration.companyAddress")}
            value={companyAddress}
            onBlur={() =>
              companyAddress === ""
                ? setCompanyAddressError(errorMsgs.emptyAddress)
                : setCompanyAddressError("")
            }
            onChange={onChangeCompanyAddress}
            errorMsg={companyAddressError}
          /> */}
                <p className="inputLabel mt-2">{t("registration.email")}</p>
                <InputTypeText
                  type="email"
                  placeholder={t("registration.email")}
                  value={email}
                  maxLength={40}
                  defaultValue={email}
                  onBlur={() =>
                    email === ""
                      ? setEmailError(errorMsgs.emptyEmail)
                      : setEmailError("")
                  }
                  onChange={onChangeEmail}
                  errorMsg={emailError}
                />
                <p className="inputLabel mt-2">{t("registration.phoneNo")}</p>

                <PhoneInput
                  inputClass="countryPhoneInput"
                  country={"us"}
                  value={phoneNumber}
                  onChange={(value, country) => onChangePhoneNo(value, country)}
                  onBlur={() =>
                    phoneNumber === ""
                      ? setPhoneNumberError(errorMsgs.emptyPhone)
                      : setPhoneNumberError("")
                  }
                />
                <span className="errorMsg">
                  {phoneNumberError ? phoneNumberError : ""}
                </span>

                {/* <InputComponent
            className="phone-inp"
            placeholder="+3 (80) 00 000 00 00"
            value={phoneNumber}
            onBlur={() =>
              phoneNumber === ""
                ? setPhoneNumberError(errorMsgs.emptyPhone)
                : setPhoneNumberError("")
            }
            onChange={onChangePhoneNo}
            errorMsg={phoneNumberError}
          /> */}
                {/* <p className="createpass-text">{t("registration.createPass")}</p>
          <InputComponent
            className="createpass-inp"
            placeholder={t("registration.createPass")}
            type="password"
            value={password}
            onBlur={() =>
              password === ""
                ? setPasswordError(errorMsgs.emptyPasswordReg)
                : setPasswordError("")
            }
            onChange={onChangePassword}
            errorMsg={passwordError}
          />
          <p className="new_confnpass-text">{t("registration.confirmPass")}</p>
          <InputComponent
            type="password"
            className="new_confirm-inp"
            placeholder={t("registration.confirmPass")}
            value={confirmPass}
            onBlur={() =>
              confirmPass === ""
                ? setConfirmPassError(errorMsgs.confirmPassword)
                : setConfirmPassError("")
            }
            onChange={onChangeConfirmPassword}
            errorMsg={ConfirmpassError}
          /> */}


                <p className="inputLabel mt-4">{t("registration.country")}</p>
                {/* <InputComponent
              type="text"
              placeholder={t("registration.country")}
              value={country}
              defaultValue={country}
              onBlur={() =>
                country === ""
                  ? setCountryErrorMsg(errorMsgs.emptyCountry)
                  : setCountryErrorMsg("")
              }
              onChange={onChangeCountry}
              errorMsg={countryErrorMsg}
            /> */}
                <Form.Select
                  title="Select Country"
                  className="enter-num-inp"
                  aria-label="Default select example"
                  onChange={(e)=>onChangeCountry(e.target.value)}
                  value={country}
                  defaultValue={country}
                  onBlur={() => country === "" ? setCountryErrorMsg(errorMsgs.emptyCountry) : setCountryErrorMsg("")}
                // placeholder={t("registration.country")}
                >
                  <option value="">{t("addRecipient.form.selectCountry")}</option>
                  {countries && countries.map((ele, index: number) => (
                    <option value={ele.country} key={index}>
                      {ele.country}
                    </option>
                  ))}
                </Form.Select>
                <span className="errorMsg">
                  {countryErrorMsg ? countryErrorMsg : ""}
                </span>

                <p className="inputLabel mt-3">{t("registration.city")}</p>
                {/* <InputTypeText
                  type="text"
                  placeholder={t("registration.city")}
                  value={city}
                  maxLength={20}
                  defaultValue={city}
                  onBlur={() =>
                    city === ""
                      ? setCityErrorMsg(errorMsgs.emptyCity)
                      : setCityErrorMsg("")
                  }
                  onChange={onChangeCity}
                  errorMsg={cityErrorMsg}
                /> */}
                <AutoCompleteInput
                  inputData={citiesByCountry}
                  value={city}
                  onBlur={() => city === "" ? setCityErrorMsg(errorMsgs.emptyCity) : setCityErrorMsg("")}
                  onChange={onChangeCity}
                  errorMsg={cityErrorMsg && `${t("errorMsgs.emptyCity")}`}
                />

                <p className="inputLabel mt-4">{t("registration.postalCode")}</p>
                <InputTypeText
                  type="text"
                  placeholder={t("registration.postalCode")}
                  value={postalCode}
                  maxLength={20}
                  defaultValue={postalCode}
                  onBlur={() =>
                    postalCode === ""
                      ? setPostalCodeErrorMsg(errorMsgs.emptyPostCode)
                      : setPostalCodeErrorMsg("")
                  }
                  onChange={onChangePostalCode}
                  errorMsg={postalCodeErrorMsg}
                />

                <p className="inputLabel mt-2">{t("registration.companyRegistrationNumber")}</p>
                <InputTypeText
                  type="text"
                  placeholder={t("registration.companyRegistrationNumber")}
                  value={companyRegNumber}
                  maxLength={20}
                  onBlur={() => companyRegNumber === "" ? setCompanyRegNumberErrorMsg(errorMsgs.emptyCompanyRegNo) : setCompanyRegNumberErrorMsg("")}
                  onChange={onChangeCompanyRegNumber}
                  errorMsg={companyRegNumberErrorMsg}
                />

                {/* <img  className="componyLogo" style={{ width: 100, marginRight: "23px",height:100,border:"solid 1px" }} src={companyLogo} alt="logo" /> */}



                <img
                  className="componyLogo"
                  style={{ width: 100, marginRight: "23px", border: "solid 1px" }}
                  src={CompanyLogoBase64 || companyLogo}  // Use CompanyLogoBase64 if available, otherwise fallback to companyLogo
                  alt="logo"
                />

                <p className="inputLabel mt-2">{t("registration.companyLogo")}</p>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleLogoUpload} // Add a function to handle file upload
                />
                <span className="errorMsg">{companyLogoError}</span>
                <br></br>

                {/* <div className="terms-conditions">
            <input
              style={{ marginRight: 5 }}
              type="checkbox"
              id="agree"
              onChange={checkboxHandler}
            />
            <label className="term-condition-text" htmlFor="agree">
              {" "}
              {t("registration.termCondition")}{" "}
              <a
                href={
                  "https://www.b4bpayments.com/prepaid/terms-and-conditions/"
                }
              >
                <b>{t("registration.termConditionText")}</b>
              </a>
            </label>
          </div> */}
                {/* <Button disabled={!agree} type="submit" className="reg_btn">
              {loading ? (
                <Spinner animation={"border"} />
              ) : (
                `${t("registration.updateAcc")}`
              )}
            </Button> */}
                <BasicButton type="submit" text={t("registration.updateAcc")} isLoading={loading} disabled={!agree} />

                {/* <Button onClick={() => navigate("/")} className="reg_btn">
              {t("registration.backToHome")}
            </Button> */}
                <BasicButton type="submit" className="mt-4" text={t("registration.backToHome")} onClick={() => navigate("/")} />

              </form>
            </div>
          </div>
        </Col>
        <Col md={5} lg={4} xl={4} className="MonthlyRevenueTracker">
          {/* <RightSideBar exchangeRates accountSwitch summaryBox interestAccount /> */}
          <ExchangeRatesCard />
        </Col>
      </Row>

    </div>
  );
};
export default EditRegistration;
