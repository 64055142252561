import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
// import BankAcc from "../../../assets/images/bank-accounts.png";
// import downArrow from "../../../assets/images/down-arrow.png";
import { AppDispatch, RootState } from "../../../redux/store";
import CardDetailsTypes from "../../../pages/BankAccounts/interface";
import { getAllAccounts } from "../../../redux/toolkit/getAllUserAccount/operation";
import "./index.css";
import { DropDownArrowDownIconWhite, CardWhiteIcon } from "../../../constants/Images";

const AccountSwitch = (props: any) => {
  const { setReloadRatesCard } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userId: any = localStorage.getItem("userId");
  const [cardDetails, setCardDetails] = useState<any>([]);
  const [accountsList, setAccountsList] = useState(false);
  const [accountName, setAccountName] = useState('');
  const accountSelector: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );

  const {t} = useTranslation(); 

  useEffect(() => {
    dispatch(getAllAccounts(userId));
  }, [dispatch]);
  useEffect(() => {
    if (accountSelector?.data) {
      setCardDetails(accountSelector?.data.data);
    }
  }, [accountSelector, cardDetails]);
  const handleAccountSelect = (ele: any) => {
    setAccountName(ele?.accountName)
    const customEvent = new CustomEvent("CardValue", {
      detail: { accountId: ele.accB4BUUID, activeAccId: ele._id },
    });
    document.dispatchEvent(customEvent);
    localStorage.setItem("accountId", ele.accB4BUUID);
    localStorage.setItem("activeAccId", ele._id);
    localStorage.setItem("AccountData", JSON.stringify(ele));
    setAccountsList(false)
    localStorage.setItem("CurrForExhangeRateCard",ele.currency)
    setReloadRatesCard((prev:any) => !prev);
  };

  useEffect(() => {
    const storedDataString = localStorage.getItem('AccountData');
  const getSelectedCardFromLS: any = storedDataString ? JSON.parse(storedDataString) : {};
  console.log('getSelectedCardFromLS: ', getSelectedCardFromLS);
  setAccountName(getSelectedCardFromLS?.accountName)
  },[])


  return (
    <>
      <div className="createNewAccount d-flex justify-content-between align-items-center"  onClick={() => setAccountsList(!accountsList)}>
        <div style={{marginRight:"10px"}}>
          <img src={CardWhiteIcon} />
        </div>
        <div style={accountName?.length >23 ? {fontSize:'12px',fontWeight: "600"}:{fontSize:"14px",fontWeight: "600"}}> {accountName ? accountName : t("generalInfo.account")}</div>
        <div style={{marginLeft:'5px'}}>
          <img src={DropDownArrowDownIconWhite}/>
        </div>
      </div>

      <div className="accountList" style={accountsList ? {display:"block"} : {display:"none"}}>
        {/* <div className=""> */}
            <ul className="list-style-none p-0 m-0">
              {cardDetails && cardDetails.length > 0 ? (
                cardDetails.map((ele: CardDetailsTypes, index: number) => {
                  return !ele.inactive ? (
                    <li key={index}>
                      <button
                        className="switch-account-btn"
                        style={{ border: "none", padding: "10px" }}
                        onClick={() => handleAccountSelect(ele)}
                      >
                        {ele.accountName}
                      </button>
                    </li>
                  ) : null;
                })
              ) : (
                <p>{t("noAccounts")}</p>
              )}
            </ul>
          {/* </div> */}
        </div>

      {/* ========================================== */}
      {/* <div className="dropdown">
        <button className="dropdown-acc">
          <div className="d-flex align-items-center">
            <img
              className="bank-acc-img-sb"
              src={BankAcc}
              alt="bank-link-ico"
            ></img>
            <div className="user-info">
              <p className="user-fname">{t("accounts")}</p>
            </div>
          </div>
          <img className="down-arrow" src={downArrow} alt="down-arrow"></img>
        </button>
        <div className="dropdown-content">
          <ul className="list-style-none p-0 m-0">
            {cardDetails && cardDetails.length > 0 ? (
              cardDetails.map((ele: CardDetailsTypes, index: number) => {
                return !ele.inactive ? (
                  <li key={index}>
                    <button
                      className="switch-account-btn"
                      style={{ border: "none", padding: "10px" }}
                      onClick={() => handleAccountSelect(ele)}
                    >
                      {ele.accountName}
                    </button>
                  </li>
                ) : null;
              })
            ) : (
              <p>{t("noAccounts")}</p>
            )}
          </ul>
        </div>
      </div> */}
    </>
  );
};
export default AccountSwitch;
