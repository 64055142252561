import React, { useEffect, useState,useRef } from "react";
import RightSideBar from "../../components/shared/RightSidebar";
import InputComponent from "../../components/shared/Input/Index";
import LeftSideBar from "../../components/shared/LeftSidebar";
import Icon from "../../assets/images/Icon.png";
import Form from "react-bootstrap/Form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { GetALlbeneficiaries } from "../../redux/toolkit/beneficiaries/operation";
import {
  SendFundsData,
  SendFundsRecurring,
} from "../../redux/toolkit/sendFunds/operations";
import {
  errorMsgs,
  regexStrConst,
  LOGO_URL,
} from "../../constants/stringConstants";
import { Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import BeneficiaryTypes from "./interface";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import SmsIcon from "@mui/icons-material/Sms";
import EastIcon from "@mui/icons-material/East";
import CallIcon from "@mui/icons-material/Call";
import { Button, Link, Tooltip, Typography, Box } from "@mui/material";
import {
  OTPVerificationPayment,
  QrVerificationPayment,
} from "../../redux/toolkit/verifyPayment/operation";
// import Accordion from "@mui/material/Accordion";
import Accordion from 'react-bootstrap/Accordion';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Footer from "../../components/shared/Footer";
import "./index.css";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import OtpInput from "../../components/shared/OTP";
import {
  OTPVerification,
  SendOTP,
  SendOTPVoice,
} from "../../redux/toolkit/auth/operation";
import COLORS from "../../utils/colors";
import { AddbeneficiaryForm } from "../Addbeneficiary/AddBenificiaryForm";
import ExchangeRatesCard from "../../components/shared/ExchangeRatesCard";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";
import IconButton from "../../components/shared/Buttons/IconButton";
import { ModalTransferIcon, PaymentVerifictionIcon } from "../../constants/Images";
import FundTransferDetails from "./Fund Transfer Details";
import ModalHeader from "../../components/shared/Modal/ModalHeader";
import ModalBannerSection from "../../components/shared/Modal/ModalBannerSection";
// For material ui dropdown
import { DropDownArrowIconSolid, DropDownInputWhite } from "../../components/shared/DropdownSelect";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { getCardAccountBalance } from "../../redux/toolkit/accountBalances/operation";
import InfoIcon from '@mui/icons-material/Info';

function SendFunds() {
  const dispatch = useDispatch<AppDispatch>();
  const beneficiariesData: any = useSelector(
    (state: RootState) => state.beneficiary.data
  );
  const [beneficiariesId, setBeneficiariesId] = useState([]);
  const [uuid, setuuid] = useState("");


  const [showOTPInput, setShowOTPInput] = useState(false);
  const [paymentRef, setPaymentRef] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentDescriptionError, setPaymentDescriptionError] = useState("");
  
  const [amount, setAmount] = useState("");
  const [purpose, setPurpose] = useState("");
  // const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [twofaErr, setTwofaErr] = useState("");
  const [paymentRefError, setPaymentRefError] = useState("");
  // const [descriptionError, setDescriptionError] = useState("");
  const [recipientError, setRecipientError] = useState("");
  const accId: any = localStorage.getItem("activeAccId");
  const accountId: any = localStorage.getItem("accountId");
  const accData: any = localStorage.getItem("AccountData");
  
  const ParsedData: any = JSON.parse(accData);
  const [currency, setCurrency] = useState(ParsedData?.currency);
  const userId: any = localStorage.getItem("userId");
  const userEmail: any = localStorage.getItem("userEmail");
  const userPhoneNo: any = localStorage.getItem("userPhone");
  const roles: any = localStorage.getItem("roles");
  const [accUUID, setAccUUID] = useState(accountId);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [recipientModal, setRecipientModal] = useState(false);
  const [code, setCode] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [loadingOTPVerify, setLoadingOTPVerify] = useState(false);
  const [tokenError, setTokenError] = useState("");
  const [btnSMSVisible, setBtnSMSVisible] = useState(false);
  const [btnVoiceVisible, setBtnVoiceVisible] = useState(false);
  const [loadingOTPVoice, setLoadingOTPVoice] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [paymentType, setPaymentType] = useState("REGULAR");
  const [recurringType, setRecurringType] = useState("");
  const [recurringDate, setRecurringDate] = useState("");
  const [recurringValue, setRecurringValue] = useState("");
  const [reloadRatesCard, setReloadRatesCard] = useState(false);
  const accountIdSelecto: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const BalanceSelector: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.data
  );
  const balanceStatus: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.status
  );
  const [balance, setBalance] = useState<number>(0);
  const [balanceError, setBalanceError] = useState(false);
  const [otpBtnText, setOtpBtnText] = useState("Send");
  const [recipientSelectVal, setRecipientSelectVal] = useState("");
  
  const [otp, setOtp] = useState("");
  const onChange = (value: string) => setOtp(value);
  const sendFundsSelec: any = useSelector(
    (state: RootState) => state.sendFundsDetails.data
  );
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  // This arrangement can be altered based on how we want the date's format to appear.
  const currentDate = `${day}-${month}-${year}`;

  const { t } = useTranslation();
  const recipientModalRef = useRef(null);

  const getDateFromDay = (dayName: any) => {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const today = new Date(); // Current date and time
    const currentDayIndex = today.getDay(); // Index of today
    const targetDayIndex = daysOfWeek.indexOf(dayName); // Index of the target day
  
    if (targetDayIndex === -1) {
      throw new Error("Invalid day name provided");
    }
  
    // Calculate days difference; add 7 to handle wrap-around
    const daysDifference = (targetDayIndex - currentDayIndex + 7) % 7;
  
    // Calculate the target date
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + daysDifference);
  
    // Return the ISO string (local time adjustment)
    return new Date(targetDate.getTime() - targetDate.getTimezoneOffset() * 60000).toISOString();
  };

  useEffect(() => {
    localStorage.setItem("CurrForExhangeRateCard",currency)

  }, [currency])

  useEffect(() => {
    getBeneficiaries();
  }, [dispatch, accUUID]);

  useEffect(() => {
    dispatch(getAllAccounts(userId));
  }, [dispatch]);

  useEffect(() => {
    if (accountIdSelecto) {
      setAccountDetails(accountIdSelecto?.data?.data);
    }
  }, [accountIdSelecto, accountDetails]);

  useEffect(() => {
    if (beneficiariesData) {
      setBeneficiariesId(beneficiariesData.data?.data);
    }
  }, [beneficiariesData]);

  useEffect(() => {
    dispatch(getCardAccountBalance(accUUID));
  }, [dispatch, accUUID]);

  useEffect(() => {
    if (balanceStatus === 'succeeded' && BalanceSelector) {
      setBalance(BalanceSelector?.data.data?.float);
    } else if (balanceStatus === 'failed') {
      setBalance(0); 
    }
  }, [balanceStatus, BalanceSelector]);
  const handleSendOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setBtnVoiceVisible(true);
    setLoadingOTP(true);
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTP(payload)).then(() => {
      setBtnVoiceVisible(false);
      setLoadingOTP(false);
      setShowOTPInput(true);
      setOtpBtnText("Resend");
    });
  };
  const handleSendOTPVoiceButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setBtnSMSVisible(true);
    setLoadingOTPVoice(true);
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTPVoice(payload)).then(() => {
      setBtnSMSVisible(false);
      setLoadingOTPVoice(false);
      setShowOTPInput(true);
      setOtpBtnText("Resend");
    });
  };
  const verifyOTPLengthOnBlur = (e: any) => {
    if (e.length < 6) {
      setOtpErrorMsg("Enter all otp digits");
    } else {
      setOtpErrorMsg("");
    }
  
  };
  const handleVerifyOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setLoadingOTPVerify(true);
    e.preventDefault();

    const payloadData = {
      phoneNo: userPhoneNo,
      otp: otp,
      accB4BUUID:accUUID,

    };
    dispatch(OTPVerificationPayment(payloadData)).then((val: any) => {
      setLoadingOTPVerify(false);
      if (val.payload.message) {
        setLoadingOTPVerify(true);
        const payloadData = {
          beneficiary_uuid: uuid,
          // description: description,
          payment_reference: paymentRef,
          payment_amount: amount,
          payment_currency: currency,
          payment_reason: purpose,
          // uuid: accUUID,
          uuid: accUUID,
          userID: accId,
        };

        dispatch(SendFundsData(payloadData)).then((val) => {
          setLoadingOTPVerify(false);
          setShow(false);
          setShow1(true);
          //setAmount("");
        });
      }
      setBtnSMSVisible(false);
      setBtnVoiceVisible(false);
    });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
      const item = event.target.value;  
      // Check if the input is empty, in which case we should clear the error and amount.
      if (item === "") {
        setAmountError("");
        setBalanceError(false);
        setAmount("");
        return;
      }
      const itemNumber = parseFloat(item);
      // Perform validation only if the input is a valid number
      if (regexStrConst.amountRegex.test(item)) {
        setAmountError("");
        // Check if the entered amount exceeds the balance
        if (itemNumber > balance) {
          setBalanceError(true);
          setAmount("");
        } else {
          setBalanceError(false);
          setAmount(item);
        }
      } else {
        setAmountError(errorMsgs.emptyAmount);
      }
    };

  const onChangePaymentRef = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    // Use regex to allow only alphanumeric characters (A-Z, a-z, 0-9)
    const sanitizedValue = item.replace(/[^A-Za-z0-9\s]/g, "");
    event.target.value = sanitizedValue;
    setPaymentRefError("");
    setPaymentRef(sanitizedValue);
  };

  // const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const item = event.target.value;
  //   setDescriptionError("");
  //   setDescription(item);
  // };

  const validate = () => {
    if (amount === "" && !balanceError) setAmountError(errorMsgs.emptyAmount);
    // if (description === "") setDescriptionError(errorMsgs.emptyDescription);
    if (paymentRef === "") setPaymentRefError(errorMsgs.emptyPaymentRefError);
    if (uuid === "add") setRecipientError(errorMsgs.selectRecipient);
    return uuid === "" ||
      uuid === "add" ||
      amount === "" ||
      // description === "" ||
      paymentRef === "" ||
      amountError ||
      // descriptionError ||
      paymentRefError ||
      recipientError
      ? false
      : true;
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (validate()) {
      setShow(true);
    }
  };
  const handleClose = () => {
    setShow(false);
    setOtp("");
    setShow1(false);
  };
  const handleCloseAddRecipient = () => {
    setRecipientModal(false);
  };
  const getBeneficiaries = () => {
    dispatch(GetALlbeneficiaries(accUUID));
  };

  const handleRecipientSelect = (guid: any) => {
    if (guid === "add") {
      setuuid("");
      setRecipientSelectVal("");
      setRecipientModal(true);
    } else {
      setuuid(guid);
      setRecipientSelectVal(guid);
      setRecipientError("");
    }
  };
  const handleVerifyPayment = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading2(true);

    if(!code) {
      setTwofaErr("2FA code required")
      setCode("");
      setLoading2(false);
      return null;
    }

    const payloadData = {
      phoneNo: userPhoneNo,
      email: userEmail,
      token: code,
      accB4BUUID: accUUID,
    };

    // part one to test purpose
    if (isRecurring || roles?.includes('ACCOUNTANT')) {
      // const payloadData = {
      //   beneficiary_uuid: uuid,
      //   description: description,
      //   payment_reference: paymentRef,
      //   payment_amount: amount,
      //   payment_currency: currency,
      //   payment_reason: purpose,
      //   uuid: accUUID,
      //   userID: accId,
      //   recurringType: recurringType,
      //   recurringDate: recurringValue
      // };

      const payloadData: any = {
        beneficiary_uuid: uuid,
        // description: description,
        payment_reference: paymentRef,
        payment_amount: amount,
        payment_currency: currency,
        payment_reason: purpose,
        uuid: accUUID,
        userID: accId,
        token: code,
        isRecurring: false
      };

      if(isRecurring) {
        payloadData['recurringType'] = recurringType;
        payloadData['recurringDate'] = recurringValue;
        // payloadData['isRecurring'] = true;
        payloadData['isScheduling'] = false;
      } else if(roles?.includes('ACCOUNTANT')) {
        // payloadData['isRecurring'] = false;
        payloadData['isScheduling'] = true;
        payloadData['accountantID'] = userId;
      }

      
      dispatch(SendFundsRecurring(payloadData)).then((res: any) => {
        if (res?.error?.name === "Error") setShow1(false)
        else setShow1(true);
        
        setLoading2(false);
        setShow(false);
        // setShow1(true);
        setIsRecurring(false);
        setRecurringType("");
        setRecurringDate("");
        setRecurringValue("")
        setAmount("");
        setCode("");
      });
    } else {
      dispatch(QrVerificationPayment(payloadData)).then((val: any) => {
        setLoading2(false);
        setCode("");
        if (val.payload.message) {
          setLoading2(true);
          const payloadData = {
            beneficiary_uuid: uuid,
            // description: description,
            payment_reference: paymentRef,
            payment_amount: amount,
            payment_currency: currency,
            payment_reason: purpose,
            uuid:  accUUID,
            userID: accId,
          };
          dispatch(SendFundsData(payloadData)).then((val:any) => {
            if (val?.error?.name ==="Error"){
              setShow1(false);
            }else {
              setShow1(true);
            }
            setLoading2(false);
            setShow(false);
            // setAmount("");
          });
        }
      });
    }

    // setLoading2(false);

    //with respect to old implementation
    // dispatch(QrVerificationPayment(payloadData)).then((val: any) => {
    //   setLoading2(false);
    //   if (val.payload.message) {
    //     setLoading2(true);
    //     const payloadDataRecurring = {
    //       beneficiary_uuid: uuid,
    //       description: description,
    //       payment_reference: paymentRef,
    //       payment_amount: amount,
    //       payment_currency: currency,
    //       payment_reason: purpose,
    //       uuid: accUUID,
    //       userID: accId,
    //       recurringType: recurringType,
    //       recurringDate: recurringValue
    //     };
    //     const payloadData = {
    //       beneficiary_uuid: uuid,
    //       description: description,
    //       payment_reference: paymentRef,
    //       payment_amount: amount,
    //       payment_currency: currency,
    //       payment_reason: purpose,
    //       uuid: accUUID,
    //       userID: accId,
    //     };

    //     if (isRecurring) {
    //       
    //       dispatch(SendFundsRecurring(payloadDataRecurring)).then((res: any) => {
    //        
    //         setLoading2(false);
    //         setShow(false);
    //         setShow1(true);
    //         // setAmount("");
    //       });
    //     } else {
    //       

    //       dispatch(SendFundsData(payloadData)).then((val) => {
    //         setLoading2(false);
    //         setShow(false);
    //         setShow1(true);
    //         setIsRecurring(false);
    //         setRecurringType("");
    //         setRecurringDate("");
    //         setRecurringValue("")
    //         // setAmount("");
    //       });
    //     }
    //   }
    // });
  };


  const handleVerifyPaymentUpdated = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading2(true);
    setLoading(true);

    if(!code && roles?.includes('ADMIN')) {
      setTwofaErr("2FA code required")
      setCode("");
      setLoading2(false);
      setLoading(false);
      return null;
    }

    const payloadData = {
      phoneNo: userPhoneNo,
      email: userEmail,
      token: code,
      accB4BUUID: accUUID,
    };

    if (roles?.includes('ACCOUNTANT') || ( roles?.includes('ADMIN') && paymentType !== 'REGULAR' )) {
      const beneficiaryObj: any = beneficiariesId.filter((obj: { guid: string }) => obj.guid === uuid);
      
      const payloadData: any = {
        beneficiary_uuid: uuid,
        payment_reference: paymentRef,
        payment_amount: amount,
        payment_currency: currency,
        payment_reason: purpose,
        uuid: accUUID,
        userID: accId,
        token: roles?.includes('ACCOUNTANT') ? "ACCOUNTANT" : code,
        isRecurring: false,
        description: paymentDescription,
      };

      if(beneficiaryObj && beneficiaryObj?.length > 0) {
        payloadData['beneficiary_name'] = beneficiaryObj[0]?.name;
        payloadData['beneficiary_iban'] = beneficiaryObj[0]?.account;
      }

      if(isRecurring || paymentType === 'RECURRING') {
        const offsetHours = -new Date(recurringValue).getTimezoneOffset() / 60
        payloadData['recurringType'] = recurringType;
        if(recurringType !== 'weekly') {
          const localetorecurringformat = new Date(recurringValue);
          localetorecurringformat.setHours(localetorecurringformat.getHours() + offsetHours);
          payloadData['recurringDate'] = localetorecurringformat.toISOString();
          payloadData['recurringDateOffset'] = `${offsetHours}`;
          payloadData['recurringDateUtc'] = new Date(recurringValue).toISOString();
        } else {
          const timetime = getDateFromDay(recurringValue);
          const isostr = new Date(timetime);
          isostr.setHours(isostr.getHours() - (-new Date().getTimezoneOffset() / 60))
          console.log('.... Locale Time', timetime)
          console.log('.... Locale Time in ISO', isostr.toISOString())
          // payloadData['recurringDate'] = recurringValue;
          payloadData['recurringDate'] = timetime;
          payloadData['recurringDateUtc'] = isostr.toISOString();
          payloadData['recurringDateOffset'] = `${-new Date().getTimezoneOffset() / 60}`;
        }
        payloadData['isScheduling'] = false;
      } else {
        const offsetHours = -new Date(recurringValue).getTimezoneOffset() / 60
        const localetorecurringformat = new Date(recurringValue);
        localetorecurringformat.setHours(localetorecurringformat.getHours() + offsetHours);
        payloadData['recurringDate'] = localetorecurringformat.toISOString();
        payloadData['recurringDateOffset'] = `${offsetHours}`;
        payloadData['recurringDateUtc'] = new Date(recurringValue).toISOString();
        payloadData['isScheduling'] = true;
        payloadData['accountantID'] = userId;
      }

      dispatch(SendFundsRecurring(payloadData)).then((res: any) => {
        if (res?.error?.name === "Error") setShow1(false)
        else setShow1(true);
        
        setLoading2(false);
        setLoading(false);
        setShow(false);
        setIsRecurring(false);
        setRecurringType("");
        setRecurringDate("");
        setRecurringValue("")
        setAmount("");
        setCode("");
        setPaymentType('REGULAR')
        setPaymentDescription("");
      });
    } else if (paymentType === 'REGULAR') {
      dispatch(QrVerificationPayment(payloadData)).then((val: any) => {
        setLoading2(false);
        setLoading(false);
        setCode("");
        if (val.payload.message) {
          setLoading2(true);
          setLoading(true);
          const payloadData = {
            beneficiary_uuid: uuid,
            payment_reference: paymentRef,
            payment_amount: amount,
            payment_currency: currency,
            payment_reason: purpose,
            uuid:  accUUID,
            userID: accId,
          };
          dispatch(SendFundsData(payloadData)).then((val:any) => {
            if (val?.error?.name ==="Error"){
              setShow1(false);
            } else {
              setShow1(true);
            }
            setLoading2(false);
            setLoading(false);
            setShow(false);
          });
        }
      });
    }
  };

  const radioChangeHandler = (e: any) => {
    setRecurringType(e.target.value);
    if (recurringType === "weekly") setRecurringDate("");
    // if(recurringType==="monthly") setRecurringValue('')
  };
  const recurringTypeHandler = (value: any) => {
   

    if (recurringType === "monthly") {
      const now = new Date();
      const targetDate = new Date(value);
      targetDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
      // const localDateTime = targetDate.toLocaleString();
      const localDateTime = targetDate.toISOString();
      // const date: any = new Date(value).toLocaleString();
      // setRecurringValue(date); //date for api
      setRecurringValue(localDateTime); //date for api
      setRecurringDate(value); // date for ui
    }
    if (recurringType === "weekly") {
      setRecurringValue(value.trim().toLowerCase()); // value will be day name
      setRecurringDate("");
    }
  };

  const schedulingTypeHandler = (value: any) => {
    const now = new Date();
    const targetDate = new Date(value);
    targetDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
    // const localDateTime = targetDate.toLocaleString();
    const localDateTime = targetDate.toISOString();
    
    // const date: any = new Date(value);
    // setRecurringValue(date); //date for api
    setRecurringValue(localDateTime); //date for api
    setRecurringDate(value); // date for ui
  };

  console.log(beneficiariesId,"----======-----> beneficiariesId");
  return (
    <>
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="sendFunds" />
      </div> */}

      <Row className="SendFunds justify-content-between">
        {/* <div className="left_sidebar position-fixed">
          <LeftSideBar name="sendFunds" />
        </div> */}
        <Col xs={12} sm={12} md={7} lg={7} xl={7} className="mainFormBox">
          <div className="inner__content position-relative">
            <div className="basicFormWidth">
              <h1 className="mainHeading">{t("sendFunds.title")}</h1>
              <form className="" onSubmit={roles?.includes('ACCOUNTANT') ? handleVerifyPaymentUpdated : handleSubmit}>
                <p className="formInputLabel">{t("sendFunds.form.account")}</p>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    //   value={age}
                    onChange={(e: any) => {
                      const accountData = JSON.parse(e.target.value);
                      setAccUUID(accountData.accB4BUUID);
                      setBeneficiariesId([]);
                      setCurrency(accountData.currency);
                      setAmount("");
                      setReloadRatesCard(prev => !prev);
                    }}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value={JSON.stringify(ParsedData)}>
                    {t("sendFunds.form.selectedAcc")}: {ParsedData?.accountName}
                  </option>
                  {accountDetails &&
                    accountDetails.map((ele: any, index: number) =>
                      !ele.inactive ? (
                        <option key={index} value={JSON.stringify(ele)}>
                          {ele.accountName}
                        </option>
                      ) : null
                    )}
                    </NativeSelect>
                    </FormControl>

                {/* <Form.Select
                  title="Select a account"
                  required={true}
                  // className="enter-num-inp"
                  className="basicDropDownStyles"
                  aria-label="Default select example"
                  onChange={(e: any) => {
                    localStorage.setItem("AccountData", e.target.value);
                    const accountData = JSON.parse(e.target.value);
                    setAccUUID(accountData.accB4BUUID);
                  }}
                >
                  <option value={JSON.stringify(ParsedData)}>
                    {t("sendFunds.form.selectedAcc")}: {ParsedData?.accountName}
                  </option>
                  {accountDetails &&
                    accountDetails.map((ele: any, index: number) =>
                      !ele.inactive ? (
                        <option key={index} value={JSON.stringify(ele)}>
                          {ele.accountName}
                        </option>
                      ) : null
                    )}
                </Form.Select> */}

                <p className="formInputLabel">{t("sendFunds.form.recipient")}</p>
                {/* <Form.Select
                  // title="Select a recipient"
                  required={true}
                  // className="enter-num-inp"
                  className="basicDropDownStyles"
                  aria-label="Default select example"
                  // onChange={(e) => setuuid(e.target.value)}
                  onChange={(e) => handleRecipientSelect(e.target.value)}
                  value={recipientSelectVal}
                >
                  <option value="">
                    {t("sendFunds.form.selectRecipient")}
                  </option>
                  {beneficiariesId && beneficiariesId.length > 0 ? (
                    beneficiariesId.map(
                      (ele: BeneficiaryTypes, index: number) => (
                        <option value={ele.guid} key={index}>
                          {ele.name + " " + ele.account}
                        </option>
                      )
                    )
                  ) : (
                    <option>{t("sendFunds.form.noRecipient")}</option>
                  )}
                  <hr />
                  <option
                    value="add"
                    className="fw-bold"
                    style={{ padding: "20px !important", fontSize: "18px" }}
                  >
                    {t("addRecipient.title")}
                  </option>
                </Form.Select> */}

                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    onChange={(e) => handleRecipientSelect(e.target.value)}
                    value={recipientSelectVal}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value="">
                    {t("sendFunds.form.selectRecipient")}
                  </option>
                  {beneficiariesId && beneficiariesId.length > 0 ? (
                    beneficiariesId.map(
                      (ele: BeneficiaryTypes, index: number) => (
                        <option value={ele.guid} key={index}>
                          {ele.name + " " + ele.account}
                        </option>
                      )
                    )
                  ) : (
                    <option>{t("sendFunds.form.noRecipient")}</option>
                  )}
                  <hr />
                  <option
                    value="add"
                    className="fw-bold"
                    style={{ padding: "20px !important", fontSize: "18px", cursor:"pointer",}}
                  >
                    {t("addRecipient.title")}
                  </option>
                  </NativeSelect>
                </FormControl>
                <span className="errorMsg">{recipientError &&t("errorMsgs.selectRecipient")}</span>

                <p className="formInputLabel">{t("sendFunds.form.paymentRef")}</p>
                <InputTypeText
                  placeholder={t("sendFunds.form.paymentRefP")}
                  value={paymentRef}
                  onBlur={() =>
                    paymentRef === ""
                      ? setPaymentRefError(errorMsgs.emptyPaymentRefError)
                      : setPaymentRefError("")
                  }
                  onChange={onChangePaymentRef}
                  errorMsg={paymentRefError && `${t("errorMsgs.emptyPaymentRefError")}`}
                />
                <p className="formInputLabel">{t("sendFunds.form.paymentCurr")}</p>
                {/* <Form.Select
                  // className="enter-num-inp"
                  className="basicDropDownStyles"
                  required={true}
                  aria-label="Default select example"
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option value="">{t("sendFunds.form.paymentCurrP")}</option>
                  <option>{ParsedData?.currency}</option>
                </Form.Select> */}

                {/* <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    onChange={(e) => setCurrency(e.target.value)}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value="">{t("sendFunds.form.paymentCurrP")}</option>
                  <option>{ParsedData?.currency}</option>
                  </NativeSelect>
                  </FormControl> */}
                  <InputTypeText
                  placeholder={currency}
                  disabled
                  type="text"
                />

                <p className="formInputLabel">{t("sendFunds.form.purpose")}</p>
                {/* <Form.Select
                  // className="enter-num-inp"
                  className="basicDropDownStyles"
                  required={true}
                  aria-label="Default select example"
                  onChange={(e) => setPurpose(e.target.value)}
                >
                  <option value="">{t("sendFunds.reason.title")}</option>
                  <option>{t("sendFunds.reason.health")}</option>
                  <option>{t("sendFunds.reason.education")}</option>
                  <option>{t("sendFunds.reason.flatRent")}</option>
                  <option>{t("sendFunds.reason.locationCosts")}</option>
                  <option>{t("sendFunds.reason.utilities")}</option>
                  <option>{t("sendFunds.reason.telephoneAndInternet")}</option>
                  <option>{t("sendFunds.reason.businessInsurance")}</option>
                  <option>{t("sendFunds.reason.officeEquipment")}</option>
                  <option>{t("sendFunds.reason.employee")}</option>
                  <option>{t("sendFunds.reason.marketing")}</option>
                  <option>{t("sendFunds.reason.tax")}</option>
                  <option>{t("sendFunds.reason.business")}</option>
                  <option>{t("sendFunds.reason.businessMeals")}</option>
                  <option>{t("sendFunds.reason.businessTravel")}</option>
                  <option>{t("sendFunds.reason.legal")}</option>
                  <option>{t("sendFunds.reason.home")}</option>
                  <option>{t("sendFunds.reason.loan")}</option>
                  <option>{t("sendFunds.reason.maintenance")}</option>
                  <option>{t("sendFunds.reason.other")}</option>
                </Form.Select> */}

                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    onChange={(e) => setPurpose(e.target.value)}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                  <option value="">{t("sendFunds.reason.title")}</option>
                  <option>{t("sendFunds.reason.health")}</option>
                  <option>{t("sendFunds.reason.education")}</option>
                  <option>{t("sendFunds.reason.flatRent")}</option>
                  <option>{t("sendFunds.reason.locationCosts")}</option>
                  <option>{t("sendFunds.reason.utilities")}</option>
                  <option>{t("sendFunds.reason.telephoneAndInternet")}</option>
                  <option>{t("sendFunds.reason.businessInsurance")}</option>
                  <option>{t("sendFunds.reason.officeEquipment")}</option>
                  <option>{t("sendFunds.reason.employee")}</option>
                  <option>{t("sendFunds.reason.marketing")}</option>
                  <option>{t("sendFunds.reason.tax")}</option>
                  <option>{t("sendFunds.reason.business")}</option>
                  <option>{t("sendFunds.reason.businessMeals")}</option>
                  <option>{t("sendFunds.reason.businessTravel")}</option>
                  <option>{t("sendFunds.reason.legal")}</option>
                  <option>{t("sendFunds.reason.home")}</option>
                  <option>{t("sendFunds.reason.loan")}</option>
                  <option>{t("sendFunds.reason.maintenance")}</option>
                  <option>{t("sendFunds.reason.other")}</option>
                    </NativeSelect>
                    </FormControl>

                <p className="formInputLabel">{t("sendFunds.form.amount")}</p>
                <InputTypeText
                  placeholder="1"
                  type="number"
                  step="any"
                  value={amount}
                  onChange={onChangeAmount}
                  errorMsg={amountError && `${t("errorMsgs.emptyAmount")}`}
                />

                {balanceError && 
                <p style={{color: "red", marginTop: "-10px"}}>{t("errorMsgs.amountGreaterThanBalance")}</p>
                }

                {roles?.includes('ADMIN') || roles?.includes('ACCOUNTANT') ? (
                  <>
                    <p className="formInputLabel">{t("sendFunds.form.paymentType")}</p>
                    <FormControl sx={{ width: "100%", mb: 2 }} variant="standard">
                      <NativeSelect
                        id="customized-select-native"
                        required={true}
                        onChange={(e) => { 
                          setPaymentType(e.target.value)
                          if(e.target.value === 'RECURRING') setIsRecurring(true)
                          else setIsRecurring(false)
                        }}
                        value={paymentType}
                        input={<DropDownInputWhite />}
                        IconComponent={DropDownArrowIconSolid}
                      >
                        <option value="">
                        {/* {t("sendFunds.form.selectRecipient")} */}
                          {t("sendFunds.form.selectPaymentType")}
                        </option>
                      {roles?.includes('ACCOUNTANT') ? (
                          ['RECURRING', 'SCHEDULING'].map((e: string, index: number) => {
                            return (
                              <option value={e} key={index}>
                                {e}
                              </option>
                            )
                          })
                        ): null
                      }

                      {roles?.includes('ADMIN') ? (
                          ['REGULAR', 'RECURRING', 'SCHEDULING'].map((e: string, index: number) => {
                            return (
                              <option value={e} key={index}>
                                {e}
                              </option>
                            )
                          })
                        ): null
                      }
                      </NativeSelect>
                    </FormControl>
                  </>
                ): null}
                
                {/* <p className="formInputLabel border-danger">{t("sendFunds.form.comments")}</p>
                <InputTypeText
                  placeholder={t("sendFunds.form.comments")}
                  onChange={onChangeDescription}
                  errorMsg={descriptionError}
                /> */}
                
                { isRecurring ? (
                    <div className="listBox">
                      {/* <Form.Check // prettier-ignore
                        type="checkbox"
                        id={`default`}
                        checked={isRecurring}
                        name="limit"
                        label={"Recurring Payment"}
                        onClick={(e: any) => setIsRecurring(e.target.checked)}
                      /> */}
                      {isRecurring && (
                        <>
                          <Form.Check // prettier-ignore
                            type="radio"
                            id={`default-radio-1`}
                            value="monthly"
                            label={"Monthly"}
                            onChange={radioChangeHandler}
                            checked={recurringType === "monthly"}
                          />
                          <Form.Check // prettier-ignore
                            type="radio"
                            id={`default-radio-2`}
                            value="weekly"
                            label={"Weekly"}
                            onChange={radioChangeHandler}
                            checked={recurringType === "weekly"}
                          />
                        </>
                      )}
                    </div> 
                  ): null
                }


                {paymentType === 'SCHEDULING' && (
                  <div className="d-flex flex-row align-items-center mt-2">
                    <span className="fw-bold d-block span-dates me-2">{"Date"}</span>

                    <Form.Control
                      className="date_style"
                      type="date"
                      value={recurringDate}
                      name={"date"}
                      // min={new Date().toISOString().split("T")[0]}
                      min={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                      required
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e: any) => {
                        schedulingTypeHandler(e.target.value);
                      }}
                    />
                  </div>
                )}

                {isRecurring && recurringType === "monthly" && (
                  <div className="d-flex flex-row align-items-center mt-2">
                    <span className="fw-bold d-block span-dates me-2">{"Date"}</span>

                    <Form.Control
                      className="date_style"
                      type="date"
                      value={recurringDate}
                      // min={new Date().toISOString().split("T")[0]}
                      min={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                      name={"date"}
                      required
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e: any) => {
                        recurringTypeHandler(e.target.value);
                      }}
                    />
                  </div>
                )}

                {isRecurring && recurringType === "weekly" && (
                  <div className="d-flex flex-row align-items-center mt-2">
                    <span className="fw-bold d-block span-dates me-2">{"Day"}</span>
                    <Form.Group style={{ width: "100%" }}>
                      <Form.Select
                        onChange={(e: any) =>
                          recurringTypeHandler(e.target.value)
                        }
                        className="btn btn-light"
                        aria-label="Change Currency"
                      >
                        <option value="" disabled selected hidden>
                          {"Select Day"}
                        </option>
                        {[
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                          "Sunday",
                        ].map((ele: any, index: number) => (
                          <option key={index} value={ele}>
                            {ele}
                          </option>
                        ))}
                      </Form.Select>
                      {/* <span className="errorMsg">{accountError}</span> */}
                    </Form.Group>
                  </div>
                )}

                {
                  paymentType === 'RECURRING' ? (
                    <>
                      <Box display="flex" alignItems="center" sx={{mt: 2, mb: 2}}>
                        {/* <Typography  color={"#ffd457"} fontSize="16px" sx={{mr: 2}} >
                          *Payment will be executed on the chosen date weekly or monthly
                        </Typography> */}
                        <span style={{
                          color: "#ffd457"
                        }} >Payment will be executed on the chosen date weekly or monthly</span>
                        {/* <Tooltip sx={{ ml: 3 }} title="Payment will be executed on the chosen date weekly or monthly" arrow>
                          <InfoIcon color="primary" sx={{fontSize: "20px"}} />
                        </Tooltip> */}
                      </Box>
                    </>
                  ): null
                }

{
                  paymentType === 'SCHEDULING' ? (
                    <>
                      <Box display="flex" alignItems="center" sx={{mt: 2, mb: 2}}>
                        {/* <Typography  color={"#ffd457"} fontSize="16px" sx={{mr: 2}} >
                          *Payment will be executed on the chosen date
                        </Typography> */}
                        <span style={{
                          color: "#ffd457"
                        }} >Payment will be executed on the chosen date</span>
                        {/* <Tooltip sx={{ ml: 3 }} title="Payment will be executed on the chosen date" arrow>
                          <InfoIcon color="primary" sx={{fontSize: "20px"}} />
                        </Tooltip> */}
                      </Box>
                    </>
                  ): null
                }

                {
                  paymentType === 'RECURRING' || paymentType === 'SCHEDULING' ? (
                    <>
                      <p className="formInputLabel">{t("sendFunds.form.paymentDescription")}</p>
                      <InputTypeText
                        placeholder={t("sendFunds.form.paymentDescription")}
                        value={paymentDescription}
                        // onBlur={() =>
                        //   paymentRef === ""
                        //     ? setPaymentRefError(errorMsgs.emptyPaymentRefError)
                        //     : setPaymentRefError("")
                        // }
                        onChange={(e) => {
                          setPaymentDescription(e.target.value);
                          setPaymentDescriptionError("");
                        }}
                        errorMsg={paymentDescriptionError}
                      />
                    </>
                  ) : null
                }

                <div className="mt-4">
                  <BasicButton 
                  type="submit"
                  text={roles?.includes('ACCOUNTANT') ? "Create" :t("sendFunds.form.send")}
                  isLoading={loading?true:false}
                  />
                </div>
                {/* <div className="btn-send">
                  <button
                    className="sendf-btn"
                    style={{ background: COLORS.pimary }}
                    type="submit"
                  >
                    {loading ? (
                      <Spinner animation={"border"} />
                    ) : (
                      `${t("sendFunds.form.send")}`
                    )}
                  </button>
                </div> */}
              </form>
            </div>
            {/* <Footer /> */}
          </div>
        </Col>
        <Col md={5} lg={4} xl={4} className="MonthlyRevenueTracker">
          <ExchangeRatesCard reload={reloadRatesCard}/>
        </Col>
        {/* <RightSideBar exchangeRates summaryBox /> */}
      </Row>

      {/* 1st 2FA modal on form submit or at send */}
      <Modal
        // style={{ marginTop: 100, maxHeight: "80%" }}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <ModalHeader onClose={() => setShow(false)}/>
        <Modal.Body className="sendFund-2FA-modal">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="mb-2">
            <img src={PaymentVerifictionIcon} width='100' height='100'/>
            </div>
            <div className="paymentVerifyText mb-3">
            <h2 className="ver-right-title m-0">{t("sendFunds.modal.title")}</h2>
            </div>
          </div>
          {/* <div className="verifyPayment-container">
            <span className="verication-payment-text">
              {t("sendFunds.modal.text")}
            </span>
          </div>
          <form className="verification-form" onSubmit={handleVerifyPayment}>
            <input
              className="enter-inp"
              placeholder="Enter code"
              onChange={(e) => setCode(e.target.value)}
            ></input>
            <div style={{ justifyContent: "center" }}>
              <button className="send-btn" type="submit">
                {loading2 ? (
                  <Spinner animation={"border"} />
                ) : (
                  `${t("sendFunds.form.send")}`
                )}
              </button>
            </div>
          </form> */}

        <Accordion >
           <Accordion.Item eventKey="0" className="p-0 accordionTab">
             <Accordion.Header>{t("verifyNew.2faDescription")}</Accordion.Header>
               <Accordion.Body>
                  <form
                        className="verification-form"
                        onSubmit={handleVerifyPaymentUpdated}
                      >
                        <InputTypeText
                          // className="enter-inp"
                          placeholder= {t("verifyNew.enterCode")}
                          onChange={(e) => {
                            setCode(e.target.value);
                            setTwofaErr("");
                          }}
                          errorMsg={twofaErr}
                        />
                        {/* <div style={{ justifyContent: "center" }}>
                          <button className="send-btn" type="submit">
                            {loading2 ? <Spinner animation={"border"} /> : "Send"}
                          </button>
                        </div> */}
                        <BasicButton
                          type="submit"
                          text={t("verify.send")}
                          isLoading={loading2 ? true: false}
                        />
                      </form>
                  </Accordion.Body>
             </Accordion.Item>
      
        </Accordion>
         <Accordion>
            <Accordion.Item eventKey="0" className="mt-4 p-0 accordionTab">
               <Accordion.Header>{t("verify.otp.description")}</Accordion.Header>
                 <Accordion.Body>
                 <div className="mr-xl-1">
                <p>{t("verifyNew.otpSmsDescription")}</p>
              </div>

              <div className="mr-xl-1">
              <IconButton 
                text={
                  loadingOTP ? <>
                  {" "}
                      {t("verifyNew.sending")} <Spinner animation={"border"} />
                  </>:
                  // (`${otpBtnText} ${userPhoneNo}`)
                  (
                    `${otpBtnText === 'Send' ? t("verifyNew.send") : t("verifyNew.resend")} ${userPhoneNo}`
                  )
                }
                isLoading={loadingOTP?true:false}
                disabled={loadingOTP ? true : false}
                onClick={handleSendOTPButtonClick}
                suffixIcon={<SmsIcon />}
              />

                {/* <Button
                  hidden={btnSMSVisible}
                  disabled={loadingOTP ? true : false}
                  className="send-otp-btn"
                  variant="contained"
                  onClick={handleSendOTPButtonClick}
                  endIcon={<SmsIcon />}
                >
                  {loadingOTP ? (
                    <>
                      {" "}
                      Sending.. <Spinner animation={"border"} />
                    </>
                  ) : (
                    // `${otpBtnText} SMS OTP to ${userPhone}`
                    `${otpBtnText} ${userPhoneNo}`
                  )}
                </Button> */}
                {/* <button
                hidden={btnSMSVisible}
                disabled={loadingOTP ? true : false}
                className="send-otp-btn"
                onClick={handleSendOTPButtonClick}
              >
                {loadingOTP ? (
                  <>
                    {" "}
                    Sending.. <Spinner animation={"border"} />
                  </>
                ) : (
                  // `${otpBtnText} SMS OTP to ${userPhone}`
                  `${otpBtnText}`
                )}
              </button> */}
              </div>

              {showOTPInput ? (
                <>
                  <div
                    style={{
                      alignContent: "center",
                      width: "100%",
                      padding: 10,
                    }}
                  >
                    <OtpInput
                      value={otp}
                      valueLength={6}
                      onChange={onChange}
                      onBlur={verifyOTPLengthOnBlur}
                    />
                    <div className="mr-xl-1">
                      <BasicButton 
                        text={
                          loadingOTPVerify? t("verifyNew.verifying") : t("verifyNew.verify")
                        }
                        className="mt-2"
                        disabled={loadingOTPVerify ? true : false}
                        onClick={handleVerifyOTPButtonClick}
                      isLoading={loadingOTPVerify?true:false}
                      />

                      {/* <Button
                        style={{ marginTop: 20 }}
                        disabled={loadingOTPVerify ? true : false}
                        variant="contained"
                        className="send-otp-btn"
                        onClick={handleVerifyOTPButtonClick}
                        endIcon={<EastIcon />}
                      >
                        {loadingOTPVerify ? (
                          <>
                            Verifying
                            <Spinner animation={"border"} />
                          </>
                        ) : (
                          "Verify"
                        )}
                      </Button> */}
                    </div>
                    {/* <button
              hidden={btnVoiceVisible}
              disabled={loadingOTPVoice ? true : false}
              className="send-otp-btn"
              onClick={handleSendOTPVoiceButtonClick}
            >
              {loadingOTPVoice ? (
                <>
                  {" "}
                  Sending.. <Spinner animation={"border"} />{" "}
                </>
              ) : (
                `${otpBtnText} Voice Call OTP to ${userPhone}`
              )}
            </button> */}
                  </div>
                  <div className="mr-xl-1">
                    <div
                      onClick={handleSendOTPVoiceButtonClick}
                      style={{ fontSize: 13 }}
                    >
                      {t("verifyNew.resendP1")}
                      {loadingOTPVoice ? (
                        <Spinner
                          style={{ width: 15, height: 15 }}
                          animation={"border"}
                        />
                      ) : (
                        <CallIcon
                          color="primary"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      {t("verifyNew.resendP2")}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
             </Accordion.Body>
           </Accordion.Item>

         </Accordion>

        </Modal.Body>
      </Modal>

      {/* Payment Receipt Modal */}
      <Modal
        className="sendF-modal"
        show={show1}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader title="Payment Receipt" onClose={() => setShow1(false)}/> 
        <Modal.Body>
            <ModalBannerSection icon={ModalTransferIcon} statusText={sendFundsSelec.data?.data?.status} title={t("sendFunds.receipt.text")}/>
            <FundTransferDetails 
              bank={ParsedData?.bank}
              recipient={sendFundsSelec.data?.data?.beneficiary_name}
              // description={sendFundsSelec.data?.data?.description}
              amount={sendFundsSelec.data?.data?.payment_amount}
              currency={sendFundsSelec.data?.data?.payment_currency}
              reason={sendFundsSelec.data?.data?.payment_reason}
              reference={sendFundsSelec.data?.data?.payment_reference}
              date={currentDate}
              status={sendFundsSelec.data?.data?.status}
              showRecurring={isRecurring}
              recurringType={sendFundsSelec.data?.data?.recurringType?.toUpperCase()}
            />

          {/* <Card className="card-send-dat">
            <div>
              <h5>Details</h5>
            </div>
            <hr />
            <div className="row receipt__data">
              <div className="col-6 fund-send-con">
                <h6>Bank:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{ParsedData?.bank}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Recipient:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{sendFundsSelec.data?.data?.beneficiary_name}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Description:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{sendFundsSelec.data?.data?.description}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Amount:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{sendFundsSelec.data?.data?.payment_amount}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Currency:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{sendFundsSelec.data?.data?.payment_currency}</p>
              </div>
              <div className="col-6 fund-send-con">
                {" "}
                <h6>Reason:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p> {sendFundsSelec.data?.data?.payment_reason}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Reference:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{sendFundsSelec.data?.data?.payment_reference}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Date:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{currentDate}</p>
              </div>
              <div className="col-6 fund-send-con">
                <h6>Payment Status:</h6>
              </div>
              <div className="col-6 fund-send-con">
                <p>{sendFundsSelec.data?.data?.status}</p>
              </div>
              {isRecurring && (
                <>
                  <div className="col-6 fund-send-con">
                    <h6>Recurrig Type:</h6>
                  </div>
                  <div className="col-6 fund-send-con">
                    <p>
                      {sendFundsSelec.data?.data?.recurringType?.toUpperCase()}
                    </p>
                  </div>
                </>
              )}
            </div>
          </Card> */}
        </Modal.Body>
      </Modal>

      {/* Add recipient modal */}
      <Modal
        show={recipientModal}
        onHide={handleCloseAddRecipient}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <ModalHeader title="Add Recipient" onClose={() => setRecipientModal(false)} modalBodyRef={recipientModalRef}/> 
        <Modal.Body ref={recipientModalRef}>
          <AddbeneficiaryForm
            handleCloseAddRecipient={handleCloseAddRecipient}
            isRecipientModal={recipientModal}
            getBeneficiaries={getBeneficiaries}
            handleRecipientSelect={handleRecipientSelect}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SendFunds;
