import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  ComplateRegistrationOp,
  VerifyToken,
} from "../../../redux/toolkit/auth/operation";
import { errorMsgs, regexStrConst, LOGO_URL } from "../../../constants/stringConstants";
import Swal from "sweetalert2";
import "./index.css";
import { OMWLogoWhite } from "../../../constants/Images";
import InputTypeText from "../../../components/shared/Input/Basic/InputTypeText";
import InputTypeSecure from "../../../components/shared/Input/InputTypeSecure";
import BasicButton from "../../../components/shared/Buttons/Basic";
import LanguageDropdown from "../../../components/shared/TranslationDropdown";
import PhoneInput from "react-phone-input-2";
import {jwtDecode} from 'jwt-decode'
import { PathStrConst } from "../../../constants";

type CustomJwtPayload = {
  firstName: string;
  lastName: string;
  email: string;
  companyId:string;
  userId:string;
  exp:number;
  iat:number;

}



const ComplateRegistration = () => {
 
  
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [ConfirmpassError, setConfirmPassError] = useState("");
  const [showNewPass, setShowNewPass] = useState(true)
  const [showConfirmNewPass, setShowConfirmNewPass] = useState(true)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");



  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");



  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  const token = location.pathname.split("/")[2];


  // const resetPassDetailss: any = useSelector((state: RootState) => state.resetPassDetails);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  

  const validate = () => {
    if (firstName === "") setFirstName(errorMsgs.emptyName);
    if (lastName === "") setLastNameError(errorMsgs.emptyName);
    if (phoneNumber === "") setPhoneNumberError(errorMsgs.emptyPhone);
    if (confirmPass === "") setConfirmPassError(errorMsgs.emptyPassword);
    if (password === "") setPassError(errorMsgs.emptyPassword);
    if (confirmPass !== password) setConfirmPassError(errorMsgs.confirmPassword)

    return confirmPass === "" ||
      password === "" ||
      ConfirmpassError ||
      firstName === "" ||
      lastName === "" ||
      phoneNumber === "" ||
      passError
      ? false
      : true;
  };

  // useEffect(() => {
  //   const payload = {
  //     token: token,
  //   };
  //   dispatch(VerifyToken(payload));
  // }, [token]);

  // useEffect(() => {
  //   if (resetPassDetailss && Object.keys(resetPassDetailss.data).length !== 0) {
  //     navigate("/");
  //   }
  // }, [resetPassDetailss]);
  useEffect(() => {
    if(token){
      const tokenPayload = jwtDecode(token) as CustomJwtPayload;
      setFirstName(tokenPayload.firstName);
      setLastName(tokenPayload.lastName)
      setEmail(tokenPayload.email)  
    }
  }, []);

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.passwordRegex.test(item)) {
      setPassError("");
    } else {
      setPassError(errorMsgs.emptyPassword);
    }
    setPassword(item);
  };

  // const onChangeConfirmPassword = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const item = event.target.value;
  //   if (regexStrConst.passwordRegex.test(item)) {
  //     setConfirmPassError("");
  //   } else {
  //     setConfirmPassError(errorMsgs.confirmPassword);
  //   }
  //   setConfirmPass(item);
  // };
  const onChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (!regexStrConst.passwordRegex.test(item)) {
      setConfirmPassError(errorMsgs.confirmPassword);
    }
    else if (item !== password) {
      setConfirmPassError(errorMsgs.confirmPassword);
    }
    else {
      setConfirmPassError("");
    }
    setConfirmPass(item);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (validate()) {
      if (password === confirmPass) {
        setLoading(true);
        const payload = {
          password: password,
          firstName,
          lastName,
          phoneNo:phoneNumber,
          token: token,
        };
        dispatch(ComplateRegistrationOp(payload)).then((da) => {
          console.log("=>",da)
          setLoading(false);
          navigate(PathStrConst.signin)
        });
      } 
      // else {
      //   // Swal.fire({
      //   //   icon: "error",
      //   //   title: "Oopss",
      //   //   text: "Password should match",
      //   // });
      // }
    }
  };

  const validatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == '') {
      setPassError(errorMsgs.emptyPassword)
    }
    else if (!regexStrConst.passwordRegex.test(e.target.value)) {
      setPassError(errorMsgs.incorrectPassword)
    }
    else {
      setPassError('')
    }
  }

  // const validateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.value == '') {
  //     setConfirmPassError(errorMsgs.confirmPassword)
  //   }
  //   else if (!regexStrConst.passwordRegex.test(e.target.value)) {
  //     setConfirmPassError(errorMsgs.confirmPassword)
  //   }
  //   else {
  //     setConfirmPassError('')
  //   }
  // }
  const validateConfirmPassword = (e: string) => {
    if (!e || !regexStrConst.passwordRegex.test(e)) {
      setConfirmPassError(errorMsgs.confirmPassword)
    }
    else if (password && e !== password) {
      setConfirmPassError(errorMsgs.confirmPassword)
    }
    else {
      setConfirmPassError('')
    }
  }
  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setFirstNameError(errorMsgs.emptyName);
    } else {
      setFirstNameError("");
    }
    setFirstName(item);
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setLastNameError(errorMsgs.emptyName);
    } else {
      setLastNameError("");
    }
    setLastName(item);
  };

  const onChangePhoneNo = (value: any, country: any) => {
    // const item = event.target.value;
    if (regexStrConst.phoneNumberRegex.test(value)) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError(errorMsgs.emptyPhone);
    }
    // setCountryCode(country?.countryCode)
    setPhoneNumber(value);
  };

  return (
    <div className="NewPassword p-2">
      <div className="onBoardingImage onBoardingBGImg">

        <img className="omwLogo" src={OMWLogoWhite} />
      </div>
      <div className="onBoardingForm">
        <div className="top-banner">
          <img src={OMWLogoWhite} />
        </div>
        <div className="lng-dropdown">
          <LanguageDropdown />
        </div>
        <h2 className="onBoarding-title mt-2">
          {/* {t("resetPassword.title.heading")} */}
          {/* Complete Registration */}
          {t("register")}
        </h2>
        <p className="onBoarding-text">
          {/* {t("resetPassword.title.description")}: */}
          {/* Enter an unique and strong password */}
          {t("registration.title.description")}
        </p>
        <form className="new-form">
          <div className="userNameDiv">
            <div className="userName-firstName">
              <p className="inputLabel mt-2">{t("registration.firstName")}</p>
              <InputTypeText
                type="text"
                className="inputText"
                // placeholder={t("registration.firstName")}
                value={firstName}
                maxLength={20}
                onBlur={() =>
                  firstName === ""
                    ? setFirstNameError(errorMsgs.emptyName)
                    : setFirstNameError("")
                }
                onChange={onChangeFirstName}
                // errorMsg={firstNameError}
                errorMsg={firstNameError && `${t("errorMsgs.emptyFirstName")}`}
              />
            </div>
            <div className="userName-lastName">
              <p className="inputLabel mt-2">{t("registration.lastName")}</p>
              <InputTypeText
                type="text"
                className="inputText"
                // placeholder={t("registration.lastName")}
                value={lastName}
                maxLength={20}
                onBlur={() =>
                  lastName === ""
                    ? setLastNameError(errorMsgs.emptyName)
                    : setLastNameError("")
                }
                onChange={onChangeLastName}
                // errorMsg={lastNameError}
                errorMsg={lastNameError && `${t("errorMsgs.emptyLastName")}`}
              />
            </div>
          </div>

          <p className="inputLabel mt-2">{t("registration.email")}</p>
          <InputTypeText
            disabled={true}
            type="email"
            className="inputText"
            // placeholder={t("registration.emailExample")}
            value={email}
          />

          <p className="inputLabel mt-2">{t("registration.phoneNo")}</p>
          <PhoneInput
            inputClass="countryPhoneInput"
            country={"us"}
            value={phoneNumber}
            onChange={(value, country) => onChangePhoneNo(value, country)}
            onBlur={() => phoneNumber === "" ? setPhoneNumberError(errorMsgs.emptyPhone) : setPhoneNumberError("")}
          />
          <span className="errorMsg">
            {/* {phoneNumberError ? phoneNumberError : ""} */}
            {phoneNumberError && t("errorMsgs.emptyPhone") }
          </span>


          <p className="inputLabel mt-4">{t("resetPassword.newPass")}</p>
          <InputTypeSecure
            type={`${showNewPass ? "password" : "text"}`}
            className="inputTextBox inputPass"
            // placeholder={t("enterPass")}
            value={password}
            onBlur={(e: any) => validatePassword(e)}
            onChange={onChangePassword}
            errorMsg={passError && t("errorMsgs.emptyPassword")}
          />
          <span className="passwordReqText mt-2">{t("errorMsgs.emptyPasswordReg")}</span>

          {/* <p className="inputLabel mt-4">
            Confirm New Password
          </p> */}
          <p className="inputLabel mt-2">{t("registration.confirmPass")}</p>


          <InputTypeSecure
            type={`${showConfirmNewPass ? "password" : "text"}`}
            className="inputTextBox inputPass"
            value={confirmPass}
            onBlur={(e: any) => validateConfirmPassword(e.target.value)}
            onChange={onChangeConfirmPassword}
            errorMsg={ConfirmpassError && t("errorMsgs.confirmPassword")}
          />

          <BasicButton
            text={t("registration.createAcc")}
            style={{ marginTop: '20px' }}
            isLoading={loading}
            onClick={handleButtonClick}
            disabled={loading}
          />

          <div className="backToLogin">
            <span onClick={() => navigate("/")}>
              {t("forgotPassword.backToLogin")}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ComplateRegistration;
