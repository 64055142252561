import React,{useState,useEffect} from "react";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import {
  DropDownArrowIconSolid,
  DropDownInputWhite,
} from "../../components/shared/DropdownSelect";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useTranslation } from "react-i18next";
import BeneficiaryTypes from "./interface";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import Form from "react-bootstrap/Form";
import BasicButton from "../../components/shared/Buttons/Basic";
import { Card, Col, Modal, Row, Spinner,Container } from "react-bootstrap";
import ModalHeader from "../../components/shared/Modal/ModalHeader";
import ModalBannerSection from "../../components/shared/Modal/ModalBannerSection";
import { AddbeneficiaryForm } from "../Addbeneficiary/AddBenificiaryForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { GetALlbeneficiaries } from "../../redux/toolkit/beneficiaries/operation";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import {
  SendFundsData,
  SendFundsRecurring,
} from "../../redux/toolkit/sendFunds/operations";
import {
  OTPVerificationPayment,
  QrVerificationPayment,
} from "../../redux/toolkit/verifyPayment/operation";
import {
  OTPVerification,
  SendOTP,
  SendOTPVoice,
} from "../../redux/toolkit/auth/operation";
import {
  ModalTransferIcon,
  PaymentVerifictionIcon,
} from "../../constants/Images";
import FundTransferDetails from "./Fund Transfer Details";
import Accordion from "react-bootstrap/Accordion";
import IconButton from "../../components/shared/Buttons/IconButton";
import SmsIcon from "@mui/icons-material/Sms";
import OtpInput from "../../components/shared/OTP";
import CallIcon from "@mui/icons-material/Call";
import { getCardAccountBalance } from "../../redux/toolkit/accountBalances/operation";

const FundsTransferForm = ({beneficiaryData,setShowSendMoney,setShow2FA}:any) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const accountId: any = localStorage.getItem("accountId");
  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);
  const userId: any = localStorage.getItem("userId");
  const userPhoneNo: any = localStorage.getItem("userPhone");
  const userEmail: any = localStorage.getItem("userEmail");
  const roles: any = localStorage.getItem("roles");
  const accId: any = localStorage.getItem("activeAccId");

  const accountIdSelecto: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const beneficiariesData: any = useSelector(
    (state: RootState) => state.beneficiary.data
  );
  const sendFundsSelec: any = useSelector(
    (state: RootState) => state.sendFundsDetails.data
  );
  const BalanceSelector: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.data
  );
  const balanceStatus: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.status
  );
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const currentDate = `${day}-${month}-${year}`;

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [recipientError, setRecipientError] = useState("");
  const [paymentRefError, setPaymentRefError] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentRef, setPaymentRef] = useState("");
  const [uuid, setuuid] = useState(beneficiaryData.guid);
  const [accUUID, setAccUUID] = useState(accountId);
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const [recipientSelectVal, setRecipientSelectVal] = useState(beneficiaryData.guid);
  const [recipientModal, setRecipientModal] = useState(false);
  const [beneficiariesId, setBeneficiariesId] = useState([]);
  const [currency, setCurrency] = useState(ParsedData?.currency);
  const [purpose, setPurpose] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringType, setRecurringType] = useState("");
  const [recurringDate, setRecurringDate] = useState("");
  const [recurringValue, setRecurringValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [code, setCode] = useState("");
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [otpBtnText, setOtpBtnText] = useState("Send");
  const [btnVoiceVisible, setBtnVoiceVisible] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [loadingOTPVerify, setLoadingOTPVerify] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [btnSMSVisible, setBtnSMSVisible] = useState(false);
  const [loadingOTPVoice, setLoadingOTPVoice] = useState(false);
  const [balanceError, setBalanceError] = useState(false);
  const [balance, setBalance] = useState<number>(0);

  const onChange = (value: string) => setOtp(value);
  const verifyOTPLengthOnBlur = (e: any) => {
    if (e.length < 6) {
      setOtpErrorMsg("Enter all otp digits");
    } else {
      setOtpErrorMsg("");
    }
    
  };
  const handleVerifyOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setLoadingOTPVerify(true);
    e.preventDefault();

    const payloadData = {
      phoneNo: userPhoneNo,
      otp: otp,
      accB4BUUID: ParsedData.accB4BUUID,
    };
    dispatch(OTPVerificationPayment(payloadData)).then((val: any) => {
      setLoadingOTPVerify(false);
      if (val.payload.message) {
        setLoadingOTPVerify(true);
        const payloadData = {
          beneficiary_uuid: uuid,
          payment_reference: paymentRef,
          payment_amount: amount,
          payment_currency: currency,
          payment_reason: purpose,
          uuid: ParsedData.accB4BUUID,
          userID: accId,
        };

        dispatch(SendFundsData(payloadData)).then((val) => {
          setLoadingOTPVerify(false);
          setShow(false);
          setShow1(true);
        })
        .catch((err) => {
              setShowSendMoney(false);
              setShow2FA(false);
              setLoadingOTPVerify(true);
        });
      }
      setBtnSMSVisible(false);
      setBtnVoiceVisible(false);
    });
  };
  const handleSendOTPVoiceButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setBtnSMSVisible(true);
    setLoadingOTPVoice(true);
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTPVoice(payload)).then(() => {
      setBtnSMSVisible(false);
      setLoadingOTPVoice(false);
      setShowOTPInput(true);
      setOtpBtnText("Resend");
    });
  };
  const validate = () => {
    if (amount === "" && !balanceError) setAmountError(errorMsgs.emptyAmount);
    if (paymentRef === "") setPaymentRefError(errorMsgs.emptyPaymentRefError);
    if (uuid === "add") setRecipientError(errorMsgs.selectRecipient);
    return uuid === "" ||
      uuid === "add" ||
      amount === "" ||
      paymentRef === "" ||
      amountError ||
      paymentRefError ||
      recipientError
      ? false
      : true;
  };
  const handleRecipientSelect = (guid: any) => {
    if (guid === "add") {
      setuuid("");
      setRecipientSelectVal("");
      setRecipientModal(true);
    } else {
      setuuid(guid);
      setRecipientSelectVal(guid);
      setRecipientError("");
    }
  };
  const onChangePaymentRef = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    const sanitizedValue = item.replace(/[^A-Za-z0-9\s]/g, "");
    event.target.value = sanitizedValue;
    setPaymentRefError("");
    setPaymentRef(sanitizedValue);
  };
  const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    const itemNumber = parseFloat(item);
    if (regexStrConst.amountRegex.test(item)) {
      setAmountError("");
    if(itemNumber > balance) {
        setBalanceError(true);
        setAmount("");
      } else {
        setBalanceError(false);
        setAmount(item);
      }
    } else {
      setAmountError(errorMsgs.emptyAmount);
    }
  };
  
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (validate()) {
      setShow(true);
      setShow2FA(true);
    }
  };
  const recurringTypeHandler = (value: any) => {
    if (recurringType === "monthly") {
      const date: any = new Date(value);
      setRecurringValue(date); 
      setRecurringDate(value);
    }
    if (recurringType === "weekly") {
      setRecurringValue(value.trim().toLowerCase());
      setRecurringDate("");
    }
  };
  const handleClose = () => {
      setShow(false);
      setOtp("");
      setShow2FA(false);
  };

  const handleReceiptClose = () => {
    setShow1(false);
    setShow2FA(false);
    setShowSendMoney(false);

  }
  const handleCloseAddRecipient = () => {
    setRecipientModal(false);
  };

  const getBeneficiaries = () => {
    dispatch(GetALlbeneficiaries(accUUID));
  };

  const handleVerifyPayment = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading2(true);

    const payloadData = {
      phoneNo: userPhoneNo,
      email: userEmail,
      token: code,
      accB4BUUID: ParsedData.accB4BUUID,
    };
    if (isRecurring || roles?.includes("ACCOUNTANT")) {
      const payloadData: any = {
        beneficiary_uuid: uuid,
        payment_reference: paymentRef,
        payment_amount: amount,
        payment_currency: currency,
        payment_reason: purpose,
        uuid: ParsedData.accB4BUUID,
        userID: accId,
      };

      if (isRecurring) {
        payloadData["recurringType"] = recurringType;
        payloadData["recurringDate"] = recurringValue;
        payloadData["isRecurring"] = true;
        payloadData["isScheduling"] = false;
      } else if (roles?.includes("ACCOUNTANT")) {
        payloadData["isRecurring"] = false;
        payloadData["isScheduling"] = true;
        payloadData["accountantID"] = userId;
      }

      dispatch(SendFundsRecurring(payloadData)).then((res: any) => {
        setLoading2(false);
        setShow(false);
        setShow1(true);
        setIsRecurring(false);
        setRecurringType("");
        setRecurringDate("");
        setRecurringValue("");
        setAmount("");
      });
    } else {
      dispatch(QrVerificationPayment(payloadData)).then((val: any) => {
        setLoading2(false);
        if (val.payload.message) {
          setLoading2(true);
          const payloadData = {
            beneficiary_uuid: uuid,
            payment_reference: paymentRef,
            payment_amount: amount,
            payment_currency: currency,
            payment_reason: purpose,
            uuid: ParsedData.accB4BUUID,
            userID: accId,
          };
          dispatch(SendFundsData(payloadData)).then((val: any) => {
            if (val?.error?.name === "Error") {
              setShow1(false);
              setShowSendMoney(false);
              setShow2FA(false);
            } else {
              setShow1(true);
            }
            setLoading2(false);
            setShow(false);
          });
        }
      });
    }

    setLoading2(false);
  };

  const handleSendOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setBtnVoiceVisible(true);
    setLoadingOTP(true);
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTP(payload)).then(() => {
      setBtnVoiceVisible(false);
      setLoadingOTP(false);
      setShowOTPInput(true);
      setOtpBtnText("Resend");
    });
  };
  useEffect(() => {
    if (balanceStatus === 'succeeded' && BalanceSelector) {
      setBalance(BalanceSelector?.data.data?.float);
    } else if (balanceStatus === 'failed') {
      setBalance(0); 
    }
  }, [balanceStatus, BalanceSelector]);
  useEffect(() => {
    dispatch(getCardAccountBalance(accUUID));
  }, [dispatch, accUUID]);

  useEffect(() => {
    getBeneficiaries();
  }, [dispatch, accUUID]);

  useEffect(() => {
    dispatch(getAllAccounts(userId));
  }, [dispatch]);
  
  useEffect(() => {
    if (accountIdSelecto) {
      setAccountDetails(accountIdSelecto?.data?.data);
    }
  }, [accountIdSelecto, accountDetails]);

  useEffect(() => {
    if (beneficiariesData) {
      setBeneficiariesId(beneficiariesData.data?.data);
    }
  }, [beneficiariesData, beneficiariesId]);
  return (
    <>
    <Container fluid>
      <Row>
        <Col
          className="mainFormBox"
        >
            <div className="">
              {/* <h1 className="mainHeading">{t("sendFunds.title")}</h1> */}
              <form className="" onSubmit={handleSubmit}>
                <p className="formInputLabel">{t("sendFunds.form.account")}</p>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    //   value={age}
                    onChange={(e: any) => {
                      // localStorage.setItem("AccountData", e.target.value);
                      const accountData = JSON.parse(e.target.value);
                      setAccUUID(accountData.accB4BUUID);
                      setCurrency(accountData.currency);
                      setAmount("");
                    }}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value={JSON.stringify(ParsedData)}>
                      {t("sendFunds.form.selectedAcc")}:{" "}
                      {ParsedData?.accountName}
                    </option>
                    {accountDetails &&
                      accountDetails.map((ele: any, index: number) =>
                        !ele.inactive ? (
                          <option key={index} value={JSON.stringify(ele)}>
                            {ele.accountName}
                          </option>
                        ) : null
                      )}
                  </NativeSelect>
                </FormControl>

                <p className="formInputLabel">
                  {t("sendFunds.form.recipient")}
                </p>

                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    onChange={(e) => handleRecipientSelect(e.target.value)}
                    value={recipientSelectVal}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value="">
                      {t("sendFunds.form.selectRecipient")}
                    </option>
                    {beneficiariesId && beneficiariesId.length > 0 ? (
                      beneficiariesId.map(
                        (ele: BeneficiaryTypes, index: number) => (
                          <option value={ele.guid} key={index}>
                            {ele.name + " " + ele.account}
                          </option>
                        )
                      )
                    ) : (
                      <option>{t("sendFunds.form.noRecipient")}</option>
                    )}
                    <hr />
                    <option
                      value="add"
                      className="fw-bold"
                      style={{
                        padding: "20px !important",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {t("addRecipient.title")}
                    </option>
                  </NativeSelect>
                </FormControl>
                <span className="errorMsg">{recipientError && t("errorMsgs.selectRecipient")}</span>

                <p className="formInputLabel">
                  {t("sendFunds.form.paymentRef")}
                </p>
                <InputTypeText
                  placeholder={t("sendFunds.form.paymentRefP")}
                  value={paymentRef}
                  onBlur={() =>
                    paymentRef === ""
                      ? setPaymentRefError(errorMsgs.emptyPaymentRefError)
                      : setPaymentRefError("")
                  }
                  onChange={onChangePaymentRef}
                  errorMsg={paymentRefError && `${t("errorMsgs.emptyPaymentRefError")}`}
                />
                <p className="formInputLabel">
                  {t("sendFunds.form.paymentCurr")}
                </p>

                <InputTypeText
                  placeholder={currency}
                  disabled
                  type="text"
                />

                <p className="formInputLabel">{t("sendFunds.form.purpose")}</p>

                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    onChange={(e) => setPurpose(e.target.value)}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value="">{t("sendFunds.reason.title")}</option>
                    <option>{t("sendFunds.reason.health")}</option>
                    <option>{t("sendFunds.reason.education")}</option>
                    <option>{t("sendFunds.reason.flatRent")}</option>
                    <option>{t("sendFunds.reason.locationCosts")}</option>
                    <option>{t("sendFunds.reason.utilities")}</option>
                    <option>
                      {t("sendFunds.reason.telephoneAndInternet")}
                    </option>
                    <option>{t("sendFunds.reason.businessInsurance")}</option>
                    <option>{t("sendFunds.reason.officeEquipment")}</option>
                    <option>{t("sendFunds.reason.employee")}</option>
                    <option>{t("sendFunds.reason.marketing")}</option>
                    <option>{t("sendFunds.reason.tax")}</option>
                    <option>{t("sendFunds.reason.business")}</option>
                    <option>{t("sendFunds.reason.businessMeals")}</option>
                    <option>{t("sendFunds.reason.businessTravel")}</option>
                    <option>{t("sendFunds.reason.legal")}</option>
                    <option>{t("sendFunds.reason.home")}</option>
                    <option>{t("sendFunds.reason.loan")}</option>
                    <option>{t("sendFunds.reason.maintenance")}</option>
                    <option>{t("sendFunds.reason.other")}</option>
                  </NativeSelect>
                </FormControl>

                <p className="formInputLabel">{t("sendFunds.form.amount")}</p>
                <InputTypeText
                  placeholder="1"
                  type="number"
                  step="any"
                  value={amount}
                  onChange={onChangeAmount}
                  errorMsg={amountError && `${t("errorMsgs.emptyAmount")}`}
                />
                {balanceError && 
                <p style={{color: "red", marginTop: "-10px"}}>{t("errorMsgs.amountGreaterThanBalance")}</p>
                }

                {isRecurring && recurringType === "monthly" && (
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold d-block span-dates">{"Date"}</span>

                    <Form.Control
                      className="date_style"
                      type="date"
                      value={recurringDate}
                      name={"date"}
                      required
                      onChange={(e: any) => {
                        recurringTypeHandler(e.target.value);
                      }}
                    />
                  </div>
                )}

                {isRecurring && recurringType === "weekly" && (
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold d-block span-dates">{"Day"}</span>
                    <Form.Group style={{ width: "100%" }}>
                      <Form.Select
                        onChange={(e: any) =>
                          recurringTypeHandler(e.target.value)
                        }
                        className="btn btn-light"
                        aria-label="Change Currency"
                      >
                        <option value="" disabled selected hidden>
                          {"Select Day"}
                        </option>
                        {[
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                          "Sunday",
                        ].map((ele: any, index: number) => (
                          <option key={index} value={ele}>
                            {ele}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                )}
                <div className="mt-4">
                  <BasicButton
                    type="submit"
                    text={t("sendFunds.form.send")}
                    isLoading={loading ? true : false}
                  />
                </div>
              </form>
            </div>
          
        </Col>
      </Row>
      </Container>

      {/* Add recipient modal */}
      <Modal
        show={recipientModal}
        onHide={handleCloseAddRecipient}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <ModalHeader
          title="Add Recipient"
          onClose={() => setRecipientModal(false)}
        />
        <Modal.Body>
          <AddbeneficiaryForm
            handleCloseAddRecipient={handleCloseAddRecipient}
            isRecipientModal={recipientModal}
            getBeneficiaries={getBeneficiaries}
            handleRecipientSelect={handleRecipientSelect}
          />
        </Modal.Body>
      </Modal>
      {/* Payment Receipt Modal */}
      <Modal
        className="sendF-modal"
        show={show1}
        onHide={handleReceiptClose}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader title="Payment Receipt" onClose={handleReceiptClose} />
        <Modal.Body>
          <ModalBannerSection
            icon={ModalTransferIcon}
            statusText={sendFundsSelec.data?.data?.status}
            title={t("sendFunds.receipt.text")}
          />
          <FundTransferDetails
            bank={ParsedData?.bank}
            recipient={sendFundsSelec.data?.data?.beneficiary_name}
            // description={sendFundsSelec.data?.data?.description}
            amount={sendFundsSelec.data?.data?.payment_amount}
            currency={sendFundsSelec.data?.data?.payment_currency}
            reason={sendFundsSelec.data?.data?.payment_reason}
            reference={sendFundsSelec.data?.data?.payment_reference}
            date={currentDate}
            status={sendFundsSelec.data?.data?.status}
            showRecurring={isRecurring}
            recurringType={sendFundsSelec.data?.data?.recurringType?.toUpperCase()}
          />
        </Modal.Body>
      </Modal>
      {/* 1st 2FA modal on form submit or at send */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <ModalHeader onClose={handleClose} />
        <Modal.Body className="">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="mb-2">
              <img src={PaymentVerifictionIcon} width="100" height="100" />
            </div>
            <div className="paymentVerifyText mb-3">
              <h2 className="ver-right-title m-0">
                {t("sendFunds.modal.title")}
              </h2>
            </div>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0" className="p-0 accordionTab">
              <Accordion.Header>
                Verify 2FA from Authenticator App
              </Accordion.Header>
              <Accordion.Body>
                <form
                  className="verification-form"
                  onSubmit={handleVerifyPayment}
                >
                  <InputTypeText
                    placeholder="Enter code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <BasicButton
                    type="submit"
                    text="Send"
                    isLoading={loading2 ? true : false}
                  />
                </form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey="0" className="mt-4 p-0 accordionTab">
              <Accordion.Header>{t("verify.otp.description")}</Accordion.Header>
              <Accordion.Body>
                <div className="mr-xl-1">
                  <p>We will send OTP to your registered number</p>
                </div>

                <div className="mr-xl-1">
                  <IconButton
                    text={
                      loadingOTP ? (
                        <>
                          Sending.. <Spinner animation={"border"} />
                        </>
                      ) : (
                        `${otpBtnText} ${userPhoneNo}`
                      )
                    }
                    isLoading={loadingOTP ? true : false}
                    disabled={loadingOTP ? true : false}
                    onClick={handleSendOTPButtonClick}
                    suffixIcon={<SmsIcon />}
                  />
                </div>

                {showOTPInput ? (
                  <>
                    <div
                      style={{
                        alignContent: "center",
                        width: "100%",
                        padding: 10,
                      }}
                    >
                      <OtpInput
                        value={otp}
                        valueLength={6}
                        onChange={onChange}
                        onBlur={verifyOTPLengthOnBlur}
                      />
                      <div className="mr-xl-1">
                        <BasicButton
                          text={loadingOTPVerify ? "Verifying" : "Verify"}
                          className="mt-2"
                          disabled={loadingOTPVerify ? true : false}
                          onClick={handleVerifyOTPButtonClick}
                          isLoading={loadingOTPVerify ? true : false}
                        />
                      </div>
                    </div>
                    <div className="mr-xl-1">
                      <div
                        onClick={handleSendOTPVoiceButtonClick}
                        style={{ fontSize: 13 }}
                      >
                        {" "}
                        Having trouble Receiving SMS,{" "}
                        {loadingOTPVoice ? (
                          <Spinner
                            style={{ width: 15, height: 15 }}
                            animation={"border"}
                          />
                        ) : (
                          <CallIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                          />
                        )}{" "}
                        call me instead.
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FundsTransferForm;
