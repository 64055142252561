import React, { useState, useEffect, useCallback } from "react";
import LanguageDropdown from "../TranslationDropdown";
import { useLocation } from "react-router-dom";
import LeftSideBar from "../LeftSidebar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsChevronUp,BsChevronDown } from "react-icons/bs";
import _ from 'lodash';


import Avatar from "@mui/material/Avatar";
// import { BsFillPencilFill } from "react-icons/bs";
import { LiaUserEditSolid } from "react-icons/lia";
import { BiAlignJustify } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { apiHelper } from "../../../api helper";
import { Grid } from '@mui/material'

import "./index.css";
import { Divider, ListItemIcon, ListItemText } from "@mui/material";
import { Logout, GetLastLogin } from "../../../redux/toolkit/auth/operation";
import { disconnectSocket } from "../../../socket";
import { NotificationBell ,HamburgerMenu, SuccessImage, ErrorImage} from "../../../constants/Images";
import MerchantDropdown from "../MerchantDropdown";
import { PathStrConst } from "../../../constants";
import { makeStyles } from "@material-ui/core";
import InputTypeText from "../Input/Basic/InputTypeText";
import { IoIosSearch } from "react-icons/io";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ModalHeader from "../Modal/ModalHeader";
import {Modal} from 'react-bootstrap'
import BasicButton from "../Buttons/Basic";
import { showAlert } from "../../../redux/toolkit/swal/reducer";



const useStyles = makeStyles(() => ({
  emailSelect: {
    // background:'red',
    borderRadius: "10px !important",
    borderColor: '#D7F0FF !important',
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline' : {borderColor: "#D7F0FF !important",borderWidth:"2px"},
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#D7F0FF !important" },
    "&:hover .MuiOutlinedInput-notchedOutline":       { borderColor: "#D7F0FF !important" },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: "#D7F0FF !important" },
    "& .css-6hp17o-MuiList-root-MuiMenu-list":{boxShadow:'none !important'}
  },
  menuItemRoot:{
    background:'#ffffff !important',
    fontFamily:'Montserrat !important',
    fontSize:'16px'


    // '&.Mui-selected': {
    //     backgroundColor: '#e44444',
    //   }
  }

}));

const Header = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [lastLogin, setLastLogin] = useState("");
  const [lastSync, setLastSync] = useState("");
  const [sessionEmail, setSessionEmail] = useState("");

  const [width, setWidth] = useState(window.innerWidth);
  const [admins, setAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddUser, setShowAddUser] = useState(false);
  const [inviteFormValues, setInviteFormValues] = useState({
    firstName:'',
    lastName:'',
    email:'',
  })
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [invalidEmailError , setInvalidEmailError] = useState("");


  const token: any = localStorage.getItem("token");
  const userId: any = localStorage.getItem("userId");
  const roles: any = JSON.parse(localStorage.getItem('roles') || "[]");

  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch<AppDispatch>();


  const sidebarActiveTab = pathname.replace("/", "");

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const firstName: any = localStorage.getItem("firstName");
  const lastName: any = localStorage.getItem("lastName");
  const email: any = localStorage.getItem('userEmail');

  useEffect(() => {
    const item = localStorage.getItem('session_email');
    if(item)
      setSessionEmail(item)
    else
      setSessionEmail(email)
  }, [])

  const userLogout: any = useSelector((state: RootState) => {
    if (state.qrDetails && Object.keys(state.qrDetails.data).length !== 0) {
      return state.qrDetails;
    } else if (
      state.otpVerifyDetails &&
      Object.keys(state.otpVerifyDetails.data).length !== 0
    ) {
      return state.otpVerifyDetails;
    }
  });

  const getAllAdmin = async (search = "") => {
    try {
      const res = await apiHelper(
        `user/get-admins/${userId}?search=${search}`,
        "get",
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (res?.data?.data?.users)
        setAdmins(res?.data?.data?.users)
    } catch (error) {
      console.log('Error Occured ', error)
    }
  }

  const debouncedFetchResults = useCallback(_.debounce(getAllAdmin, 500), []);
  useEffect(() => {
    debouncedFetchResults(searchTerm);
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [searchTerm, debouncedFetchResults]);

  const loginAdminSession = async (emailLocal: string) => {
    try {
      const res = await apiHelper(
        `user/login-session-user`,
        "post",
        {
          email: emailLocal
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (res) {
        setSessionEmail(emailLocal)
        localStorage.setItem('session_email', emailLocal);
        if(pathname.includes("home"))
          window.location.reload()
        else navigate('/home')
      }
    } catch (error) {
      console.log('Error Occured ', error)
    }
  }

  const logoutAdminSession = async () => {
    try {
      const res = await apiHelper(
        `user/logout-session/${token}`, "get"
      );
      console.log('RES ', res)
      if (res)
        setSessionEmail(email)
        localStorage.removeItem('session_email')
        navigate('/home');
        window.location.reload()
    } catch (error) {
      console.log('Error Occured ', error)
    }
  }

  useEffect(() => {
    getAllAdmin()
  }, [])

  const setHeaderActivePage =(selecterPagePath:string)=>{
    let path = '';
    switch (selecterPagePath) {
      case PathStrConst.bankAccount:
       path = t("leftSidebar.home");
        break;
      case PathStrConst.bankAccountCards:
        path = t("leftSidebar.cards");
        break;
    case PathStrConst.convert:
        path =  t("leftSidebar.convert");
        break;
    case PathStrConst.generalInformation:
        path = t("leftSidebar.accountInfo");
        break;
    case PathStrConst.sendFunds:
        path = t("leftSidebar.transfer");
        break;
    case PathStrConst.addbeneficiary:
        path = t("leftSidebar.recipient");
        break;
    case PathStrConst.invoice:
        path = t("leftSidebar.invoice");
        break;
    case PathStrConst.statement:
        path = t("leftSidebar.statement");
        break;
    case PathStrConst.CardPayments:
         path = t("leftSidebar.cardPayment")
        break;
    case PathStrConst.editregistration:
         path = t("leftSidebar.editProfile")
        break;
    case PathStrConst.PrivacyPolicy:
         path = t("leftSidebar.privacyPolicy")
        break;
    case PathStrConst.OmwFees:
         path = t("footer.fee.title")
        break;
    case PathStrConst.Settings:
         path = t("leftSidebar.settings")
        break;
     
    }
    return path
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButtonClick = () => {
    setLoading(true);
    dispatch(Logout(token)).then(() => setLoading(false));
  };
  const handleAddUser = () => {
    setShowAddUser(true);
    setAnchorElUser(null)
  };
  const handleCloseAddUser = () => {
    setShowAddUser(false);
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setInviteFormValues({
      firstName:'',
      lastName:'',
      email:'',
    })
  };
  const resetInviteUserState = () => {
    setInviteFormValues({
      firstName:'',
      lastName:'',
      email:'',
    })
  };
  
  const handleSubmitInvitation = async (event:any) => {
    event.preventDefault();
    // Validate form fields
  let isValid = true;

  if (!inviteFormValues.firstName.trim()) {
    setFirstNameError(t("errorMsgs.emptyFirstName") as string);
    isValid = false;
  } else {
    setFirstNameError("");
  }

  if (!inviteFormValues.lastName.trim()) {
    setLastNameError(t("errorMsgs.emptyLastName") as string);
    isValid = false;
  } else {
    setLastNameError("");
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!inviteFormValues.email.trim()) {
    setEmailError(t("errorMsgs.emptyEmail") as string);
    isValid = false;
  } else if (!emailRegex.test(inviteFormValues.email)) {
    setInvalidEmailError(t("errorMsgs.incorrectEmail") as string);
    isValid = false;
  } else {
    setEmailError("");
  }

  if (!isValid) {
    return;
  }

    try {
      const auth = { "Authorization": `Bearer ${token}` }
   console.log("inviteFormValues =>", inviteFormValues)
   const {data} = await apiHelper(`user/getUser/${userId}`, "get", {}, auth)
   const companyDetails=data.data?.companyID
    const res = await apiHelper(`user/createAccountant`, "post", {
      ...inviteFormValues,
      companyId:companyDetails?._id,
      companyName:companyDetails?.companyName,
      userId
    }, auth)
  if(res.data.status.success===true) {
    dispatch(showAlert({
      type: 'success',
      title: `${t("sweetAlerts.success")}`,
      text: `${res.data.message}`,
      imageUrl: SuccessImage,
      imageAlt: 'Success Image'
    }));

    resetInviteUserState();
    handleCloseAddUser();
  }
      
    } catch (error) {
      dispatch(showAlert({
        type: 'error',
        title: `${t("sweetAlerts.error")}`,
        text: `${error}`,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
    }

  };

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setInviteFormValues({...inviteFormValues, [name]: value});
  };


  const getLastLogin = () => {
    setLoading(true);
    dispatch(GetLastLogin(token)).then((el: any) => {
      if(el?.payload?.data?.lastSync)
        setLastSync(el?.payload?.data?.lastSync)
        // setLastSync(new Date(el?.payload?.data?.lastSync).toLocaleString())
      if(el?.payload?.data?.lastLogin)
        setLastLogin(el?.payload?.data?.lastLogin)
        // setLastLogin(new Date(el?.payload?.data?.lastLogin).toLocaleString())
      else 
        setLastLogin(el?.payload?.data?.lastSync)
        // setLastLogin(new Date(el?.payload?.data?.lastSync).toLocaleString())
      setLoading(false)
    });
  }

  useEffect(() => {
    getLastLogin();
  }, [])

  useEffect(() => {
    if (userLogout?.data === undefined || userLogout?.data === "") {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.clear();
      disconnectSocket();
      navigate("/");
    }
  }, [userLogout]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onChangeRouteInMobileView = () => {
    if(width < 1025){
      setShow(false)
    }
  }

  useEffect(() => {
    onChangeRouteInMobileView()
  },[sidebarActiveTab])
 
  const removeSeconds =(time:any)=>{
    const solittedTime = time?.split(':');
    if(solittedTime){
      const hour = solittedTime[0]
      const minute = solittedTime[1]
      const AM_PM = time?.split(':')[2]?.split(" ")[1];
      return `${hour}:${minute} ${AM_PM}`
    }
    
    return time
  }

// dummy data array only for ui
  const [dummyData, setDummyData] = useState([{
    name: "Tayyab",
    email: "tayyab.qureshi@gmail.com",
    id: 1
  },
  {
    name: "Fahad",
    email: "fahad@gmail.com",
    id: 2
  },
  {
    name: "Sheheryar",
    email: "sheheryar@gmail.com",
    id: 3
  },
  {
    name: "Saad",
    email: "saad@gmail.com",
    id: 4
  },{
    name: "Usama",
    email: "usama@gmail.com",
    id: 5
  },
  {
    name: "Musa",
    email: "musa@gmail.com",
    id: 6
  }
])

// if you want to see swtich account menu setswitchAccountMenu = true
  const [switchAccountMenu, setswitchAccountMenu] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState(email);
  const [showAddAccountant, setShowAddAccountant] = useState<boolean>(false);

  useEffect(() => {
    setSelectedUser(sessionEmail ? sessionEmail: email);
  }, [sessionEmail])

  useEffect(() => {
    if(roles && roles?.includes('ACCOUNTANT')) setswitchAccountMenu(true)
    if (roles && roles?.includes('ADMIN')) setShowAddAccountant(true)
  }, [])

  const handleRadioChange = (id:any) => {
    setSelectedUser(id);
  };

  return (
    <>
      <div className="mainHeader d-flex justify-content-between align-items-center">
        <button className="btn hamburgerMenuButton" type="button" onClick={handleShow}>
          {/* <BiAlignJustify /> */}
          <img src={HamburgerMenu}/>
          </button>
          {/* <span>{sidebarActiveTab}</span> */}
          <span className="header-page-name">{setHeaderActivePage(pathname)}</span>
        <div className="d-flex justify-content-end align-items-center headerActions ms-auto">
          {/* {
            roles.includes('ACCOUNTANT') ? (
              <div className="mx-2 email-dropdown-container" >
                <Box sx={{ minWidth: 300 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      className={classes.emailSelect}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      defaultValue={sessionEmail}
                      value={sessionEmail}
                      label=""
                      IconComponent={()=> <BsChevronDown size={22} color="#006FF4" style={{marginRight:"8px"}}/>}
                    >
                      <MenuItem classes={{root:classes.menuItemRoot}} value={email} onClick={() => logoutAdminSession()}>{firstName} {lastName}</MenuItem>
                      {
                        admins?.map((el: any, index) => {
                          return <MenuItem key={index} value={el?.email} classes={{root:classes.menuItemRoot}} onClick={() => loginAdminSession(el?.email)}>{`${el?.firstName} ${el?.lastName}`}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Box>
              </div>
            ): null
          } */}

         
          
        {/* <div className="merchantDiv" style={{marginRight:"60px"}} >
            <MerchantDropdown />
          </div> */}
          <div className="languageDiv" >
            <LanguageDropdown />
          </div>
          <div className="notificationDiv d-flex  align-items-center flex-column">
            <img src={NotificationBell} />
          </div>
          <div className="userIconDiv">
            {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
            {/* <Tooltip title="Open settings"> */}
            <IconButton onClick={handleOpenUserMenu} sx={{ borderRadius: "6px !important", padding: "0 !important" }}>
              <Avatar
                sx={{borderRadius: "6px"}}
                alt={firstName?.charAt(0).toUpperCase()}
                src="/static/images/avatar/2.jpg"
              />
            </IconButton>
            {/* </Tooltip> */}
            {/* <Menu
              className="menu-love"
              sx={{ marginTop: "45px !important"}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{vertical: "top",horizontal: "right"}}
              keepMounted
              transformOrigin={{vertical: "top",horizontal: "right"}}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            
              <MenuItem onClick={handleCloseUserMenu}>
                <ListItemText>{firstName}</ListItemText>
                <ListItemText>{lastName}</ListItemText>
              </MenuItem>
              <Divider className="divider" />
              <MenuItem onClick={() => {
                handleCloseUserMenu();
                navigate("/editregistration");
                }}>
                <ListItemIcon>
                  <BsFillPencilFill />
                </ListItemIcon>
                <ListItemText>{t("editProfile")}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleButtonClick}>
                <ListItemIcon>
                  <MdLogout />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
              <Divider className="divider"/>

              <MenuItem disabled className="lastLogin-lastSync">
                <ListItemText><b>Last Login:</b>&nbsp;</ListItemText>
                
                <ListItemText>{lastLogin}</ListItemText>
              </MenuItem>
              <Divider className="divider"/>
              
              <MenuItem disabled className="lastLogin-lastSync">
                <ListItemText><b>Synced:</b>&nbsp;</ListItemText>
               
                <ListItemText>{lastSync}</ListItemText>
              </MenuItem>
            </Menu> */}
             <Menu
              className="menu-love"
              sx={{ marginTop: "45px !important"}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{vertical: "top",horizontal: "right"}}
              keepMounted
              transformOrigin={{vertical: "top",horizontal: "right"}}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              { switchAccountMenu &&
               <div style={{display: "flex", flexDirection:"column", padding:"0px 16px"}}>
               <h2 className="swtichAccount-title">{t("switchaccounts")}</h2>
               <div className="input-container">
               <InputTypeText
               placeholder={t("searchaccountplaceholder")}
               className="search-input-recent-op-modal"
               onChange={(event) => {
                setSearchTerm(event.target.value);
               }}
               />
               <IoIosSearch  className="search-icon"/>
               </div>
              </div>
              }
            
              {switchAccountMenu ? (
                <div className="useraccount-parent">
                  <div className="useraccount-child" onClick={() => { 
                      handleRadioChange(email);
                      logoutAdminSession();
                    }}>
                    <Avatar
                      sx={{borderRadius: "6px"}}
                      alt={firstName?.charAt(0).toUpperCase()}
                      src="/static/images/avatar/2.jpg"
                    />
                    <div style={{display: "flex",flexDirection: "column", gap: "4px",flex: 1}}>
                      <h2 className="username">{firstName} {lastName}</h2>
                      <p className="user-email" style={{margin: "0px"}}>{email}</p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                      <input
                      className="radio-button"
                      type="radio"
                      checked={selectedUser === email}
                      />
                    </div>
                  </div>
                {admins?.map((item: any,) => (
                  <div key={item._id} className="useraccount-child" onClick={() => { 
                      handleRadioChange(item?.email);
                      loginAdminSession(item?.email)
                    }}>
                    <Avatar
                      sx={{borderRadius: "6px"}}
                      alt={item?.firstName?.charAt(0).toUpperCase()}
                      src="/static/images/avatar/2.jpg"
                    />
                    <div style={{display: "flex",flexDirection: "column", gap: "4px",flex: 1}}>
                      <h2 className="username">{item?.firstName} {item?.lastName}</h2>
                      <p className="user-email" style={{margin: "0px"}}>{item?.email}</p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                      <input
                      className="radio-button"
                      type="radio"
                      checked={selectedUser === item?.email}
                      />
                    </div>
                  </div>
                ))}
                </div>
              ) : (
                <>
                <div className="useraccount-child" style={{padding: "0px 16px 10px 16px"}} onClick={()=> navigate("/")}>
                  <Avatar
                    sx={{borderRadius: "6px"}}
                    alt={firstName?.charAt(0).toUpperCase()}
                    src="/static/images/avatar/2.jpg"
                  />
                  <div>
                    <h2 className="username">{`${firstName} ${lastName}`}</h2>
                    {/* <p style={{margin: "0px",fontSize: "12px"}}>{email}</p> */}
                  </div>
                </div>
                </>
              )    
            }
            <hr style={{margin: "0px 16px 10px 16px"}}/>
              <MenuItem onClick={() => {
                handleCloseUserMenu();
                navigate("/editregistration");
                }}>
                <ListItemIcon>
                  <LiaUserEditSolid />
                </ListItemIcon>
                <ListItemText>{t("editProfile")}</ListItemText>
              </MenuItem>
              { showAddAccountant &&
             <MenuItem onClick={handleAddUser}>
                <ListItemIcon>
                  <AiOutlineUsergroupAdd />
                </ListItemIcon>
                <ListItemText>{t("inviteUserModal.addAccountManager")}</ListItemText>
              </MenuItem>
            }
              <MenuItem onClick={()=>{
                navigate("/settings");
              }}>
                <ListItemIcon>
                  <IoSettingsOutline />
                </ListItemIcon>
                <ListItemText>{t("leftSidebar.settings")}</ListItemText>
              </MenuItem>
              
              
              <MenuItem onClick={handleButtonClick}>
                <ListItemIcon>
                  <MdLogout />
                </ListItemIcon>
                <ListItemText>{t("footer.logout")}</ListItemText>
              </MenuItem>
              
              <hr style={{margin: "10px 16px 0px 16px"}}/>

              <MenuItem disabled className="lastLogin-lastSync">
                <p style={{margin: "0px"}} className="lastLogin-lastSync-text"><b>{t("lastlogin")}:</b>&nbsp;</p>
                
                <p style={{margin: "0px"}} className="lastLogin-lastSync-text">{lastLogin}</p>
              </MenuItem>
              
              <MenuItem disabled className="lastLogin-lastSync">
                <p style={{margin: "0px"}} className="lastLogin-lastSync-text"><b>{t("synced")}:</b>&nbsp;</p>
                
                <p style={{margin: "0px"}} className="lastLogin-lastSync-text">{lastSync}</p>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {/* Sidedrawer */}
      <div>
        <Offcanvas show={show} onHide={handleClose} style={{ width: "250px" }}>
          {/* <Offcanvas.Header closeButton></Offcanvas.Header> */}
          <Offcanvas.Body>
            <LeftSideBar name={sidebarActiveTab} />
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {/* Invite User Modal */}
      <Modal
        style={{ marginTop: 100 }}
        show={showAddUser}
        onHide={handleCloseAddUser}
        backdrop="static"
        keyboard={false}
        className="edit-benificiety-modal-root"
      >
        <ModalHeader title={t("inviteUserModal.inviteUser")} onClose={handleCloseAddUser}/> 
        <Modal.Body>
          <form onSubmit={handleSubmitInvitation}>
            <p className="formInputLabel">{t("registration.firstName")}</p>
            <InputTypeText
                type="text"
                className="inputText"
                name="firstName"
                // placeholder={t("registration.firstName")}
                value={inviteFormValues.firstName}
                maxLength={20}
                onBlur={() =>
                  inviteFormValues.firstName === ""
                    ? setFirstNameError(t("errorMsgs.emptyName") as string)
                    : setFirstNameError("")
                }
                onChange={handleInputChange}
                errorMsg={firstNameError && `${t("errorMsgs.emptyFirstName")}`}
              />

            <p className="formInputLabel">{t("registration.lastName")}</p>
            <InputTypeText
                type="text"
                className="inputText"
                name="lastName"
                // placeholder={t("registration.lastName")}
                value={inviteFormValues.lastName}
                maxLength={20}
                onBlur={() =>
                  inviteFormValues.lastName === ""
                    ? setLastNameError(t("errorMsgs.emptyName") as string)
                    : setLastNameError("")
                }
                onChange={handleInputChange}
                errorMsg={lastNameError && `${t("errorMsgs.emptyLastName")}`}
              />
            <p className="formInputLabel">{t("registration.email")}</p>
            <InputTypeText
            type="email"
            className="inputText"
            name="email"
            value={inviteFormValues.email}
            onBlur={() => {
              if (inviteFormValues.email === "") {
                setEmailError(t("errorMsgs.emptyEmail") as string);
                setInvalidEmailError("");
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inviteFormValues.email)) {
                setInvalidEmailError(t("errorMsgs.incorrectEmail") as string);
                setEmailError("");
              } else {
                setEmailError("");
                setInvalidEmailError("");
              }
            }}
            onChange={handleInputChange}
            errorMsg={emailError && `${t("errorMsgs.emptyEmail")}` || invalidEmailError && `${t("errorMsgs.incorrectEmail")}`}
          />
            <div className="btn-send mt-4">
              <BasicButton type="submit" isLoading={false} disabled={false} text={t("inviteUserModal.invite")} />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
