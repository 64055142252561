import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ExchangeRates, TempExchangeRates } from "../../../redux/toolkit/exchangeRates/operation";
import { BiRefresh } from "react-icons/bi";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import CurrencyFlag from "react-currency-flags";
import "./index.css";
import COLORS from "../../../utils/colors";
import CurrencyFlagAndValue from "./CurrencyFlagAndValue";

const ExchangeRatesCard = ( reload:any ) => {
  const dispatch = useDispatch<AppDispatch>();
  const exchangeRates: any = useSelector(
    (state: RootState) => state.exchangeRates.data
  );
  const [exchangeRateState, setExchnageRate] = useState<any>([]);
  const [loader, setLoader] = useState(true);
  const [maketClosedError, setMarketClosedError] = useState(false);
  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);


  const { t } = useTranslation();

  const handleRefresh = () => {
    setLoader(true);
  };


  useEffect(() => {
    const checkMarketStatus = async () => {
      const payLoadData = {
        accCurrency: ParsedData?.currency,
        toCurrency: "HUF"
      };
      const res = await dispatch(TempExchangeRates(payLoadData)) as { payload: { status: number } }; 
      if (res.payload == undefined) {
        setMarketClosedError(true)
        setLoader(false); 
      }
    };
    checkMarketStatus();
  },[])

  useEffect(() => {
    const payLoadData = {
      accCurrency: localStorage.getItem('CurrForExhangeRateCard'),
    };

    dispatch(ExchangeRates(payLoadData))
    .then(() => {
      setLoader(false);
    })
    .catch((error) => {
      console.error('Error in dispatch:', error);
      setLoader(false); 
    });

  // setLoader(true);

  }, [dispatch, loader,reload]);
// }, [dispatch]);

  useEffect(() => {
    if (exchangeRates && exchangeRates.data && exchangeRates.data.data) {
      const filteredExchangeRates = exchangeRates.data.data.filter(
        (ele: { convertToCurrency: string }) =>
          ["EUR", "USD", "DKK", "SEK", "NOK","GBP"].includes(ele.convertToCurrency)
      );

      setExchnageRate(filteredExchangeRates);

      localStorage.setItem("exchangeRates", JSON.stringify(filteredExchangeRates));
      setLoader(false);
    }
  }, [ exchangeRates,loader,reload]);

  const CurrencyFlagComponent = (logo: string) => (
    <CurrencyFlag currency={logo} size='lg'/>
  );

  return (
    <div>

      <div className="exchange-rates-box">
        <div className="refresh-icon-container my-2">
          <p className="exchange-text m-0">{t("exchangeRate.title")}</p>
          <BiRefresh
            className="refreshIcon"
            // color="#86b6ef"
            color={COLORS.pimary}
            size={27}
            onClick={handleRefresh}
          />
        </div>
        <ul className="p-0 m-0 ex_rates_list">
          {loader ? (
            <Stack spacing={1}>
              <Skeleton
                style={{ marginLeft: 10 }}
                variant="circular"
                width={40}
                height={40}
              />
              <Skeleton
                style={{ marginLeft: 12 }}
                variant="rectangular"
                width={180}
                height={60}
              />
            </Stack>
          ) :  maketClosedError ? (
            <div className="message-container">
              <p className="message-text">FX Markets currently closed! Opening hours are Monday - Friday 8:00-19:00 CET</p>
            </div>
          ) : (
            exchangeRateState?.map(
              (ele: { amount?: string; convertToCurrency: string }) => {
                // console.log('currencyA: ', ele)
                return (
                  <CurrencyFlagAndValue key={ele.convertToCurrency} currencyAmount={ele?.amount} currencyToConvert={ele.convertToCurrency}/>
                );
              }
            )
          )}
        </ul>
        <div className="valid-for">
          <p className="valid-text">{t("exchangeRate.description")}</p>
        </div>
      </div>
    </div>
  );
};
export default ExchangeRatesCard;
