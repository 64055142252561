import React, { useEffect, useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { privateR, publicRoutes } from "./constants/routes";
import PrivateRoute from "./constants/routes/privateRoute";
import PublicRoute from "./constants/routes/publicRoute";
import { useDispatch, useSelector } from "react-redux";
import ThreeDSAuthModal from "./pages/BankAccountCard/CardSettingModals/ThreeDSAuthModal";
import { AppDispatch } from "./redux/store";
import AppLayout from "./components/shared/AppLayout";
import { socketActions } from "./redux/toolkit/auth/reducer";
import { socketSelec } from "./redux/toolkit/auth/selector";
import SWALWrapper from "./components/shared/Swal";
import { loadPublicCrisp } from "./utils/crisp";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const QRToken = useSelector((state: any) => state.qrDetails?.data?.data?.accessToken)
  const OTPToken = useSelector((state: any) => state.otpVerifyDetails?.data?.data?.accessToken)
  const socketSelector: any = useSelector(socketSelec);
  const jwtToken: string = QRToken || OTPToken;
  const userId: any = localStorage.getItem("userId");
  const roles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");

  useEffect(() => {
    if (jwtToken && typeof jwtToken === 'string' && jwtToken.length > 0 && userId) {
      dispatch(socketActions.startConnecting({ jwtToken, userId }));
    } else {
      dispatch(socketActions.disconnect());
      close3DSModal();
      loadPublicCrisp();
    }
  }, [jwtToken, userId]);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('3DS_webhook', (data: any) => {
  //       setThreeDSData(data)
  //       setThreeDSModal(true)
  //     })
  //     socket.on("card_notification", (data: any) => {
  //       console.log("card notification received", data)
  //       dispatch(getAllCardsNotifications({ userId: localStorage.getItem("userId")! }))
  //       dispatch(getAllCards({ userId: localStorage.getItem("userId")! }))
  //     })
  //     socket.on('3DS_response_received', () => {
  //       setThreeDSData(null)
  //       setThreeDSModal(false)
  //     })
  //   }
  // }, [socket?.id])

  const close3DSModal = () => {
    dispatch(socketActions.threeDSResponseReceived());
    // setThreeDSModal(false)
    // setThreeDSData(null)
  }

  return (
    <BrowserRouter>
     {/* SWALWrapper should be included here */}
     <SWALWrapper />
      {socketSelector.isConnected && socketSelector.threeDSModal
        ? <ThreeDSAuthModal
          data={socketSelector.threeDSData}
          closeModal={close3DSModal}
        />
        : null
      }

      <Routes>
        {publicRoutes.map((value) => (
          <Route path={value.path} element={<PublicRoute />} key={""}>
            <Route path={value.path} element={value.component} />
          </Route>
        ))}
        <Route element={<AppLayout />} >
          {privateR.map((value, index) => {
            if (!roles || roles?.length === 0 || value?.roles.some(item => roles?.includes(item)))
              return (
                <Route path={value.path} element={<PrivateRoute />} key={""}>
                  <Route path={value.path} element={value.component} />
                </Route>
              )
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
