import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputTypeSecure from '../../components/shared/Input/InputTypeSecure';
import { regexStrConst } from '../../constants/stringConstants';
import "./index.css";
import BasicButton from '../../components/shared/Buttons/Basic';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { changePasswordOp } from '../../redux/toolkit/auth/operation';
import { resetStatus } from '../../redux/actions';
import ModalHeader from '../../components/shared/Modal/ModalHeader';
import StrongCustomerAuthentication from '../../components/shared/StrongCustomerAuthentication';

const Settings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const statusSelector: string = useSelector((state: RootState) => state.changePassword.status);
    const token: string = localStorage.getItem('token') as string;
    const email: string = localStorage.getItem('userEmail') as string;
    const [isShowCreatePass, setIsShowCreatePass] = useState(true);

    // Separate state for current password, new password, and confirm password
    const [currentPassword, setcurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    // Separate error states for each password field
    const [currentPasswordError, setcurrentPasswordError] = useState<string>("");
    const [newPasswordError, setNewPasswordError] = useState<string>("");
    const [emptyNewPassword, setEmptyNewPassword] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
    const [show2FA, setShow2FA] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [authCompleted, setAuthCompleted] = useState<boolean>(false);

    const handleClose2FA = () => {
        setLoading(false);
        setShow2FA(false);
        dispatch(resetStatus());
    }

    // Validation for new password
    const onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmptyNewPassword("");
        const item = event.target.value;
        if (!regexStrConst.passwordRegex.test(item)) {
            setNewPasswordError('invalidPassword');
        } else {
            setNewPasswordError('');
        }
        setNewPassword(item);
    };

    const validateConfirmPassword = () => {
        if (confirmPassword !== newPassword) {
            setConfirmPasswordError('confirmPasswordNotMatch');
        } else {
            setConfirmPasswordError('');
        }
    };

    const validateOnSubmit = () => {
        let isValid = true;

        if (!currentPassword) {
            setcurrentPasswordError('empty');
            isValid = false;
        } else {
            setcurrentPasswordError('');
        }

        if (!newPassword) {
            setEmptyNewPassword('empty');
            isValid = false;
        } else {
            setEmptyNewPassword('');
        }

        if (confirmPassword !== newPassword) {
            setConfirmPasswordError('confirmPasswordNotMatch');
            isValid = false;
        } else {
            setConfirmPasswordError('');
        }

        if (newPasswordError) {
            isValid = false;
        }

        if (confirmPasswordError) {
            isValid = false;
        }

        return isValid;
    };


    // Submit handler
    const handleSubmit = () => {
        if (validateOnSubmit()) {
            setLoading(true);
            setShow2FA(true);
            setAuthCompleted(false);
        }
    };
    const handleChangePassword = () => {
        const payload = {
            email,
            currentPassword,
            newPassword,
            token
        }
        dispatch(changePasswordOp(payload))
    };

    useEffect(() => {
        if (statusSelector === "succeeded") {
            setcurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
        }
    }, [statusSelector])

    useEffect(() => {
        if (authCompleted) {
            handleChangePassword();
            setShow2FA(false);
            setLoading(false);
            dispatch(resetStatus());
        }
    }, [authCompleted]);


    return (
        <div>
            <Row className='setting-main'>
                <Col xs={12} sm={12} md={7} lg={7} xl={7} className='settingFormBox'>
                    <div className='settingFormWidth'>
                        <h1 className="onBoarding-title">{t("changePassword.title")}</h1>
                        <p className="onBoarding-text">{t("changePassword.description")}</p>

                        {/* Old Password */}
                        <p className="inputLabel mt-2">{t("changePassword.currentPassword")}</p>
                        <InputTypeSecure
                            type={isShowCreatePass ? 'password' : 'text'}
                            className="inputTextBox inputPass"
                            value={currentPassword}
                            onBlur={(e: any) => setcurrentPasswordError(!e.target.value ? 'empty' : '')}
                            onChange={(e: any) => {
                                setcurrentPassword(e.target.value);
                                setcurrentPasswordError('');
                            }}
                            errorMsg={currentPasswordError && t("changePassword.emptyCurrentPassword")}
                        />

                        {/* New Password */}
                        <p className="inputLabel mt-2">{t("changePassword.newPassword")}</p>
                        <InputTypeSecure
                            type={isShowCreatePass ? 'password' : 'text'}
                            className="inputTextBox inputPass"
                            value={newPassword}
                            onBlur={(e: any) => onChangeNewPassword(e)}
                            onChange={onChangeNewPassword}
                            errorMsg={newPasswordError && t("errorMsgs.emptyPassword") || emptyNewPassword && t("changePassword.emptyNewPassword")}
                        />
                        <div>
                            <span className="passwordReqText">
                                {t("errorMsgs.emptyPasswordReg")}
                            </span>
                        </div>

                        {/* Confirm New Password */}
                        <p className="inputLabel mt-2">{t("changePassword.confirmNewPassword")}</p>
                        <InputTypeSecure
                            type={isShowCreatePass ? 'password' : 'text'}
                            className="inputTextBox inputPass"
                            value={confirmPassword}
                            onBlur={validateConfirmPassword}
                            onChange={(e: any) => setConfirmPassword(e.target.value)}
                            errorMsg={confirmPasswordError && t("changePassword.confirmPasswordNotMatch")}
                        />

                        <br />
                        <div className='mt-3'>
                            <BasicButton
                                type="submit"
                                text={t("changePassword.update")}
                                isLoading={loading ? true : false || statusSelector === "loading"}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            {/* 2FA modal */}
            <Modal
                show={show2FA}
                onHide={handleClose2FA}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader onClose={handleClose2FA} />
                <Modal.Body>
                    <StrongCustomerAuthentication setAuthCompleted={setAuthCompleted} showImage />
                </Modal.Body>

            </Modal>
        </div>
    );
};

export default Settings;
