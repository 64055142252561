import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorMsgs, regexStrConst } from "../../../../constants/stringConstants";

import '../../index.css'
import { B4BCardHolder, B4BCardHolderAddress, B4BCardHolderShippingAddress } from "../../interface";
import { useDispatch, useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import { AppDispatch, RootState } from "../../../../redux/store";
import { createPhysicalCard, createVirtualCard, getAllCardOrders, getAllCards } from "../../../../redux/toolkit/cards/operation";
import { CreateCardPayload, CreateVirtualCardPayload } from "../../../../redux/toolkit/cards/interface";
import * as iso from "iso-3166-1";
import { Col, Form, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { toNumber } from "lodash";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { Country } from "iso-3166-1/dist/iso-3166";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import { showAlert } from "../../../../redux/toolkit/swal/reducer";
import { ErrorImage, SuccessImage } from "../../../../constants/Images";
import { AiOutlineArrowLeft } from "react-icons/ai";
import virtualCardIcon from '../../../../assets/images/cardAssets/virtual-card-colored.svg'
import physicalCardIcon from '../../../../assets/images/cardAssets/physical-card-colored.svg'

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  tabPanel: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabPanel, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabPanel !== index}
      id={`card-typepanel-${index}`}
      aria-labelledby={`card-type-${index}`}
      {...other}
    >
      {tabPanel === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `card-type-${index}`,
    'aria-controls': `card-typepanel-${index}`,
  };
}

type DefaultAccountsOptions = {
  accountName: string;
  accB4BUUID: string;
}

type ParsedAccountsOptions = {
  label: string; //Business Account Name
  value: string; //Business Account UUID - accB4BUUID
}

// Get the current year
const currentYear = new Date().getFullYear();
const maxYear = currentYear - 18;

// Construct the regular expression
const dobRegex = new RegExp(`^(19[0-9]{2}|20[0-${maxYear.toString().slice(2, 3)}][0-${maxYear.toString().slice(3)}])-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$`);
const emailRegex = regexStrConst.emailRegex;

const ApplyforCardForm = (props: {
  cardHolder: B4BCardHolder,
  setCardHolder: React.Dispatch<React.SetStateAction<B4BCardHolder>>,
  cardHolderAddress: B4BCardHolderAddress,
  setCardHolderAddress: React.Dispatch<React.SetStateAction<B4BCardHolderAddress>>,
  setApplyCard: any
  setCardTypeSelected: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { cardHolder, setCardHolder, cardHolderAddress, setCardHolderAddress, setApplyCard, setCardTypeSelected } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<B4BCardHolder & B4BCardHolderAddress & B4BCardHolderShippingAddress>({
    // mandatory fields for card holder
    first_name: "",
    last_name: "",
    dateOfBirth: "",
    email: "",
    locale: "",
    mobileNo: "",
    //optional fields for card holder
    title: "",
    gender: "",
    telephoneNo: "",
    //CONDITIONAL: Mandatory for physical card order
    embossedName: "",

    // mandatory fields for card holder address
    line_1: "",
    city: "",
    post_code: "",
    //optional fields for card holder address
    country_code: "",
    line_2: "",
    line_3: "",

    // mandatory fields for card holder SHIPPING address
    s_line_1: "",
    s_city: "",
    s_post_code: "",
    fao: "",
    // optional fields for card holder SHIPPING address
    s_country_code: "",
    s_line_2: "",
    s_line_3: "",
  })
  const [missingFields, setMissingFields] = useState<boolean>();
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [differentShippingAddress, setDifferentShippingAddress] = useState<boolean>(true);
  const [tabPanel, setTabPanel] = useState<number>(2);
  const [parsedBusinessAccounts, setParsedBusinessAccounts] = useState<ParsedAccountsOptions[]>([]);
  const [ibanAccUUID, setIbanAccUUID] = useState<string>("");
  const [shippingAddress, setShippingAddress] = useState<B4BCardHolderShippingAddress>({
    s_line_1: "",
    s_city: "",
    s_post_code: "",
    s_country_code: "",
    fao: ""
  });

  const createPhysicalCardSelector = useSelector(cardSelectors.createPhysicalCardSelect);
  const createVirtualCardSelector = useSelector(cardSelectors.createVirtualCardSelect);
  const businessAccSelector: any = useSelector((state: RootState) => state.getAllAccounts.data);

  const cardHolderFields = ["embossedName", "title", "gender", "first_name", "last_name", "dateOfBirth", "email", "mobileNo", "telephoneNo"];
  const cardHolderAddressFields = ["line_1", "city", "post_code", "country_code"];
  const shippingAddressFields = ["s_line_1", "s_city", "s_post_code", "s_country_code", "fao"];

  // Define an array of mandatory field names for checking error before submit
  const mandatoryCardHolderFields: Array<keyof B4BCardHolder> = ["first_name", "last_name", "dateOfBirth", "email", "locale", "mobileNo"];
  const mandatoryPhysicalCardHolderFields: Array<keyof B4BCardHolder> = ["embossedName", "first_name", "last_name", "dateOfBirth", "email", "locale", "mobileNo"];

  // A list of all countries to create a country list dropdown
  const countries: Country[] = iso.all();
  const [showPreview, setShowPreview] = useState(false);


  useEffect(() => {
    if (businessAccSelector && businessAccSelector?.data?.data) {
      setParsedBusinessAccounts(parseBusinessAccounts(businessAccSelector.data.data))
    } else {
      setParsedBusinessAccounts([])
    }
  }, [businessAccSelector]);

  // Effect to set the value to the first option on component mount
  useEffect(() => {
    if (parsedBusinessAccounts.length > 0) {
      setIbanAccUUID(parsedBusinessAccounts[0].value);
    }
  }, [parsedBusinessAccounts]);

  const parseBusinessAccounts = (data: DefaultAccountsOptions[]): ParsedAccountsOptions[] => {
    return data.map(account => ({
      label: account.accountName,
      value: account.accB4BUUID
    }));
  };

  const checkMandatoryFields = (): boolean => {
    // Check mandatory cardholder fields based on the selected tabPanel (0: Virtual, 1: Physical)
    const missingCardHolderField = tabPanel === 1
      ? mandatoryPhysicalCardHolderFields.some(field => cardHolder[field] === "")
      : mandatoryCardHolderFields.some(field => cardHolder[field] === "");

    // Check if any address fields are missing
    const missingCardHolderAddressField = Object.values(cardHolderAddress).some(field => field === "");

    // Check if shipping address fields are missing
    const missingShippingAddressField = tabPanel === 1
      ? Object.values(shippingAddress).some(field => field === "")
      : false;

    // Check for missing IBAN Account UUID
    const isIbanAccountMissing = ibanAccUUID === "";

    // Return true if any mandatory fields are missing
    return missingCardHolderField || missingCardHolderAddressField || missingShippingAddressField || isIbanAccountMissing;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (cardHolderFields.includes(name)) {
      if (name !== "mobileNo" && name !== "telephoneNo" && name !== "dateOfBirth") {
        if (name === "email") {
          !emailRegex.test(value)
            ? setErrors({ ...errors, email: t("errorMsgs.incorrectEmail") })
            : setErrors({ ...errors, email: "" })
          const trimmedValue = value.replace(/\s/g, '');
          setCardHolder({ ...cardHolder, [name]: trimmedValue })
        } else {
          setCardHolder({ ...cardHolder, [name]: value })
        }
      } else {
        // Allow only numeric characters in the fields below
        if (name === "dateOfBirth") {
          !dobRegex.test(value)
            ? setErrors({ ...errors, dateOfBirth: t("applyForCardModal.validDate") })
            : setErrors({ ...errors, dateOfBirth: "" })
          const numericValue = value.replace(/[^-\d]/g, ''); // Filter out non-numeric characters except '-' for DOB
          setCardHolder({ ...cardHolder, [name]: numericValue });
        } else {
          const numericValue = value.replace(/[^+\d]/g, ''); // Filter out non-numeric characters except '+' for mobile and telephone number
          // Check if the value is just a "+" and set it to an empty string
          const sanitizedValue = numericValue === "+" ? "" : numericValue;
          setCardHolder({ ...cardHolder, [name]: sanitizedValue });
        }
      }
    } else if (cardHolderAddressFields.includes(name)) {
      setCardHolderAddress({ ...cardHolderAddress, [name]: value });
    } else if (shippingAddressFields.includes(name)) {
      setShippingAddress({ ...shippingAddress, [name]: value });
    }
  };

  const handleBlur = (name: string, value: string, errorMessage: string) => {
    if (value === "") {
      setErrors({ ...errors, [name]: errorMessage })
    } else if (value !== "" && name === "dateOfBirth") {
      !dobRegex.test(value)
        ? setErrors({ ...errors, dateOfBirth: t("applyForCardModal.validDate") })
        : setErrors({ ...errors, dateOfBirth: "" })
    } else if (value !== "" && name === "email") {
      !emailRegex.test(value)
        ? setErrors({ ...errors, email: t("errorMsgs.incorrectEmail") })
        : setErrors({ ...errors, email: "" })
    } else {
      setErrors({ ...errors, [name]: "" })
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    // Ensure all the fields have been filled in
    const missingCardHolderField = mandatoryCardHolderFields.some(field => cardHolder[field] === "");
    const missingCardHolderAddressField = Object.values(cardHolderAddress).some(field => field === "");
    if (missingCardHolderField || missingCardHolderAddressField
      // || (!termsChecked || !privacyChecked)
    ) {
      setMissingFields(true);
    } else {
      setMissingFields(false);

      switch (tabPanel) {
        case 0: {
          const virtualCardPayload: CreateVirtualCardPayload = {
            userId: localStorage.getItem('userId')!,
            cardDetails: {
              uuid: ibanAccUUID,
              cardholder: {
                title: cardHolder.title,
                first_name: cardHolder.first_name,
                last_name: cardHolder.last_name,
                gender: cardHolder.gender,
                dob: cardHolder.dateOfBirth,
                tel: cardHolder.telephoneNo,
                mobile: cardHolder.mobileNo,
                email: cardHolder.email,
                locale: cardHolder.locale
              },
              address: {
                line_1: cardHolderAddress.line_1,
                city: cardHolderAddress.city,
                post_code: cardHolderAddress.post_code,
                country_code: toNumber(cardHolderAddress.country_code)
              }
            }
          }
          // console.log("Virtual CARD SELECTED", virtualCardPayload);
          dispatch(createVirtualCard(virtualCardPayload))
            .unwrap()
            .then((resp) => {
              // console.log("this is create card resp", resp)
              setApplyCard(false);
              dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
              // Swal.fire({
              //   icon: "success",
              //   title: "Success",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Your virtual card is ready to use!",
              // });
              dispatch(showAlert({
                type: 'success',
                title: `${t("sweetAlerts.success")}`,
                text: `${t("sweetAlerts.virtualCardReady")}`,
                imageUrl: SuccessImage,
                imageAlt: 'Success Image'
              }));
            })
            .catch((err) => {
              // console.log("this is create card error", err)
              // Swal.fire({
              //   icon: "error",
              //   title: "Error",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Error while creating virtual card!",
              // });
              dispatch(showAlert({
                type: 'error',
                title: `${t("sweetAlerts.error")}`,
                text: `${t("sweetAlerts.errorCreatingVirtualCard")}`,
                imageUrl: ErrorImage,
                imageAlt: 'Error Image'
              }));
            });
          break;
        }
        case 1: {
          const physicalCardPayload: CreateCardPayload = {
            userId: localStorage.getItem('userId')!,
            cardDetails: {
              uuid: ibanAccUUID,
              embossed_name: cardHolder.embossedName || `${cardHolder.first_name} ${cardHolder.last_name}`,
              cardholder: {
                title: cardHolder.title,
                first_name: cardHolder.first_name,
                last_name: cardHolder.last_name,
                gender: cardHolder.gender,
                dob: cardHolder.dateOfBirth,
                tel: cardHolder.telephoneNo,
                mobile: cardHolder.mobileNo,
                email: cardHolder.email,
                locale: cardHolder.locale
              },
              address: {
                line_1: cardHolderAddress.line_1,
                city: cardHolderAddress.city,
                post_code: cardHolderAddress.post_code,
                country_code: toNumber(cardHolderAddress.country_code)
              },
              delivery_address: {
                fao: shippingAddress.fao,
                address: {
                  line_1: shippingAddress.s_line_1,
                  city: shippingAddress.s_city,
                  post_code: shippingAddress.s_post_code,
                  country_code: toNumber(shippingAddress.s_country_code)
                }
              },
              shipping_supplier: 'standard',
              // PAYMENT METHOD IS FOR THE CARD. FOR ALL OUR USERS, IT WILL BE CREDITS AS WE PURCHASE THE CARD STOCK
              // AND ASSIGN IT TO OUR USERS. IT CAN BE INVOICE FOR TESTING AS CREDITS WOULD END IN AN ERROR OTHERWISE.
              payment_method: process.env.REACT_APP_ENV === 'production' ? 'credits' : 'invoice'
            }
          };
          // console.log("Physical CARD SELECTED", physicalCardPayload);
          dispatch(createPhysicalCard(physicalCardPayload))
            .unwrap()
            .then((resp) => {
              // console.log("this is create card resp", resp)
              setApplyCard(false);
              dispatch(getAllCardOrders({ userId: localStorage.getItem('userId')! }))
              // Swal.fire({
              //   icon: "success",
              //   title: "Success",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Your physical card has been ordered!",
              // });
              dispatch(showAlert({
                type: 'success',
                title: `${t("sweetAlerts.success")}`,
                text: `${t("sweetAlerts.physicalCardOrdered")}`,
                imageUrl: SuccessImage,
                imageAlt: 'Success Image'
              }));
            })
            .catch((err) => {
              // console.log("this is create card error", err)
              // Swal.fire({
              //   icon: "error",
              //   title: "Error",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Error ordering physical card!",
              // });
              dispatch(showAlert({
                type: 'error',
                title: `${t("sweetAlerts.error")}`,
                text: `${t("sweetAlerts.errorOrderingPhysicalCard")}`,
                imageUrl: ErrorImage,
                imageAlt: 'Error Image'
              }));
            });
          break;
        }
        default:
          break;
      }
    }
  }

  const handleTabChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = Number(event.target.value);
    setTabPanel(newValue);
  };


  const getCurrentDateMinus18Years = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split('T')[0];
  };

  const handleSameShippingAddress = () => {
    setDifferentShippingAddress(!differentShippingAddress);

    // Only copy address if differentShippingAddress is about to be set to `false`
    if (differentShippingAddress) {
      setShippingAddress({
        ...shippingAddress, // Keeps fao unchanged
        s_line_1: cardHolderAddress.line_1,
        s_city: cardHolderAddress.city,
        s_post_code: cardHolderAddress.post_code,
        s_country_code: cardHolderAddress.country_code,
      });
    } else {
      // Reset to empty values if differentShippingAddress is set to `true`, keeping fao unchanged
      setShippingAddress({
        ...shippingAddress,
        s_line_1: "",
        s_city: "",
        s_post_code: "",
        s_country_code: "",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!showPreview && <div>
        {/* <Tabs
          value={tabPanel}
          onChange={handleTabChange}
          aria-label="card variant tabs"
          variant="fullWidth"
        >
          <Tab sx={{ fontFamily: 'Montserrat', fontWeight: 600 }} label= {t("applyForCardModal.virtualCard")} {...a11yProps(0)} />
          <Tab sx={{ fontFamily: 'Montserrat', fontWeight: 600 }} label= {t("applyForCardModal.physicalCard")} {...a11yProps(1)} />
        </Tabs> */}

        {/* <label>Select a card</label>
        <Form.Select
          className="enter-num-inp"
          name="cardSelect"
          value={tabPanel}
          // onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          //   setCardHolder({ ...cardHolder, title: e.target.value });
          // }}
          onChange={(e) => { handleTabChange(e) }}

        // aria-describedby="cardHolderTitleSelect"
        >
          <option value="2" disabled>No card selected</option>
          <option value="1">Physical Card</option>
          <option value="0">Virtual Card</option>
        </Form.Select> */}

        {/* Uncomment below div for selectable Virtual and Physical cards UI. */}
        <div className="card-selection-container">
          <div
            className={`card-option ${tabPanel === 1 ? "selected" : ""}`}
            onClick={() => {
              setCardTypeSelected(true)
              setTabPanel(1)
            }}
          >
            <img
              src={physicalCardIcon}  // Replace with the actual path or icon component
              alt="Physical Card Icon"
              className="card-icon"
            />
            <h5>{t("applyForCardModal.physicalCard")}</h5>
          </div>
          <div
            className={`card-option ${tabPanel === 0 ? "selected" : ""}`}
            onClick={() => {
              setCardTypeSelected(true)
              setTabPanel(0)
            }}
          >
            <img
              src={virtualCardIcon}  // Replace with the actual path or icon component
              alt="Virtual Card Icon"
              className="card-icon"
            />
            <h5>{t("applyForCardModal.virtualCard")}</h5>
          </div>
        </div>



        {/* VIRTUAL CARD TAB */}
        <CustomTabPanel tabPanel={tabPanel} index={0}>
          <label htmlFor="businessAccountInput">{t("applyForCardModal.businessAccount")} *</label>
          <br />
          <Form.Text id="businessAccountSelectionHelpBlock" muted>
            {t("applyForCardModal.businessAccountInfo")}
          </Form.Text>
          <Form.Select
            id="businessAccountInput"
            className="enter-num-inp"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setIbanAccUUID(e.target.value);
            }}
            aria-describedby="businessAccountSelectionHelpBlock"
            style={{ marginBottom: "0" }}
          >
            {parsedBusinessAccounts.length > 0
              ? parsedBusinessAccounts.map((busAcc: ParsedAccountsOptions, index: number) => (
                <option key={busAcc.value} value={busAcc.value}>
                  {busAcc.label}
                </option>
              ))
              : <option key={"no-accounts"} value={""} disabled>
                {t("applyForCardModal.noCorrespondingAccounts")}
              </option>
            }
          </Form.Select>
          <br />
          <Divider>
            <Form.Text>{t("applyForCardModal.cardholderInfo")}</Form.Text>
          </Divider>
          <label htmlFor="title">{t("applyForCardModal.title")}</label>
          <Form.Select
            id="title"
            className="enter-num-inp"
            name="title"
            value={cardHolder.title}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setCardHolder({ ...cardHolder, title: e.target.value });
            }}
            aria-describedby="cardHolderTitleSelect"
          >
            <option value="" disabled>{t("applyForCardModal.selectTitle")}</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </Form.Select>

          <label htmlFor="firstName">{t("applyForCardModal.firstName")} <Form.Text>({t("applyForCardModal.appearsOnCard")})</Form.Text>*</label>
          <InputTypeText
            id="firstName"
            compulsoryValue
            maxLength={20}
            placeholder={t("applyforcard.form.firstName")}
            value={cardHolder.first_name}
            name="first_name"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterFirstName"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.first_name}
          />

          <label htmlFor="lastName">{t("applyForCardModal.lastName")} <Form.Text>({t("applyForCardModal.appearsOnCard")})</Form.Text>*</label>
          <InputTypeText
            id="lastName"
            compulsoryValue
            maxLength={20}
            placeholder={t("applyforcard.form.lastName")}
            value={cardHolder.last_name}
            name="last_name"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterLastName"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.last_name}
          />

          <label htmlFor="dob">{t("applyForCardModal.DOB")} *</label>
          <InputTypeText
            id="dob"
            compulsoryValue
            maxLength={10}
            placeholder={t("applyforcard.form.dob")}
            value={cardHolder.dateOfBirth}
            name="dateOfBirth"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.emptyDOB"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.dateOfBirth}
            type="date"
            min="1900-01-01"
            max={getCurrentDateMinus18Years()}
          />

          <label htmlFor="email">{t("applyForCardModal.email")} *</label>
          <InputTypeText
            id="email"
            compulsoryValue
            maxLength={50}
            placeholder={t("applyforcard.form.email")}
            value={cardHolder.email}
            name="email"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyEmail"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.email}
          />

          <label htmlFor="mobileNumber">{t("applyForCardModal.mobileNumber")} *</label>
          <PhoneInput
            inputClass="countryPhoneInput"
            buttonClass="countryFlagButtonPhoneInput"
            country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
            value={cardHolder.mobileNo}
            placeholder={t("applyforcard.form.mobilenumber")!}
            inputProps={{
              id: "mobileNumber",
              name: "mobileNo",
              maxLength: 20,
              minLength: 10,
            }}
            inputStyle={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              color: "black",
            }}
            buttonStyle={{
              border: "none",
              backgroundColor: "#E6F0FA",
            }}
            containerStyle={{
              marginBottom: `${errors.mobileNo ? "0" : "10px"}`,
            }}
            onChange={(value, country, e, formattedValue) => handleInputChange(e)}
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterMobileNo"))}
          />
          {errors.mobileNo ? (
            <>
              <span
                style={{ color: "red", marginBottom: "10px", display: "inline-block" }}
              >
                {errors.mobileNo}
              </span>
              <br />
            </>
          ) : (
            <></>
          )}
          <label htmlFor="telephoneNumber">{t("applyForCardModal.telephoneNumber")}</label>
          <PhoneInput
            inputClass="countryPhoneInput"
            buttonClass="countryFlagButtonPhoneInput"
            country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
            value={cardHolder.telephoneNo}
            placeholder={t("applyforcard.form.phonenumber")!}
            inputProps={{
              id: "telephoneNumber",
              name: "telephoneNo",
              maxLength: 20,
              minLength: 10,
            }}
            inputStyle={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              color: "black",
            }}
            buttonStyle={{
              border: "none",
              backgroundColor: "#E6F0FA",
            }}
            containerStyle={{
              marginBottom: "0",
            }}
            onChange={(value, country, e, formattedValue) => handleInputChange(e)}
          />
          <br />
          <Divider>
            <Form.Text>{t("applyForCardModal.address")}</Form.Text>
          </Divider>

          <label htmlFor="cardholderAddress">{t("applyForCardModal.address")} *</label>
          <InputTypeText
            id="cardholderAddress"
            compulsoryValue
            maxLength={50}
            placeholder={t("applyforcard.form.address")}
            value={cardHolderAddress.line_1}
            name="line_1"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyAddress"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.line_1}
          />

          <label htmlFor="city">{t("applyForCardModal.city")} *</label>
          <InputTypeText
            id="city"
            compulsoryValue
            maxLength={20}
            placeholder={t("applyforcard.form.city")}
            value={cardHolderAddress.city}
            name="city"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyCity"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.city}
          />

          <label htmlFor="zipCode">{t("applyForCardModal.zipCode")} *</label>
          <InputTypeText
            id="zipCode"
            compulsoryValue
            placeholder={t("applyforcard.form.zipCode")}
            value={cardHolderAddress.post_code}
            name="post_code"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyPostCode"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.post_code}
          />

          <label htmlFor="country">{t("applyForCardModal.country")} *</label>
          <Form.Select
            id="country"
            className="enter-num-inp"
            name="country_code"
            value={cardHolderAddress.country_code}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setCardHolderAddress({ ...cardHolderAddress, country_code: e.target.value });
            }}
            aria-describedby="cardHolderCountrySelect"
          >
            <option value="" disabled>{t("addRecipient.form.selectCountry")}</option>
            {countries &&
              countries.map((ele: Country, index: number) => (
                <option value={ele.numeric} key={index}>
                  {ele.country}
                </option>
              ))}
          </Form.Select>
        </CustomTabPanel>

        {/* PHYSICAL CARD TAB */}
        <CustomTabPanel tabPanel={tabPanel} index={1}>
          <label htmlFor="businessAccountInput">{t("applyForCardModal.businessAccount")} *</label>
          <br />
          <Form.Text id="businessAccountSelectionHelpBlock" muted>
            {t("applyForCardModal.businessAccountInfo")}
          </Form.Text>
          <Form.Select
            id="businessAccountInput"
            className="enter-num-inp"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setIbanAccUUID(e.target.value);
            }}
            aria-describedby="businessAccountSelectionHelpBlock"
          >
            {parsedBusinessAccounts.length > 0
              ? parsedBusinessAccounts.map((busAcc: ParsedAccountsOptions, index: number) => (
                <option key={busAcc.value} value={busAcc.value}>
                  {busAcc.label}
                </option>
              ))
              : <option key={"no-accounts"} value={""} disabled>
                {t("applyForCardModal.noCorrespondingAccounts")}
              </option>
            }
          </Form.Select>

          <label htmlFor="embossedName">{t("applyForCardModal.embossedName")} <Form.Text>({t("applyForCardModal.appearsOnCard")})</Form.Text>*</label>
          <InputTypeText
            id="embossedName"
            compulsoryValue
            maxLength={27}
            placeholder={t("applyForCardModal.nameToBeEmbossed")}
            value={cardHolder.embossedName}
            name="embossedName"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterNameToBeEmbossed"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.embossedName}
            inputContainerMargins="0"
          />

          {/* Cardholder Information Section::: START */}
          <div>
            <br />
            <Divider>
              <Form.Text>{t("applyForCardModal.cardholderInfo")}</Form.Text>
            </Divider>
            <label htmlFor="title">{t("applyForCardModal.title")}</label>
            <Form.Select
              id="title"
              className="enter-num-inp"
              name="title"
              value={cardHolder.title}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setCardHolder({ ...cardHolder, title: e.target.value });
              }}
              aria-describedby="cardHolderTitleSelect"
            >
              <option value="" disabled>{t("applyForCardModal.selectTitle")}</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
            </Form.Select>

            <label htmlFor="firstName">{t("applyForCardModal.firstName")} *</label>
            <InputTypeText
              id="firstName"
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.firstName")}
              value={cardHolder.first_name}
              name="first_name"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterFirstName"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.first_name}
            />

            <label htmlFor="lastName">{t("applyForCardModal.lastName")} *</label>
            <InputTypeText
              id="lastName"
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.lastName")}
              value={cardHolder.last_name}
              name="last_name"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterLastName"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.last_name}
            />

            <label htmlFor="dob">{t("applyForCardModal.DOB")} *</label>
            <InputTypeText
              id="dob"
              compulsoryValue
              maxLength={10}
              placeholder={t("applyforcard.form.dob")}
              value={cardHolder.dateOfBirth}
              name="dateOfBirth"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.emptyDOB"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.dateOfBirth}
              type="date"
              min="1900-01-01"
              max={getCurrentDateMinus18Years()}
            />

            <label htmlFor="email">{t("applyForCardModal.email")} *</label>
            <InputTypeText
              id="email"
              compulsoryValue
              maxLength={50}
              placeholder={t("applyforcard.form.email")}
              value={cardHolder.email}
              name="email"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyEmail"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.email}
            />

            <label htmlFor="mobileNumber">{t("applyForCardModal.mobileNumber")} *</label>
            <PhoneInput
              inputClass="countryPhoneInput"
              buttonClass="countryFlagButtonPhoneInput"
              country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
              value={cardHolder.mobileNo}
              placeholder={t("applyforcard.form.mobilenumber")!}
              inputProps={{
                id: "mobileNumber",
                name: "mobileNo",
                maxLength: 20,
                minLength: 10,
              }}
              inputStyle={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                color: "black",
              }}
              buttonStyle={{
                border: "none",
                backgroundColor: "#E6F0FA",
              }}
              containerStyle={{
                marginBottom: `${errors.mobileNo ? "0" : "10px"}`,
              }}
              onChange={(value, country, e, formattedValue) => handleInputChange(e)}
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterMobileNo"))}
            />
            {errors.mobileNo ? (
              <>
                <span
                  style={{ color: "red", marginBottom: "10px", display: "inline-block" }}
                >
                  {errors.mobileNo}
                </span>
                <br />
              </>
            ) : (
              <></>
            )}

            <label htmlFor="telephoneNumber">{t("applyForCardModal.telephoneNumber")}</label>
            <PhoneInput
              inputClass="countryPhoneInput"
              buttonClass="countryFlagButtonPhoneInput"
              country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
              value={cardHolder.telephoneNo}
              placeholder={t("applyforcard.form.phonenumber")!}
              inputProps={{
                id: "telephoneNumber",
                name: "telephoneNo",
                maxLength: 20,
                minLength: 10,
              }}
              inputStyle={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                color: "black",
              }}
              buttonStyle={{
                border: "none",
                backgroundColor: "#E6F0FA",
              }}
              containerStyle={{
                marginBottom: "0",
              }}
              onChange={(value, country, e, formattedValue) => handleInputChange(e)}
            />
          </div>
          {/* Cardholder Information Section::: END */}

          {/* Cardholder Address Section::: START */}
          <div>
            <br />
            <Divider>
              <Form.Text>{t("applyForCardModal.address")}</Form.Text>
            </Divider>

            <label htmlFor="c_line_1">{t("applyForCardModal.streetAddress")} *</label>

            <InputTypeText
              id="c_line_1"
              compulsoryValue
              maxLength={50}
              placeholder={t("applyforcard.form.address")}
              value={cardHolderAddress.line_1}
              name="line_1"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyAddress"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.line_1}
            />

            <label htmlFor="c_city">{t("applyForCardModal.city")} *</label>
            <InputTypeText
              id="c_city"
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.city")}
              value={cardHolderAddress.city}
              name="city"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyCity"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.city}
            />

            <label htmlFor="c_zipCode">{t("applyForCardModal.zipCode")} *</label>
            <InputTypeText
              id="c_zipCode"
              compulsoryValue
              placeholder={t("applyforcard.form.zipCode")}
              value={cardHolderAddress.post_code}
              name="post_code"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyPostCode"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.post_code}
            />

            <label htmlFor="c_country">{t("applyForCardModal.country")} *</label>
            <Form.Select
              id="c_country"
              className="enter-num-inp"
              name="country_code"
              value={cardHolderAddress.country_code}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setCardHolderAddress({ ...cardHolderAddress, country_code: e.target.value });
              }}
              aria-describedby="cardHolderCountrySelect"
            >
              <option value="" disabled>{t("addRecipient.form.selectCountry")}</option>
              {countries &&
                countries.map((ele: Country, index: number) => (
                  <option value={ele.numeric} key={index}>
                    {ele.country}
                  </option>
                ))}
            </Form.Select>
          </div>
          {/* Cardholder Address Section::: END */}

          {/* Separate Shipping Address Validation */}
          <div className="mt-4" style={{ display: "flex", alignItems: "center", width: "100%", gap: "10px" }}>
            <input
              type="checkbox"
              id="shippingAddCheck"
              name="shippingAddCheck"
              checked={!differentShippingAddress}
              onChange={() => handleSameShippingAddress()}
            />
            <label htmlFor="shippingAddCheck" style={{ width: "100%", fontSize: "13px", cursor: "pointer" }} >
              {t("applyForCardModal.shippingAddressIsSame")}
            </label>
          </div>

          {/* Shipping Address Section::: START */}
          <div>
            <br />
            <Divider>
              <Form.Text>{t("applyForCardModal.shippingAddress")}</Form.Text>
            </Divider>

            <Form.Text>{t("applyForCardModal.residentialAddressRequired")}</Form.Text>
            <br />
            <label htmlFor="fao">{t("applyForCardModal.poBoxName")} *</label>
            <InputTypeText
              id="fao"
              compulsoryValue
              maxLength={50}
              placeholder={t("applyForCardModal.poBoxNamePlaceholder")}
              value={shippingAddress.fao}
              name="fao"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyPOBox"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.fao}
            />
            <label htmlFor="s_line_1">{t("applyForCardModal.streetAddress")} *</label>
            <br />
            <InputTypeText
              id="s_line_1"
              compulsoryValue
              maxLength={50}
              placeholder={t("applyforcard.form.address")}
              value={shippingAddress.s_line_1}
              name="s_line_1"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyAddress"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.s_line_1}
            />

            <label htmlFor="s_city">{t("applyForCardModal.city")} *</label>
            <InputTypeText
              id="s_city"
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.city")}
              value={shippingAddress.s_city}
              name="s_city"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyCity"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.s_city}
            />

            <label htmlFor="s_zipCode">{t("applyForCardModal.zipCode")} *</label>
            <InputTypeText
              id="s_zipCode"
              compulsoryValue
              placeholder={t("applyforcard.form.zipCode")}
              value={shippingAddress.s_post_code}
              name="s_post_code"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("errorMsgs.emptyPostCode"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.s_post_code}
            />

            <label htmlFor="s_country">{t("applyForCardModal.country")} *</label>
            <Form.Select
              id="s_country"
              className="enter-num-inp"
              name="s_country_code"
              value={shippingAddress.s_country_code}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setShippingAddress({ ...shippingAddress, s_country_code: e.target.value });
              }}
              aria-describedby="cardHolderCountrySelect"
            >
              <option value="" disabled>{t("addRecipient.form.selectCountry")}</option>
              {countries &&
                countries.map((ele: Country, index: number) => (
                  <option value={ele.numeric} key={index}>
                    {ele.country}
                  </option>
                ))}
            </Form.Select>
          </div>
          {/* Shipping Address Section::: END */}
        </CustomTabPanel>

        {ibanAccUUID === ""
          ? <p style={{ color: "red", marginBottom: 0 }}>{t("applyForCardModal.businessAccountsNotFound")}</p>
          : missingFields === true && tabPanel === 2
            ? <p style={{ color: "red", marginBottom: 0 }}>{t("applyForCardModal.proceedMessage")}</p>
            : missingFields === true
              ? <p style={{ color: "red", marginBottom: 0 }}>{t("applyForCardModal.fillAllFields")}</p>
              : <></>
        }
        <div className="applyforCard_btnSubmit">
          {/* This is the Preview Button */}
          <BasicButton
            onClick={() => { setShowPreview(true) }}
            disabled={checkMandatoryFields()}
            style={{ height: "40px" }}
            text={t("pagination.next")}
            isLoading={createPhysicalCardSelector.status === "loading" || createVirtualCardSelector.status === "loading"}
            spinnerVariant="sm"
          />
        </div>
      </div>}

      {showPreview && (
        <div>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", marginBottom: "5px" }}>
            <h5 style={{ margin: '0px' }}>{t("applyForCardModal.verifyInformation")}</h5>
          </div>

          <p className="mb-1" style={{ fontWeight: "600" }}>{t("applyForCardModal.cardDetails")}:</p>
          <p className="mb-1">{t("applyForCardModal.cardType")}: {tabPanel === 0 ? t("applyForCardModal.virtualCard") : tabPanel === 1 ? t("applyForCardModal.physicalCard") : '-'}</p>
          <p className="mb-1">{t("applyForCardModal.businessAccount")}: {parsedBusinessAccounts.find((busAcc) => busAcc.value === ibanAccUUID)?.label || '-'}</p>
          {/* show embossed name in case of physical card */}
          {tabPanel === 1 && <p className="mb-1">{t("applyForCardModal.embossedName")}: {cardHolder.embossedName || '-'}</p>}
          <Divider sx={{ background: 'darkGray' }} />
          <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>{t("applyForCardModal.cardholderInfo")}:</p>
          <p className="mb-1">{t("applyForCardModal.title")}: {cardHolder.title}</p>
          <p className="mb-1">{t("applyForCardModal.firstName")}: {cardHolder.first_name}</p>
          <p className="mb-1">{t("applyForCardModal.lastName")}: {cardHolder.last_name}</p>
          <p className="mb-1">{t("applyForCardModal.DOB")}: {cardHolder.dateOfBirth}</p>
          <p className="mb-1">{t("applyForCardModal.email")}: {cardHolder.email}</p>
          <p className="mb-1">{t("applyForCardModal.mobileNumber")}: {cardHolder.mobileNo}</p>
          <p className="mb-1">{t("applyForCardModal.telephoneNumber")}: {cardHolder.telephoneNo}</p>

          <Divider sx={{ background: 'darkGray' }} />

          <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>{t("applyForCardModal.cardHolderAddress")}:</p>
          <p className="mb-1">{t("applyForCardModal.streetAddress")}: {cardHolderAddress.line_1}</p>
          <p className="mb-1">{t("applyForCardModal.city")}: {cardHolderAddress.city}</p>
          <p className="mb-1">{t("applyForCardModal.zipCode")}: {cardHolderAddress.post_code}</p>
          <p className="mb-1">{t("applyForCardModal.country")}: {countries.find(country => country.numeric === cardHolderAddress.country_code)?.country || 'Unknown Country'}</p>
          {tabPanel === 1 && <>
            <Divider sx={{ background: 'darkGray' }} />

            <p className="mt-3 mb-1" style={{ fontWeight: "600" }}>{t("applyForCardModal.shippingAddress")}:</p>
            <p style={{ marginBottom: 0, fontSize: "12px" }}>
              {t("applyForCardModal.residentialAddressRequired")}
            </p>
            <p className="mb-1">{t("applyForCardModal.poBoxName")}: {shippingAddress.fao}</p>
            <p className="mb-1">{t("applyForCardModal.streetAddress")}: {shippingAddress.s_line_1}</p>
            <p className="mb-1">{t("applyForCardModal.city")}: {shippingAddress.s_city}</p>
            <p className="mb-1">{t("applyForCardModal.zipCode")}: {shippingAddress.s_post_code}</p>
            <p className="mb-1">{t("applyForCardModal.country")}: {countries.find(country => country.numeric === shippingAddress.s_country_code)?.country || 'Unknown Country'}</p>
          </>}


          {/* Add other sections as needed */}
          <div className="applyforCard_btnSubmit mt-3"></div>
          <div style={{ display: 'flex', width: '100%' }}>
            <Col className="col-6 d-flex justify-content-center">
              <BasicButton
                type="button"
                variant="outlined"
                text={t("applyForCardModal.continueEditing")}
                onClick={() => { setShowPreview(false) }}
                style={{ height: "40px", width: '95%' }}
              />
            </Col>

            <Col className="col-6 d-flex justify-content-center">
              <BasicButton
                type="submit"
                disabled={ibanAccUUID === ""}
                style={{ height: "40px", width: '95%' }}
                text={t("forgotPassword.submit")}
                isLoading={createPhysicalCardSelector.status === "loading" || createVirtualCardSelector.status === "loading"}
                spinnerVariant="sm"
              />
            </Col>
          </div>
        </div>
      )}

    </form>
  );
};

export default ApplyforCardForm;
