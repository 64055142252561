import React, { useState, useEffect } from "react";
import { Col, Modal, ModalFooter } from "react-bootstrap";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { CardTopUpPayload } from "../../../../redux/toolkit/cards/interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { cardTopUp, getAllCards } from "../../../../redux/toolkit/cards/operation";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import Swal from "sweetalert2";
import { FormattedCard, LoadCardData, SelectedBusinessAccount } from "../../interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { ErrorImage, SuccessImage } from "../../../../constants/Images";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const TopUpSummaryModal = (props: {
  showSummaryModal: boolean,
  loadCardData: LoadCardData,
  setLoadCardData: React.Dispatch<React.SetStateAction<LoadCardData>>;
  selectedCard: FormattedCard,
  handleClose: () => void,
  selectedBusinessAcc: SelectedBusinessAccount;
  setShowAddMoneySummary: React.Dispatch<React.SetStateAction<boolean>>
  setShowAddMoney: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { showSummaryModal, loadCardData, setLoadCardData, selectedCard, handleClose, selectedBusinessAcc, setShowAddMoneySummary, setShowAddMoney } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userCurrency = selectedCard.currency;
  const { t } = useTranslation();

  const cardTopUpSelector = useSelector(cardSelectors.cardTopUpSelect);
  const handleAccept = () => {
    // the topUpAmount is sent as the full amount the user entered.
    // The fee is calculated and deducted by B4B
    const topUpPayload: CardTopUpPayload = {
      userId: localStorage.getItem("userId")!,
      card_code: selectedCard.cardId,
      amount: String(loadCardData.amount),
      uuid: selectedBusinessAcc?.accB4BUUID || "",
      currency: userCurrency,
      description: loadCardData.description
    }
    dispatch(cardTopUp(topUpPayload))
      .unwrap()
      .then((resp) => {
        // dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
        Swal.fire({
          // icon: "success",
          imageUrl: SuccessImage,
          title: `${t("sweetAlerts.success")}`,
          confirmButtonColor: "#86B6EF",
          text: `${t("sweetAlerts.cardhasBeenTopped")} ${userCurrency} ${loadCardData.amount}`,
          customClass: {
            confirmButton: 'modalBtnOK'
          }
        }).then(() => {
          setLoadCardData({ amount: 0, description: "" });
          handleClose();
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          // icon: "error",
          imageUrl: ErrorImage,
          title: `${t("sweetAlerts.error")}`,
          // confirmButtonColor: "#86B6EF",
          text: `${t("sweetAlerts.unableToTopupCard")}`,
          customClass: {
            confirmButton: 'modalBtnOK'
          }
        }).then(() => {
          setLoadCardData({ amount: 0, description: "" });
          handleClose();
        });
      })
  }

  return (
    <Modal
      show={showSummaryModal}
      centered
      onHide={() => {
        setLoadCardData({ amount: 0, description: "" });
        return handleClose();
      }}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div style={{ display: "flex", alignItems: 'center' }}>
          <div className="goBackIcon" style={{ display: "flex" }}
            onClick={() => {
              setShowAddMoneySummary(false)
              setShowAddMoney(true)
            }}
          >
            <AiOutlineArrowLeft />
          </div>
          <Modal.Title>{t("topUpSummaryModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            setLoadCardData({ amount: 0, description: "" });
            return handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("applyForCardModal.businessAccount")}</p>
        <p style={{ marginTop: "5px", marginBottom: "10px", fontWeight: "600" }}>{selectedBusinessAcc.accountName}</p>

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.cardDetails")}</p>
        <div className="addMoney-AmountToTopUp mb-2" >
          <Col>
            <p style={{ fontSize: "12px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.name")}</p>
            <p className="p-1 m-0" style={{ fontWeight: "600" }}>
              {selectedCard.holderFirstName} {selectedCard.holderLastName}
            </p>
          </Col>
          <div style={{ display: "flex", paddingRight: "5px" }}>
            <Divider orientation="vertical" flexItem sx={{ borderColor: "rgba(0,0,0,0.8)" }} />
          </div>


          <Col>
            <p style={{ fontSize: "12px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.last4digits")}</p>
            <p className="p-1 m-0" style={{ fontWeight: "600" }}>
              {selectedCard.maskedCardNumber}
            </p>
          </Col>
          <div style={{ display: "flex", paddingRight: "5px" }}>
            <Divider orientation="vertical" flexItem sx={{ borderColor: "rgba(0,0,0,0.8)" }} />
          </div>

          <Col>
            <p style={{ fontSize: "12px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.cardType")}</p>
            <p className="p-1 m-0" style={{ fontWeight: "600" }}>
              {selectedCard.cardType.split(" ")[0]}
            </p>
          </Col>
        </div>

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("topUpSummaryModal.topUpAmount")}</p>
        <p style={{ marginTop: "5px", marginBottom: "10px", fontWeight: "600" }}>{loadCardData.amount} {userCurrency}</p>


        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("topUpSummaryModal.autoTopUpConfiguration")}</p>
        <div className="addMoney-AmountToTopUp mb-2" >
          <Col>
            <p style={{ fontSize: '12px', margin: 0, color: '#ADB6C0' }}>{t("topUpSummaryModal.status")}</p>
            <p className="p-1 m-0" style={{ fontWeight: "600" }}>
              {selectedCard.autoTopupEnabled ? t("topUpSummaryModal.enabled") : t("topUpSummaryModal.disabled")}
            </p>
          </Col>

          {selectedCard.autoTopupEnabled && (
            <>
              <div style={{ display: "flex", paddingRight: "5px" }}>
                <Divider orientation="vertical" flexItem sx={{ borderColor: "rgba(0,0,0,0.8)" }} />
              </div>

              <Col>
                <p style={{ fontSize: '12px', margin: 0, color: '#ADB6C0' }}>
                  {t("topUpSummaryModal.minimumBalanceForAutoTopup")}
                </p>
                <p className="p-1 m-0" style={{ fontWeight: "600" }}>
                  {selectedCard.minimumBalance} {userCurrency}
                </p>
              </Col>

              <div style={{ display: "flex", paddingRight: "5px" }}>
                <Divider orientation="vertical" flexItem sx={{ borderColor: "rgba(0,0,0,0.8)" }} />
              </div>

              <Col>
                <p style={{ fontSize: '12px', margin: 0, color: '#ADB6C0' }}>
                  {t("topUpSummaryModal.autoTopupAmount")}
                </p>
                <p className="p-1 m-0" style={{ fontWeight: "600" }}>
                  {selectedCard.minimumBalance} {userCurrency}
                </p>
              </Col>
            </>
          )}
        </div>

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("topUpSummaryModal.loadDescription")}</p>
        <div style={{ maxHeight: "fit-content" }}>
          <p style={{ marginTop: "5px", marginBottom: "10px", fontWeight: "600", overflowWrap: "break-word" }}>{loadCardData.description || `-`}</p>
        </div>

        <p style={{ fontSize: "10px", margin: 0, color: "#ADB6C0" }}>* {t("topUpSummaryModal.standardFee")}</p>
      </Modal.Body>

      <ModalFooter style={{ borderTop: "0px", paddingTop: 0 }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              style={{ width: '95%' }}
              onClick={handleAccept}
              text={t("threeDSAuthModal.accept")}
              isLoading={cardTopUpSelector.status === "loading"}
              spinnerVariant="sm"
            />
          </Col>

          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              style={{
                width: '95%',
                backgroundColor: "white",
                color: "#006ff4",
                border: "1px solid #006ff4",
              }}
              onClick={handleClose}
              text={t("threeDSAuthModal.reject")}
              disabled={cardTopUpSelector.status === "loading"}
            />
          </Col>
        </div>

      </ModalFooter>
    </Modal>
  )
};

export default TopUpSummaryModal;