import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import { AppDispatch, RootState } from "../../redux/store";
import { GetALlbeneficiaries } from "../../redux/toolkit/beneficiaries/operation";
import { TempExchangeRates } from "../../redux/toolkit/exchangeRates/operation";
import {
  ConvertCurr,
  ConvertPay,
  ConvertTempCurr,
} from "../../redux/toolkit/convert/operations";
import { Currencies, LOGO_URL } from "../../constants/stringConstants";
import BeneficiaryTypes from "./interface";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import Footer from "../../components/shared/Footer";
import "./index.css";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import Icon from "../../assets/images/Icon.png";
import CustomTimer from "../../components/shared/Timer";
import { differenceInSeconds } from "date-fns";
import COLORS from "../../utils/colors";
import Swal from "sweetalert2";
import ExchangeRatesCard from "../../components/shared/ExchangeRatesCard";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";
import { ConfimationImage, ModalConvertIcon, ModalTransferIcon } from "../../constants/Images";
import ConversionDetails from "./ConversionModalData/details";
import ModalBannerSection from "../../components/shared/Modal/ModalBannerSection";
import TimerModalSection from "./TimerModalSection";
// For material ui dropdown
import { DropDownArrowIconSolid, DropDownInputWhite } from "../../components/shared/DropdownSelect";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AddbeneficiaryForm } from '../Addbeneficiary/AddBenificiaryForm';
import ModalHeader from "../../components/shared/Modal/ModalHeader";
import { debounce } from 'lodash';
import StrongCustomerAuthentication from "../../components/shared/StrongCustomerAuthentication";
import { PaymentVerifictionIcon } from "../../constants/Images";
import { resetStatus } from "../../redux/actions";


const Convert = () => {
  const dispatch = useDispatch<AppDispatch>();
  const beneficiariesData: any = useSelector(
    (state: RootState) => state.beneficiary.data
  );
  const convertSelector: any = useSelector(
    (state: RootState) => state.convert.data
  );
  const [beneficiariesId, setBeneficiariesId]: any = useState([]);
  const [uuid, setuuid]: any = useState({});
  const [toName, setToName] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentRef, setPaymentRef] = useState("");
  const [description, setDescription] = useState("");
  const [purpose, setPurpose] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [beneficiaryCurr, setBeneficiaryCurr] = useState("");
  const [amountError, setAmountError] = useState("");
  const [toAmountError, setToAmountError] = useState("");
  const [show, setShow] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const accountId: any = localStorage.getItem("accountId");
  const [accUUID, setAccUUID] = useState(accountId);
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const accountIdSelecto: any = useSelector((state: RootState) => state.getAllAccounts.data);
  const [timerDuration, setTimerDuration] = useState(30);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [toAmount, setToAmount] = useState("");
  const [toCurrencyErrMsg, setToCurrencyErrorMsg] = useState("")
  const [marketClosedError, setmarketClosedError] = useState(false)
  const [latestRateMatch, setlatestRateMatch] = useState(false)
  const [latestRate, setlatestRate] = useState("")
  const [recipientModal, setRecipientModal] = useState(false);
  const [recipientSelectVal, setRecipientSelectVal] = useState("");
  const [recipientError, setRecipientError] = useState("");
  const [latestRateSecond, setlatestRateSecond] = useState("")
  const [latestRateSecondMatch, setatestRateSecondMatch] = useState(false)
  const [amountType, setAmountType] = useState("")
  const [reloadRatesCard, setReloadRatesCard] = useState(false);


  const { t } = useTranslation();
  const recipientModalRef = useRef(null);
  const userId: any = localStorage.getItem("userId");
  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);
  const accId: any = localStorage.getItem("activeAccId");
  const inputAmountRef = useRef<HTMLInputElement>(null);
  const inputToAmountRef = useRef<HTMLInputElement>(null);
  const [currency, setCurrency] = useState(ParsedData?.currency);
  const [show2FA, setShow2FA] = useState<boolean>(false);
  const [authCompleted, setAuthCompleted] = useState<boolean>(false);


  useEffect(() => {
    localStorage.setItem("CurrForExhangeRateCard",currency)

  }, [currency])


  const debouncedConvertCurr = useCallback(
    debounce(async (payloadData) => {
      if (payloadData.payment_amount === "") {
        setToAmount("")
        return;
      }

      // console.log("payloadData.payment_amount", payloadData.payment_amount);
      const d: any = await dispatch(ConvertCurr(payloadData));
      // console.log("responsessss", d);
      setToAmount(d?.payload?.data?.data?.beneficiary_amount);
      setToAmountError("");

    }, 500),
    [dispatch]
  );

  const debouncedConvertCurr2 = useCallback(
    debounce(async (payloadData) => {
      const d: any = await dispatch(ConvertCurr(payloadData));
      // console.log("responsessss", d);
      setAmount(d?.payload?.data?.data?.beneficiary_amount);
      setToAmountError("");

    }, 500),
    [dispatch]
  );


  useEffect(() => {
    const checkMarketStatus = async () => {
      const payLoadData = {
        accCurrency: currency,
        toCurrency: "HUF"
      };
      const res = await dispatch(TempExchangeRates(payLoadData)) as { payload: { status: number } };
      if (res.payload == undefined) {
        setmarketClosedError(true)
      }
    };
    checkMarketStatus();
  }, [])



  useEffect(() => {
    dispatch(getAllAccounts(userId));
  }, [dispatch]);

  useEffect(() => {
    if (accountIdSelecto) {
      setAccountDetails(accountIdSelecto?.data?.data);
    }
  }, [accountIdSelecto, accountDetails]);

  useEffect(() => {
    const payLoadData = {
      accCurrency: currency,
    };

    dispatch(GetALlbeneficiaries(accUUID));
    // dispatch(TempExchangeRates(payLoadData));
    getBeneficiaries();
  }, [dispatch, accUUID]);

  useEffect(() => {
    if (parseInt(amount) < 0 || parseInt(amount) == 0) {
      setAmountError(t("errorMsgs.emptyAmount") as string);
    }
    else if (parseInt(amount) >= 0 && parseInt(amount) <= 1) {

      setAmountError(t("errorMsgs.greaterThanFiveAmount") as string);

    }
    else if (parseInt(amount) < 5) {

      setAmountError(t("errorMsgs.greaterThanFiveAmount") as string);

    }
    else {
      setAmountError("");
    }


  }, [amount]);


  useEffect(() => {
    if (beneficiariesData) {
      setBeneficiariesId(beneficiariesData.data?.data);
    }
  }, [beneficiariesData, beneficiariesId]);

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseTimer = () => {
    setShowTimer(false);
    dispatch(resetStatus());
    setAuthCompleted(false);
  };
  const handleClose2FA = () => {
    setLoading(false);
    setShow2FA(false);
    dispatch(resetStatus());
  }
  const onTimerComplete = () => {
    setIsTimerCompleted(true);
  };

  const staticPayload = {
    payment_currency: currency,
    payment_amount: "",
    beneficiary_currency: beneficiaryCurr,
    uuid: accUUID,
  };

  const onChangeAmount = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const inputValue = parseFloat(event.target.value);
    const limit = 1500000;

    if (inputValue > limit) {
      return 0;
    }
    setAmountType("FromAmount")
    // const item = event.target.value;
    // // const staticPayload = {
    // //   payment_currency: ParsedData?.currency,
    // //   payment_amount: event?.target?.value,
    // //   beneficiary_currency: beneficiaryCurr,
    // //   uuid: accUUID,
    // // }
    // staticPayload.payment_amount = event?.target?.value


    const item = event.target.value;

    staticPayload.payment_amount = event?.target?.value;

    if (item === "") {
      setToAmount("");
      setAmount("");
      setToAmountError("");
      return;
    }



    if (!item) {
      setToAmount("");
      setAmount("")
      setToAmountError("");
      return;
    }
    if (regexStrConst.amountRegex.test(item)) {
      if (parseInt(item) < 0 || parseInt(item) == 0) {
        setAmountError(t("errorMsgs.emptyAmount") as string);
      }
      else if (parseInt(item) < 5) {

        setAmountError(t("errorMsgs.greaterThanFiveAmount") as string);

      }
      else {
        setAmountError("");
      }

    } else {
      setAmount(event?.target?.value);
      setAmountError(t("errorMsgs.emptyAmount") as string);
      setToAmountError("");
      return null;
    }

    if (!beneficiaryCurr) {
      setToCurrencyErrorMsg(t("convert.form.selectBenificiaryCurrency") as string);
      setAmountError("");
      return null;
    } else {
      setAmount(event?.target?.value);
    }
    // const res: any = await dispatch(ConvertTempCurr(staticPayload));
    // const rates = res?.payload?.data?.data
    // const tempExchangeRates: any = localStorage.getItem('tempExchangeRates')
    // const parsedTempRates: any = JSON.parse(tempExchangeRates);
    // const rateObject = parsedTempRates.find((rate: { convertToCurrency: string; }) => rate.convertToCurrency === beneficiaryCurr);
    // // const beneficiaryAmount = rateObject ? rateObject.amount : undefined;
    // const clientRate = rateObject ? rateObject.clientRate : undefined;
    // const toAmount = (clientRate * parseFloat(event?.target?.value)).toFixed(2)
    // setToAmount(`${toAmount}`)
    const payloadData = {
      payment_currency: currency,
      payment_amount: event.target.value,
      beneficiary_currency: beneficiaryCurr,
      uuid: accUUID,
    };
    debouncedConvertCurr(payloadData);
    setToAmountError("");


    // setToAmount(res?.payload?.data?.data?.beneficiary_amount)
    // onChangeToAmount(event)
    // setAmount(item);
  };
  const getBeneficiaries = () => {
    dispatch(GetALlbeneficiaries(accUUID));
  };

  const handleCloseAddRecipient = () => {
    setRecipientModal(false)
  };

  const validate = () => {
    if (amount === "") setAmountError(t("errorMsgs.emptyAmount") as string);
    if (toAmount === "") setToAmountError(t("errorMsgs.emptyAmount") as string);
    if (uuid === 'add') setRecipientError(t("errorMsgs.selectRecipient") as string);
    return uuid === "" || uuid === 'add' || amount === "" || uuid === "" || amountError ? false : true;
  };

  const getPayloadData = () => {
    // copied need to remove from other function
    const payloadData = {
      payment_currency: currency,
      payment_amount: amount,
      beneficiary_currency: beneficiaryCurr,
      uuid: accUUID,
    };
    // console.log(payloadData,"test payload object");


    return payloadData
  }
  const getTimerSeconds = (created_at: any, expires_at: any) => {
    const ca = new Date(created_at);
    const ea = new Date(expires_at);
    const counterSeconds = differenceInSeconds(ea, ca);
    // console.log("created, expire, seconds=>",{ca, ea, counterSeconds})
    return counterSeconds;
  };

  const refreshHandler = async () => {
    setIsTimerCompleted(false);
    const payloadData = getPayloadData();
    const d = await dispatch(ConvertCurr(payloadData));
    const data = JSON.parse(JSON.stringify((d as any).payload?.data?.data));
    const counterSeconds = getTimerSeconds(data.created_at, data.expires_at);
    setTimerDuration(counterSeconds);
    try {
      if (d.meta.requestStatus == "rejected") {
        setShowTimer(false);
      } else {
        setShowTimer(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("=> An error occurred:", error);
    }

  }
  const handleRecipientSelect = (value: any) => {
    // value will be guidcurr
    if (value === 'add') {
      setuuid('');
      setRecipientSelectVal("")
      setRecipientModal(true)
    } else {
      setuuid(value);
      setRecipientSelectVal(value)
      const benificiary = beneficiariesId.find((d: any) => {
        if (d.guid === value) return d
      })
      setToName(benificiary.name);
      setRecipientError("")
    }
  }

  useEffect(() => {
    if (authCompleted) {
        handleConvert();
        setShow2FA(false);
        setLoading(false);
        dispatch(resetStatus());
    }
}, [authCompleted]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      setShow2FA(true);
    }
  }
  const handleConvert = async () => {
    const payloadData = getPayloadData();
      try {
        const d: any = await dispatch(ConvertCurr(payloadData));
        const data = JSON.parse(JSON.stringify((d as any).payload?.data?.data));

        setlatestRate(d?.payload?.data?.data?.beneficiary_amount)
        if (parseFloat(d?.payload?.data?.data?.beneficiary_amount) !== parseFloat(toAmount)) {
          setlatestRateMatch(true)
        } else {

          setlatestRateMatch(false)

        }

        const counterSeconds = getTimerSeconds(
          data.created_at,
          data.expires_at
        );
        setTimerDuration(counterSeconds);
        setLoading(false);

        if (d.meta.requestStatus == "rejected") {
          setShowTimer(false);
        } else {
          setShowTimer(true);
          setLoading(false);
          // e.target.reset();
        }

      } catch (error) {
        console.log("=> An error occurred:", error);
        setLoading(false);
      }
  };
  const onChangeToAmount = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const inputValue = parseFloat(event.target.value)
    const limit = 1500000;

    if (inputValue > limit) {
      return 0;
    }
    setAmountType("ToAmount")
    const item = event.target.value;



    staticPayload.payment_amount = event?.target?.value;

    if (!item) {
      setToAmount("");
      setAmount("")
      setToAmountError("");
      return;
    }

    if (regexStrConst.amountRegex.test(item)) {
      if (parseInt(item) < 5) {

        // setToAmountError(errorMsgs.greaterThanFiveAmount);

      } else {
        setToAmountError("");
      }
    } else {
      setToAmount(event?.target?.value);
      setToAmountError(errorMsgs.emptyAmount);
      return null;
    }

    if (!beneficiaryCurr) {
      setToCurrencyErrorMsg(t("convert.form.selectBenificiaryCurrency") as string);
      setToAmountError("")
      return null;
    } else {
      setToAmount(event?.target?.value);
    }

    const payloadData = {

      payment_currency: beneficiaryCurr,
      payment_amount: event.target.value,
      beneficiary_currency: currency,
      uuid: accUUID,
    };
    debouncedConvertCurr2(payloadData)

  };

  const max = 999999;


  const onChangeCurrency = async (event: any) => {
    staticPayload.payment_amount = amount
    setToCurrencyErrorMsg("");
    setBeneficiaryCurr(event.target.value);
    const payLoadData = {
      accCurrency: currency,
      toCurrency: event.target.value
    };


    dispatch(TempExchangeRates(payLoadData));


    setAmount("")
    setToAmount("")

  }
  // this function used for testing market when market is close eg: on saturday sunday
  // const sendfundsss =()=>{
  //   const payloadData = {
  //   // payment_reference: payload.payment_reference,
  //   // fx_quote_uuid: payload.fx_quote_uuid,
  //   // uuid: payload.uuid,
  //   // payment_amount: payload.payment_amount,
  //   // payment_currency: payload.payment_currency,
  //   // beneficiary_currency: payload.beneficiary_currency,
  //   // beneficiary_amount: payload.beneficiary_amount,
  //   // payment_reason: payload.payment_reason,
  //   // userID: payload.userID,

  //     beneficiary_uuid: "11ecd20a-57ed-401a-ad50-1a407fdfdd77",
  //     payment_reference: "Convert",
  //     fx_quote_uuid: "dalfk;ldsaf",
  //     uuid: accUUID,
  //     beneficiary_amount:"10",
  //     beneficiary_currency: "10",
  //     payment_amount: "6",
  //     payment_currency: ParsedData.currency,
  //     payment_reason: "Convert",
  //     userID: accId,
  //   };
  //   dispatch(ConvertPay(payloadData)).then((data:any) => {
  //     console.log("=>",data.error.message)
  //     if(data.error.message==='Error: Your Account is suspended for this operation'){
  //       alert("hery");
  //       setShow(false);

  //     }else {
  //       setShow(true);

  //     }

  //   });}

  const onKeyUpAmount = (event: any) => {

    if (event.target.value === "") {


      debouncedConvertCurr.cancel();
      debouncedConvertCurr2.cancel();
      setAmount("");
      setToAmount("");
      setAmountError("");
      setToAmountError("");


    }
  };


  return (
    <div className="Convert-info-main">
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="convert" />
      </div> */}

      {/* <div className="left_sidebar position-fixed">
        <LeftSideBar name="convert" />
      </div> */}

      <Row className="addbeneficiary justify-content-between">
        <Col xs={12} sm={12} md={7} lg={7} xl={7} className="mainFormBox">
          <div className="inner__content position-relative">
            <div className="basicFormWidth">
              <h1 className="mainHeading">{t("convert.title")}</h1>
              <form className="convert-form" onSubmit={handleSubmit}>
                {marketClosedError && (
                  <p style={{ color: 'red', border: 'solid', borderRadius: '10px', borderWidth: '2px', textAlign: 'center' }}>{t("convert.form.marketClosed")}</p>
                )}
                <p className="formInputLabel">{t("convert.form.account")} </p>
                {/* Material Ui DropDown */}
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    //   value={age}
                    onChange={(e: any) => {
                      const accountData = JSON.parse(e.target.value);
                      setAccUUID(accountData.accB4BUUID);
                      setCurrency(accountData.currency)
                      const payLoadData = {
                        accCurrency: accountData.currency,
                        toCurrency: beneficiaryCurr
                      };
                      dispatch(TempExchangeRates(payLoadData));
                      setAmount("")
                      setToAmount("")
                      setReloadRatesCard(prev => !prev);
                    }}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value={JSON.stringify(ParsedData)}>
                      {t("convert.form.selectedAcc")}: {ParsedData?.accountName}
                    </option>
                    {accountDetails &&
                      accountDetails.map((ele: any, index: number) =>
                        !ele.inactive ? (
                          <option key={index} value={JSON.stringify(ele)}>
                            {ele.accountName}
                          </option>
                        ) : null
                      )}
                  </NativeSelect>
                </FormControl>
                {/* Material Ui DropDown */}

                <p className="formInputLabel">
                  {t("convert.form.recipient")}
                </p>

                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    onChange={(e) => handleRecipientSelect(e.target.value)}
                    value={recipientSelectVal}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                  >
                    <option value="" disabled>{t("convert.form.selectRecipient")}</option>
                    {beneficiariesId && beneficiariesId.length > 0 ? (
                      beneficiariesId.map(
                        (ele: BeneficiaryTypes, index: number) => (
                          <option key={index} value={ele?.guid}>
                            {ele.name + " " + ele.account}
                          </option>
                        )
                      )
                    ) : (
                      <option>{t("convert.form.noRecipient")}</option>
                    )}
                    <hr />
                    <option value="add"  style={{ fontWeight: 600, padding: "20px !important", fontSize: "18px", cursor: "pointer" }}>
                      {t("addRecipient.title")}
                    </option>
                  </NativeSelect>
                </FormControl>
                <span className="errorMsg">{recipientError}</span>



                <p className="formInputLabel">{t("convert.form.from")}</p>

                <InputTypeText
                  placeholder={currency}
                  disabled
                  type="text"
                />



                <p className="formInputLabel">{t("convert.form.to")}</p>

                <FormControl sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                    onChange={(e) => {
                      onChangeCurrency(e);
                    }}
                  >
                    <option value="">{t("convert.form.selectCurr")}</option>
                    {Currencies &&
                      Currencies.map((ele, index) => (
                        <option key={index}>{ele.item}</option>
                      ))}

                  </NativeSelect>
                </FormControl>

                <p style={{ color: "red" }}>{toCurrencyErrMsg}</p>

                <p className="formInputLabel">{t("convert.form.amount")}</p>
                <InputTypeText
                  ref={inputAmountRef}
                  className="enter-amount "
                  placeholder="5.00"
                  name="fromAmount"
                  type="number"
                  step="any"
                  value={amount}
                  onChange={(e) => {
                    onChangeAmount(e);
                    onKeyUpAmount(e);
                  }}
                  errorMsg={amountError}
                  disabled={marketClosedError}
                  maxLength={6}

                />

                <p className="formInputLabel">{t("convert.form.toAmount")}</p>
                <InputTypeText
                  ref={inputToAmountRef}
                  className="enter-amount"
                  placeholder=""
                  name="toAmount"
                  type="number"
                  step="any"
                  value={toAmount}
                  // onChange={onChangeToAmount}
                  onChange={(e) => {
                    onChangeToAmount(e);
                    onKeyUpAmount(e);
                  }}
                  errorMsg={toAmountError}
                  disabled={marketClosedError}
                />

                <p className="formInputLabel">{t("convert.form.paymentRef")}</p>
                <InputTypeText
                  value={paymentRef}
                  onChange={(e) => { setPaymentRef(e.target.value) }}
                  defaultValue="Convert"
                />
                <div className="btn-send">
                  <BasicButton
                    disabled={marketClosedError}
                    type="submit"
                    text={t("convert.form.send")}
                    isLoading={loading ? true : false}
                  />

                </div>
              </form>
            </div>
          </div>
        </Col>
        <Col md={5} lg={4} xl={4} className="MonthlyRevenueTracker">
          {/* <RightSideBar exchangeRates summaryBox /> */}
          <ExchangeRatesCard reload={reloadRatesCard} />
        </Col>
      </Row>

      {/* footer is hide because it was not in design */}
      {/* <Footer /> */}

      {/* Timer Modal */}
      <Modal show={showTimer} onHide={handleCloseTimer} className="convert-modal-root">
          <ModalHeader title={t("convert.modal.title")} onClose={handleCloseTimer} />
        <Modal.Body>
          <TimerModalSection
            isTimer={isTimerCompleted}
            conversionIcon={ModalConvertIcon}
            timerDuration={timerDuration}
            onTimerComplete={onTimerComplete}
            statusText={convertSelector.data?.data?.statusText}
            refreshHandler={refreshHandler} />

          <ConversionDetails
            convertingValue={` ${amountType === "FromAmount"
              ? amount
              : convertSelector.data
                ? convertSelector.data.data?.payment_amount
                : ""
              } ` +
              ` ${convertSelector.data
                ? convertSelector.data.data?.payment_currency
                : ""
              }`}
            convertingTo={toName}
            recipientGet={` ${amountType === "ToAmount"
              ? toAmount
              : convertSelector.data
                ? convertSelector.data.data?.beneficiary_amount
                : ""
              } ` +
              ` ${convertSelector.data
                ? convertSelector.data.data?.beneficiary_currency
                : ""
              }`}
            exchangeRates={convertSelector.data
              ? convertSelector.data.data?.client_rate
              : ""}
          />

          <div className="footerButtons d-flex justify-content-between mt-4">
            <div className="btnAuthorizePaymentDiv">
              <BasicButton
                text={t("convert.modal.payment")}
                isLoading={loader2 ? true : false}
                disabled={isTimerCompleted}
                onClick={() => {
                  setLoader2(true);
                  const payloadData = {
                    beneficiary_uuid: uuid,
                    description: description,
                    payment_reference: "Convert",
                    fx_quote_uuid: convertSelector.data.data.guid,
                    uuid: accUUID,
                    beneficiary_amount:
                      amountType === "ToAmount" ? toAmount : convertSelector.data.data.beneficiary_amount,
                    beneficiary_currency:
                      convertSelector.data.data.beneficiary_currency,
                    payment_amount: amountType === "FromAmount" ? amount : convertSelector.data.data.payment_amount,
                    payment_currency: currency,
                    payment_reason: "Convert",
                    userID: accId,
                  };
                  dispatch(ConvertPay(payloadData)).then((res: any) => {
                    if (res.error && res.error?.name === "Error") {
                      setShow(false);
                    } else {
                      setShow(true);
                    }
                    handleCloseTimer();
                    setLoader2(false);
                  });
                }}
              />
            </div>
            <div className="btnCloseDiv">
              <BasicButton
                variant="outlined"
                text={t("convert.modal.close")}
                onClick={handleCloseTimer}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Receipt Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <p className="timerModalHeading">{t("convert.modal.title")}</p>

          <ModalBannerSection icon={ModalConvertIcon} statusText={convertSelector.data?.data?.statusText} title={t("cardDetails.convert")} />

          <ConversionDetails
            convertingValue={
              ` ${amountType === "FromAmount"
                ? amount
                : convertSelector.data
                  ? convertSelector.data.data?.payment_amount
                  : ""
              } ` +
              ` ${convertSelector.data
                ? convertSelector.data.data?.payment_currency
                : ""
              }`
            }
            convertingTo={toName}
            recipientGet={
              ` ${amountType === "ToAmount"
                ? toAmount
                : convertSelector.data
                  ? convertSelector.data.data?.beneficiary_amount
                  : ""
              } ` +
              ` ${convertSelector.data
                ? convertSelector.data.data?.beneficiary_currency
                : ""
              }`
            }
            exchangeRates={
              convertSelector.data ? convertSelector.data.data?.client_rate : ""
            }
          />

          <BasicButton
            text={t("convert.modal.close")}
            style={{ marginTop: "20px" }}
            onClick={handleClose}
          />
        </Modal.Body>
      </Modal>

      {/* Add recipient modal */}
      <Modal
        show={recipientModal}
        onHide={handleCloseAddRecipient}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <ModalHeader title="Add Recipient" onClose={() => setRecipientModal(false)} modalBodyRef={recipientModalRef} />

        <Modal.Body ref={recipientModalRef}>
          <AddbeneficiaryForm
            handleCloseAddRecipient={handleCloseAddRecipient}
            isRecipientModal={recipientModal}
            getBeneficiaries={getBeneficiaries}
            handleRecipientSelect={handleRecipientSelect}
          />

        </Modal.Body>
      </Modal>

      {/* 2FA modal */}
      <Modal
      show={show2FA}
      onHide={handleClose2FA}
      backdrop="static"
      keyboard={false}
      >
      <ModalHeader onClose={handleClose2FA}/>
      <Modal.Body>
        <StrongCustomerAuthentication setAuthCompleted={setAuthCompleted} showImage/>
      </Modal.Body>

      </Modal>
    </div>
  );
};
export default Convert;
