import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useScreenSize } from '../../../../components/shared/ResizeHook';
import { B4BCardNotificationType } from '../../../../redux/toolkit/cards/interface';
import moment from 'moment';
import { useSelector } from 'react-redux';
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import { CreditedTransactionCodes, DebitedTransactionCodes } from '../../../../constants';

import { CiViewColumn } from "react-icons/ci";
import { AiOutlineStop } from "react-icons/ai";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

import DebitedPaymentIcon from "../../../../assets/images/cardAssets/debitedPaymentIcon.svg"
import CreditedPaymentIcon from "../../../../assets/images/cardAssets/creditedPaymentIcon.svg"
import SearchIcon from '../../../../assets/images/muiDataTable/search.svg';
import PrintIcon from '../../../../assets/images/muiDataTable/printer.svg';
import DownloadIcon from '../../../../assets/images/muiDataTable/download.svg';
import FilterIcon from '../../../../assets/images/muiDataTable/filter-lines.svg';
import { useTranslation } from 'react-i18next';

import "../index.css";

export const TransactionTable = (props: { onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => void, viewAllTransactions: boolean, transactionsList: B4BCardNotificationType[] }) => {
  const { onRowClick, viewAllTransactions, transactionsList } = props;
  const screenSize = useScreenSize();
  const allCardsNotificationsSelector: any = useSelector(cardSelectors.cardNotificationsSelect);
  const [page] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(viewAllTransactions ? 10 : 3);
  const { t } = useTranslation();

  const columns = [
    // MCC LOGO IS PROVIDED (REPURPOSED TO SHOW TRANSACTION DATE IN CSV TABLE) 
    {
      name: "",
      label: "",
      options: {
        print: false,
        setCellHeaderProps: () => ({ style: { width: "40px", minWidth: "40px", maxWidth: "40px" } }),
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div>
              {
                transactionsList[dataIndex].declined
                  ? <div style={{
                    display: "flex",
                    width: '40px',
                    height: "40px",
                    borderRadius: "6px",
                    background: "rgba(215, 240, 255, 1)",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <AiOutlineStop size={"24px"} color='rgba(0, 111, 244, 1)' strokeWidth={"40px"} />
                  </div>
                  : <img
                    className="icon-image"
                    style={{
                      width: "40px", height: "40px"
                    }}
                    src={transactionsList[dataIndex].merchant_logo_url !== null && transactionsList[dataIndex].merchant_logo_url !== undefined
                      ? `${transactionsList[dataIndex].merchant_logo_url}`
                      : DebitedTransactionCodes.includes(transactionsList[dataIndex].transaction_type)
                        ? DebitedPaymentIcon
                        : CreditedTransactionCodes.includes(transactionsList[dataIndex].transaction_type)
                          ? CreditedPaymentIcon
                          : ""
                    }
                    alt='debit transaction icon' />
              }
            </div >
          )
        }
      },
    },
    // TRANSACTION TIME COLUMN (NOT SHOWN IN THE UI AND PRINT, ONLY IN CSV)
    {
      name: "transaction_timestamp",
      label: "Time",
      options: {
        empty: true,
        display: false,
        filter: false,
        searchable: false,
        viewColumns: false,
        sort: false,
        print: false,
      },
    },
    // MERCHANT NAME COLUMN (NOTES DISPLAYED IF MERCHANT NAME ISN'T PROVIDED)
    {
      name: "merchant_name",
      label: t("bankAccountCards.name"),
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "80%", minWidth: "80%", maxWidth: "80%" } }),
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
          return (
            <>
              <div>
                {transactionsList[dataIndex].merchant_name || transactionsList[dataIndex].note}
              </div>
              <div
                style={{
                  color: "#ADB6C0",
                  fontSize: "11px",
                }}
              >
                {moment.utc(transactionsList[dataIndex].transaction_timestamp, "YYYY-MM-DD hh:mm:ss").local().format("DD MMM, YYYY | h:mm A")}
              </div>
            </>
          )
        }
      },
    },
    // TRANSACTION AMOUNT COLUMN
    {
      name: "settlement_amount",
      label: t("bankAccountCards.amount"),
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "10%", minWidth: "10%", maxWidth: "10%" } }),
        customHeadLabelRender: (columnMeta: object) => {
          return (
            screenSize.width <= 500
              ? <>
                <p className='m-0'>{t("bankAccountCards.amount")}</p>
                <p style={{ color: "#ADB6C0", fontWeight: 600, fontSize: 10, margin: "0" }}>{t("bankAccountCards.balance")}</p>
              </>
              : t("bankAccountCards.amount")
          )
        },
        customBodyRenderLite: (dataIndex: number) => {
          return (
            transactionsList[dataIndex].declined
              ? <div style={{ fontWeight: 600, fontSize: screenSize.width <= 500 ? 12 : 14 }}>
                -
              </div>
              : <>
                <div
                  style={{ fontWeight: 600, fontSize: screenSize.width <= 500 ? 12 : 14 }}
                  className={`${DebitedTransactionCodes.includes(transactionsList[dataIndex].transaction_type)
                    ? "amountDeducted"
                    : CreditedTransactionCodes.includes(transactionsList[dataIndex].transaction_type)
                      ? "amountAdded"
                      : ""}
                `}
                >
                  {(transactionsList[dataIndex].settlement_amount
                    + transactionsList[dataIndex].fx_padding
                    + transactionsList[dataIndex].fees_rate_amount).toFixed(2)
                  } {transactionsList[dataIndex].settlement_currency}
                </div>
                {screenSize.width <= 500 &&
                  <div style={{ color: "#ADB6C0", fontWeight: 600, fontSize: 10 }}>
                    {transactionsList[dataIndex].available_balance} {transactionsList[dataIndex].billing_currency}
                  </div>
                }
              </>
          )
        }
      },
    },
    // AVAILABLE BALANCE COLUMN (ONLY SHOWN ON LARGE SCREEN SIZES)
    {
      name: "available_balance",
      label: t("bankAccountCards.balance"),
      options: {
        filter: true,
        sort: false,
        display: screenSize.width > 500,
        setCellHeaderProps: () => ({ style: { width: "10%", minWidth: "10%", maxWidth: "10%" } }),
        customBodyRenderLite: (dataIndex: number) => {
          return (
            transactionsList[dataIndex].declined
              ? <div style={{ fontWeight: 600, fontSize: screenSize.width <= 500 ? 12 : 14 }}>
                -
              </div>
              :
              <div style={{ fontWeight: 600, fontSize: 14 }}>
                {(transactionsList[dataIndex].available_balance).toFixed(2)} {transactionsList[dataIndex].billing_currency}
              </div>
          );
        },
      },
    },
    // FX TRANSACTION COLUMN (NOT SHOWN IN THE UI AND PRINT, ONLY IN CSV)
    {
      name: "fx_padding",
      label: "FX Transaction",
      options: {
        empty: true,
        display: false,
        filter: false,
        searchable: false,
        viewColumns: false,
        sort: false,
        print: false,
      },
    },
    // ACTUAL AMOUNT COLUMN FOR FX TRANSACTIONS (NOT SHOWN IN THE UI AND PRINT, ONLY IN CSV)
    {
      name: "amount",
      label: "Transaction Amount",
      options: {
        empty: true,
        display: false,
        filter: false,
        searchable: false,
        viewColumns: false,
        sort: false,
        print: false,
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              display: viewAllTransactions ? "flex" : "none !important",
              alignItems: 'center',
              justifyContent: 'center'
            }
          }
        },
        MUIDataTableHeadRow: {
          styleOverrides: {
            root: {
              borderBottom: '1px solid lightgrey',
            }
          }
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              fontSize: 16,
              fontWeight: "600 !important",
            }
          }
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              cursor: 'pointer'
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            "root": {
              padding: screenSize.width <= 500 ? "4px" : '6px',
              fontFamily: 'Montserrat',
              fontSize: screenSize.width <= 500 ? 12 : 14,
              borderBottom: "none"
            },
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: 'Montserrat',
              fontWeight: "bold",
              textAlign: 'left',
              paddingLeft: 0,
              display: 'contents'
            },
          }
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              display: 'flex !important',
              justifyContent: 'center',
            },
          },
        },
        MUIDataTableFilter: {
          styleOverrides: {
            header: {
              display: 'flex',
            },
            reset: {
              display: 'flex',
            },
            title: {
              marginRight: '100% !important',
            }
          }
        }
      }
    });

  const renderIcon = (icon: string) => <img src={icon} alt="" />

  const customIcons: any = {
    SearchIcon: () => renderIcon(SearchIcon),
    PrintIcon: () => renderIcon(PrintIcon),
    DownloadIcon: () => renderIcon(DownloadIcon),
    ViewColumnIcon: () => <CiViewColumn style={{ color: 'black', fontSize: "larger" }} />,
    FilterIcon: () => renderIcon(FilterIcon),
  }

  const formatAmountForCSV = (transaction: B4BCardNotificationType) => {

    const totalAmount = transaction.settlement_amount + transaction.fx_padding + transaction.fees_rate_amount;

    const signAddedAmount = `${DebitedTransactionCodes.includes(transaction.transaction_type)
      ? `\t- ${totalAmount.toFixed(2)}`
      : totalAmount.toFixed(2)}`;

    return `${signAddedAmount} ${transaction.settlement_currency}`;
  };

  const csvDataFormatter = (field: any, dataIndex: number, transaction: B4BCardNotificationType) => {
    // dataIndex is the index of each row in the table. Corresponding values are:
    // 0: Date of transaction (col 1)
    // 1: Time of transaction (col 2)
    // 2: Merchant name (col 3)
    // 3: Transaction amount (col 4)
    // 4: Balance after transaction (col 5)
    // 5: FX transaction flag (col 6)
    // 6: Actual transaction amount if an FX transaction (col 7)
    switch (dataIndex) {
      case 0:
        return moment.utc(transaction.transaction_timestamp, "YYYY-MM-DD hh:mm:ss").local().format("DD/MM/YYYY");
      case 1:
        return moment.utc(transaction.transaction_timestamp, "YYYY-MM-DD hh:mm:ss").local().format("HH:mm:ss");
      case 2:
        return transaction.declined ? `${transaction.merchant_name || transaction.note} (DECLINED)` : transaction.merchant_name || transaction.note;
      case 3:
        return transaction.declined ? '' : formatAmountForCSV(transaction);
      case 4:
        return transaction.declined ? '' : `${transaction.available_balance.toFixed(2)} ${transaction.billing_currency}`;
      case 5:
        return transaction.declined
          ? ''
          : transaction.fx_padding !== 0
            ? true
            : '';
      case 6:
        return transaction.fx_padding !== 0 ? `${transaction.amount.toFixed(2)} ${transaction.currency}` : "";
      default:
        return field;
    }
  };

  return (
    <div style={{ width: "100% !important", overflowX: "auto" }}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={""}
          data={transactionsList}
          columns={columns}
          components={{ icons: customIcons }}
          options={{
            textLabels: {
              body: {
                noMatch: allCardsNotificationsSelector.status === "loading"
                  ? <p className='tableText'>{t("bankAccountCards.loading")}</p>
                  : transactionsList.length === 0
                    ? <p className='tableText'>{t("bankAccountCards.noTransactionDataCurrently")}</p>
                    : <p className='tableText'>{t("bankAccountCards.noMatchingRecords")}</p>
              },
            },
            jumpToPage: false,
            selectableRows: "single",
            selectableRowsHideCheckboxes: true,
            selectableRowsOnClick: false,
            onRowClick: onRowClick,
            responsive: 'standard',
            page: currentPage !== 0 ? currentPage : page,
            rowsPerPage: rowsPerPage,
            onDownload: (buildHead, buildBody, columns, data) => {
              const updatedColumns = [...columns]; // Create a copy of the original columns array
              // Modify the label of the first item in the updatedColumns array
              updatedColumns[0] = { ...updatedColumns[0], label: "Date" };

              const alteredData = data?.map((row: any, index: number) => ({
                index,
                data: row?.data?.map((field: any, dataIndex: number) => {
                  return csvDataFormatter(field, dataIndex, transactionsList[index]);
                }),
              }))
              return `${buildHead(updatedColumns)}${buildBody(alteredData)}`.trim()
            },
            downloadOptions: {
              filename: `OMW Card Transactions: ${moment.utc(transactionsList[0]?.transaction_timestamp, "YYYY-MM-DD hh:mm:ss").local().format("YYYY-MM-DD")}_${moment.utc(transactionsList[transactionsList.length - 1]?.transaction_timestamp, "YYYY-MM-DD hh:mm:ss").local().format("YYYY-MM-DD")}`,
            },
            // ENSURE THE PROPS ARE CALLED AND PASSED AS BELOW, SKIPPING THEM CAUSES ABNORMAL BEHAVIOUR
            customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
              <CustomPagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={changePage}
                setCurrentPage={setCurrentPage}
              />
            )
          }}
        />
      </ThemeProvider>
    </div>
  )
}

const CustomPagination = (props: {
  count: number,
  rowsPerPage: number,
  page: number,
  onChangePage: (newPage: number) => void,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { count, rowsPerPage, page, onChangePage, setCurrentPage } = props;
  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    onChangePage(newPage - 1);
  };

  useEffect(() => { setCurrentPage(page); }, [page])

  // Implement your custom rendering logic here
  return (
    <Pagination
      sx={{
        marginTop: "10px"
      }}
      color="primary"
      variant="outlined"
      shape="rounded"
      size={screenSize.width <= 500 || (screenSize.width < 1005 && screenSize.width > 760) ? "small" : "medium"}
      count={Math.ceil(count / rowsPerPage)}
      page={page + 1}
      onChange={handlePageChange}
      boundaryCount={screenSize.width <= 540 || (screenSize.width < 1005 && screenSize.width > 760) ? 0 : 1}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component="div"
          sx={{
            fontFamily: 'Montserrat',
            borderRadius: "6px",
            '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
            "&.Mui-selected": {
              backgroundColor: "#006FF4",
              color: "white",
              borderColor: "transparent",
              borderRadius: "6px"
            }
          }}
          slots={{
            previous: () => screenSize.width <= 500 || (screenSize.width < 1005 && screenSize.width > 760)
              ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
              : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
            next: () => screenSize.width <= 500 || (screenSize.width < 1005 && screenSize.width > 760)
              ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
              : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
          }}
        />
      )}
    />
  );
};